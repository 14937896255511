import moment from "moment";

const ValidateEmail = (email)=>{
    const reghex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    return reghex.test(email);
}

const ValidateCelphoneMX = (email)=>{
    const reghex = /^[0-9]{10}$/;
    return reghex.test(email);
}

const FormatPrice = (price)=> {
    let formattedCurrency = Intl.NumberFormat('en-Us',{
        style: 'currency',
        currency: 'USD'
    });
    return ` ${formattedCurrency.format(price)}  MXN`;
}

const GetTimeRange = (startTime, endTime)=> {
    let rangeTime = [];
    for (let i = parseInt(startTime); i <= parseInt(endTime); i++) {
        if (rangeTime.length % 2 === 0) {
          rangeTime.push(moment(i.toString(), "k").format("HH:mm"));
        }
        if (i === parseInt(endTime)) {
          break;
        }
        rangeTime.push(moment(i.toString(), "k").add(30, "minutes").format("HH:mm"));
    }
    return rangeTime;
}

const CurrencyFormat = (amount)=> {
    const currencyFormat = new Intl.NumberFormat('es-MX',{style: 'currency', currency: 'MXN'});
    return `${currencyFormat.format(amount)} MXN`;
}

export {
    ValidateEmail, 
    ValidateCelphoneMX, 
    FormatPrice,
    GetTimeRange,
    CurrencyFormat
}