import { createSlice } from "@reduxjs/toolkit";

export const chefBadgesSlice = createSlice({
    name: 'chefBadges',
    initialState: {
        badges: []
    },
    reducers: {
        setChefBadges: (state, action)=> {
            state.badges = action.payload;
        }
    }
});

export const {setChefBadges} = chefBadgesSlice.actions;
export default chefBadgesSlice.reducer;