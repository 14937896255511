import React from 'react'
import Colors from '../../../theme/Colors';
import  {IoMdSearch} from 'react-icons/io';
import styled from 'styled-components';
import StyledText from '../../atoms/typography/StyledText';
import { screenSizes } from '../../../fixtures/ScreenSizes';

const SearchButton = ({onClick}) => {
    return (
        <ButtonWrapper onClick={onClick} className='d-flex justify-content-center align-items-center'>
            <IoMdSearch style={{pointerEvents: 'none'}} color={Colors.white} size={32} />
            <StyledText 
                text='Buscar' 
                color={Colors.white}
                fontWeight='bold'
            />
        </ButtonWrapper>
    )
}

const ButtonWrapper = styled.button`
    width: 130px;
    height: 38px;
    background-color: ${Colors.mainColor};
    border-radius: 19px;
    cursor: pointer;

    :focus {
        outline: none;
    }

    @media (max-width: ${screenSizes.mediumMax}){
        margin-top: 40px;
    }
`;

export default SearchButton;
