import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Colors from '../../../theme/Colors';
import StyledTitle from '../../atoms/StyledTitle';
import SearchChefs from '../../molecules/SearchChefs';

const ResultChefsHeader = () => {
    return (
        <ResultChefsHeaderWrapper className='result-chefs-header mb-5'>
            <Container>
                <Row>
                    <Col>
                        <SearchChefs backGroundColor={Colors.lightGray} isResultPage/>
                    </Col>
                </Row>
                <Row>
                    <Col lg='8'>
                        <StyledTitle
                            text='Descubre platillos que deleitarán tu paladar en un bocado'
                        />
                    </Col>
                </Row>
            </Container>
        </ResultChefsHeaderWrapper>
    )
}

const ResultChefsHeaderWrapper = styled.section`
`;

export default ResultChefsHeader
