import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Colors from '../../../theme/Colors'
import StyledSubtitle from '../../atoms/StyledSubtitle'
import StyledText from '../../atoms/typography/StyledText'
import ChefFullDayItem from '../../molecules/ChefFullDayItem'
import StepCompleted from '../../molecules/StepCompleted/StepCompleted'

const ChefFullDayReservation = () => {

    const {chef} = useSelector(state => state.chefSelected);
    const {reservation} = useSelector(state => state);

    return (
        <>
            {
                chef.costo_dia > 0 && (
                    <ChefFullDayReservationWrapper className='chef-full-day-reservation-wrapper'>
                        <StyledSubtitle marginBottom={'32px'} text='Reservación full day' />
                        <StyledText text='¿Qué incluye? ' />
                        <StyledText
                            text='Un Chef aliado brindará sus servicios para cocinar los platillos de tu elección en un lapso de 10 horas. Tú, anfitrión, deberás proporcionar todos los suministros para la preparación de los platillos.'
                            color={Colors.gray}
                            marginBottom='16px'
                        />
                        <ChefFullDayItem />
                    </ChefFullDayReservationWrapper>
                )
            }
        </>
    )
}

const ChefFullDayReservationWrapper = styled.div`
    margin-bottom: 40px;
`;

export default ChefFullDayReservation
