import React from 'react'
import styled from 'styled-components'
import { screenSizes } from '../../../fixtures/ScreenSizes';
import Colors from '../../../theme/Colors';
import ListUserOptions from '../../molecules/ListUserOptions/ListUserOptions';
import UserAvatarInfo from '../../molecules/UserAvatarInfo/UserAvatarInfo';

const UserLoggedOption = () => {
    return (
        <UserLoggedOptionsWrapper className='user-logged-options-wrapper'>
            <UserAvatarInfo />
            <ListUserOptions />
        </UserLoggedOptionsWrapper>
    )
}

const UserLoggedOptionsWrapper = styled.div`
    border-radius: 8px;
    background-color: ${Colors.white};
    padding: 16px;
    border: solid 1px ${Colors.lightGray};
    position: absolute;
    top: 0;
    left: 0;
    width: 280px;
    z-index: 100000;
    box-shadow: 6px 6px 6px ${Colors.grayBorder};

    @media (max-width: ${screenSizes.mediumMax}){
        width: 240px;
    }
`;

export default UserLoggedOption
