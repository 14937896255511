import React from 'react'
import styled from 'styled-components'
import Colors from '../../../theme/Colors';
import CheckIcon from '../../atoms/CheckIcon';
import StyledSubtitle from '../../atoms/StyledSubtitle';

function StepCompleted({text}) {
    return (
        <MenuCompletedWrapper>
            <MenuCompletedHolder className='d-flex align-items-center justify-content-between'>
                <StyledSubtitle text={text} />
                <CheckIcon />
            </MenuCompletedHolder>
        </MenuCompletedWrapper>
    )
}

const MenuCompletedWrapper = styled.div`
    margin-bottom: 30px;
`;

const MenuCompletedHolder = styled.div`
    border: solid 1px ${Colors.grayBorder};
    border-radius: 35px;
    height: 70px;
    padding-left: 30px;
    padding-right: 30px;
`;

export default StepCompleted
