import React from 'react'
import styled from 'styled-components'
import Colors from '../../../theme/Colors';
import StyledText from '../../atoms/typography/StyledText';
import {IoCheckmarkCircle, IoRadioButtonOff} from 'react-icons/io5';

const KitchenItem = ({onClick, item, feature, checked}) => {
    return (
        <KitchenItemWrapper onClick={onClick} className='kitchen-item-wrapper'>
            <KitchenItemHolder className='d-flex align-items-center'>
                <ItemWrapper className='d-flex align-items-center' style={{flex:1}}>
                    <StyledText style={{pointerEvents: 'none'}} text={item} fontWeight='600' color={Colors.black} marginRight='40px' />
                    <StyledText style={{pointerEvents: 'none'}} className='featureKitchen' text={feature} color={Colors.gray} />
                </ItemWrapper>
                <CheckWrapper className='check-wrapper'>
                    {
                        checked ?
                            <IoCheckmarkCircle style={{pointerEvents: 'none'}} color={Colors.mainColor} size={32} />
                        :
                            <IoRadioButtonOff style={{pointerEvents: 'none'}} color={Colors.gray} size={32} />
                    }
                </CheckWrapper>
            </KitchenItemHolder>
        </KitchenItemWrapper>
    )
}

const KitchenItemWrapper = styled.div`
    margin-bottom: 12px;
    cursor: pointer;
`;

const KitchenItemHolder = styled.div`
    border: solid 1px ${Colors.gray};
    padding: 20px;
    pointer-events: none;
`;

const ItemWrapper = styled.div`
    pointer-events: none;
`;

const CheckWrapper = styled.div`
    pointer-events: none;
`;

export default KitchenItem

