import React from 'react'
import styled from 'styled-components'
import Colors from '../theme/Colors';

const InputForm = ({className, placeholder, onChange, value, autocapitalize, maxLength}) => {
    return (
        <Input className={className} placeholder={placeholder} value={value} onChange={onChange} autoCapitalize={autocapitalize} maxLength={maxLength} />
    )
}

const Input = styled.input`
    background-color: ${Colors.grayInputForm};
    color: ${Colors.grayTextInput};
    font-size: 0.6em;
    height: 37px;
    border: 0;
    padding-left: 10px;
    width: 217px;

    ::placeholder {
        color: ${Colors.grayTextInput};
    }

    :focus {
        outline: none;
    }
`;

export default InputForm
