import React from 'react'
import styled from 'styled-components'
import ChefListImage from '../../atoms/ChefListImage'
import StyledText from '../../atoms/typography/StyledText'
import ChefLocation from '../ChefLocation'
import ChefRating from '../ChefRating'

const ChefListItem = ({imageUrl, promedioRating, chefName, chefLocation, onCLick}) => {
    return (
        <ChefListItemWrapper onClick={onCLick} className='chef-list-item-wrapper'>
            <ChefListItemHolder className='chef-list-item-holder'>
                <ChefListImage imageUrl={imageUrl} />
                {
                    promedioRating !== 0 &&
                        <ChefRating promedioRating={promedioRating} />
                }
                <StyledText text={chefName} fontWeight='bold' />
                <ChefLocation chefLocation={chefLocation} />
            </ChefListItemHolder>      
        </ChefListItemWrapper>
    )
}

const ChefListItemWrapper = styled.div`
    cursor: pointer;
`;

const ChefListItemHolder = styled.div`

`;

export default ChefListItem
