import Logo from './logo_blanco.png';
import LogoMain from './logo.png';
import ImgHeader from './slider_header_1.png';
import iconDescarga from './icon_descarga.png';
import iconAcudimos from './icon_acudimos.png';
import iconCocinamos from './icon_cocinamos.png';
import iconServimos from './icon_servimos.png';
import UnderlineMain from './underline_main.png';
import ImgApp from './img_app.png';
import Appstore from './appstore.png';
import Playstore from './playstore.png';
import SliderBottom from './slider_bottom.png';
import iconInstagram from './instagram.png';
import iconFacebook from './facebook.png';
import iconTwitter from './twitter.png';
import iconYoutube from './youtube.png';
import btnWhats from './btn_whats.png';
import imgHeaderBecomeChef from './img_header_become_chef.png';
import imgInviteReservation from './img_invite_reservation.png';
import enjoyChef from './enjoy_chef.png';
import placeholderItemEnjoy from './placeholder_item_enjoy.png';
import imgHeaderHome from './img_header_home.png';
import iconDescargaOver from './icon_descarga_over.png';
import iconAcudimosOver from './icon_acudimos_over.png';
import iconCocinamosOver from './icon_cocinamos_over.png';
import anfitrionPerfecto from './anfitrion_perfecto.png';
import iconPlatillosAutor from './icon_platillos_autor.png';
import iconSaboresParaTodos from './icon_sabores_para_todos.png';
import anfitrionPerfectoCel from './anfitrion_perfecto_cel.png';
import anfitrionPerfectoImg from './anfitrion_perfecto_img.png';
import platillosCel from './platillos_cel.png';
import platillosImg from './platillos_img.png';
import saboresCel from './sabores_cel.png';
import saboresImg from './sabores_img.png';
import showProposal from './img_show_proposal.png';
import logoOpenpay from './logo_openpay.png';
import imgHeaderAlliedChef from './img_header_allied_chef.png';
import imgWorkLikeChef from './img_work_like_chef.png';
import bgCulinaryExperience from './bg_culinary_experience.png';
import imgAppLogin from './img_app_login.png';
import iconGanancias from './icon_ganancias.png';
import iconDisponibilidad from './icon_disponibilidad.png';
import iconAlcance from './icon_alcance.png';
import imgStep1HowAlliedChef from './img_step_1_how_allied_chef.png';
import imgStep2HowAlliedChef from './img_step_2_how_allied_chef.png';
import imgStep3HowAlliedChef from './img_step_3_how_allied_chef.png';
import imgHeaderFaqs from './img_header_faqs.png';
import imgHeaderContact from './img_header_contact.png';
import iconMinusCircleGray from './icon_minus_circle_gray.png';
import iconPlusCircleGray from './icon_plus_circle_gray.png';
import imgEnjoyChefMobile from './img_enjoy_chef_mobile.png';
import imgHowItWorksMobile from './img_how_it_works_mobile.png';
import imgShowProposalMobile from './img_show_proposal_mobile.png';
import imgCulinaryExperienceMobile from './culinary_experience_mobile.png';
import imgHeaderAlliedChefMobile from './img_header_allied_chef_mobile.png';
import iconCheckListMain from './icon_check_list_main.png';
import imgContactMobile from './img_contact_mobile.png';
import iconMinusCircleMain from './icon_minus_circle_main.png';
import circleWaveDecor from './circle_wave_decor.png';

const Images = {
    Logo,
    ImgHeader,
    iconDescarga,
    iconAcudimos,
    iconCocinamos,
    iconServimos,
    UnderlineMain,
    ImgApp,
    Appstore,
    Playstore,
    SliderBottom,
    LogoMain,
    iconInstagram,
    iconFacebook,
    iconTwitter,
    iconYoutube,
    btnWhats,
    imgHeaderBecomeChef,
    imgInviteReservation,
    enjoyChef,
    placeholderItemEnjoy,
    imgHeaderHome,
    iconDescargaOver,
    iconAcudimosOver,
    iconCocinamosOver,
    anfitrionPerfecto,
    iconPlatillosAutor,
    iconSaboresParaTodos,
    anfitrionPerfectoCel,
    anfitrionPerfectoImg,
    platillosCel,
    platillosImg,
    saboresCel,
    saboresImg,
    showProposal,
    logoOpenpay,
    imgHeaderAlliedChef,
    imgWorkLikeChef,
    bgCulinaryExperience,
    imgAppLogin,
    iconGanancias,
    iconDisponibilidad,
    iconAlcance,
    imgStep1HowAlliedChef,
    imgStep2HowAlliedChef,
    imgStep3HowAlliedChef,
    imgHeaderFaqs,
    imgHeaderContact,
    iconMinusCircleGray,
    iconPlusCircleGray,
    imgEnjoyChefMobile,
    imgHowItWorksMobile,
    imgShowProposalMobile,
    imgCulinaryExperienceMobile,
    imgHeaderAlliedChefMobile,
    iconCheckListMain,
    imgContactMobile,
    iconMinusCircleMain,
    circleWaveDecor
}   

export default Images;