import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Colors from '../../../theme/Colors';
import MedalIcon from '../../atoms/MedalIcon';
import StyledText from '../../atoms/typography/StyledText';
import parse from 'html-react-parser';
import './CareerPathTile.css';

const CareerPath = () => {

    const {chef} = useSelector(state => state.chefSelected);

    return (
        <CareerPathWrapper>
            <div className='d-flex align-items-center mb-4'>
                <MedalIcon className='mr-2' size={16} />
                <StyledText text='Trayectoria Profesional' />
            </div>
            {parse(chef.trayectoria)}
            {/* <StyledText text={} color={Colors.gray} /> */}
        </CareerPathWrapper>
    )
}

const CareerPathWrapper = styled.div`

`;

export default CareerPath
