import React from 'react'
import styled from 'styled-components'
import Colors from '../../../theme/Colors'
import Tab from '../../atoms/Tab'
import DetailReservation from '../DetailReservation'

const MyReservation = () => {
    return (
        <MyReservationWrapper className='my-reservation-wrapper'>
            <TabContainer>
                <Tab text='Mi reservación' cursor='auto' />
            </TabContainer>
            <DetailReservation />
        </MyReservationWrapper>
    )
}

const MyReservationWrapper = styled.div``;

const TabContainer = styled.div`
    border-bottom: solid 1px ${Colors.grayBorder};
    margin-bottom: 25px;
`;

export default MyReservation
