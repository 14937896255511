import { useState, useEffect } from "react"
import { callApi } from "../../../../fixtures/api";


const useGetAvailableLocations = () => {
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        getLocations();
    }, [])


    const getLocations = async()=> {
        const result = await callApi ('get_available_locations', 'post');
        if(result.status === 200){
            setLocations(result.locations);
        }else{
            
        }
    }

    return {
        locations
    }
}

export default useGetAvailableLocations
