import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import FullOverlay from '../../atoms/FullOverlay';
import Youtube from 'react-youtube';
import './VideoModal.css';
import {IoCloseCircleOutline} from 'react-icons/io5';
import Colors from '../../../theme/Colors';

const VideoModal = () => {

    const [showVideo, setShowVideo] = useState(true);

    const video_ref = useRef(undefined);

    const resetVideo = (e)=> {
        e.target.stopVideo();
    }

    const closeVideo = ()=> {
        video_ref.current.internalPlayer.stopVideo();
        setShowVideo(false);
    }

    return (
        <>
            {
                showVideo &&
                <FullOverlay>
                    <VideoWrapper className='video-wrapper'>
                        <VideoHolder className='video-holder'>
                            <IconCloseContainer>
                                <IconClose onClick={closeVideo} color={Colors.white} size={32} />
                            </IconCloseContainer>
                            <Youtube ref={video_ref} containerClassName='container-video-home' id='video-home' videoId='GNiklX1bIMc' opts={{width: '100%', height: '100%', playerVars: {rel: 0, autoplay: 0}}} onEnd={resetVideo}/>
                        </VideoHolder>
                    </VideoWrapper>
                </FullOverlay>
            }
        </>
    )
}

const VideoWrapper = styled.div`
    position: relative;
    width: 80%;
    height: 70%;
`;

const VideoHolder = styled.div`
    position: relative;
    height: 100%;
`;

const IconCloseContainer = styled.div`
    text-align: right;
`;

const IconClose = styled(IoCloseCircleOutline)`
    cursor: pointer;
`;

export default VideoModal
