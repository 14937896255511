import React from 'react'
import styled from 'styled-components'
import Colors from '../../theme/Colors';
import StyledText from './typography/StyledText';

const Tab = ({active=true, text, onClick, cursor}) => {
    return (
        <TabWrapper
            className='tab-wrapper d-flex justify-content-center align-items-center'
            onClick={onClick}
            active={active}
            cursor={cursor}
        >
            <StyledText color={active ? Colors.white : Colors.gray} text={text} />
        </TabWrapper>
    )
}

const TabWrapper = styled.div`
    cursor: ${props => props.cursor || 'pointer' };
    width: 302px;
    height: 44px;
    background-color: ${props => props.active ? Colors.mainColor : Colors.lightGray};
`;

export default Tab
