import React from 'react';
import styled from 'styled-components';
import StyledTitle from '../../atoms/StyledTitle';
import StyledText from '../../atoms/typography/StyledText';
import moment from 'moment';
import LabelCategory from '../../atoms/LabelCategory';

const ArticleItem = ({post, onClick}) => {

    return (
        <ArticleItemWrapper key={post.id} className='article-item-wrapper' onClick={onClick}>
            <ArticleItemHolder className='article-item-holder'>
                <ImgPost className='img-fluid' src={post.imagen_principal} />
                <TitlePostWrapper>
                    <StyledTitle
                        text={post.titulo}
                        fontSize='16px'
                    />
                </TitlePostWrapper>
                <StyledText
                    text={moment.unix(post.created_at.seconds).format('DD MMMM YYYY')}
                    marginBottom='16px'
                />
                <CategoriesWrapper className='categories-wrapper'>
                    {
                        post.categorias.map((category)=>{
                            return(
                                <LabelCategory category={category} />
                            )
                        })
                    }
                </CategoriesWrapper>
            </ArticleItemHolder>
        </ArticleItemWrapper>
    )
}

const ArticleItemWrapper = styled.div`
    margin-bottom: 45px;
    cursor: pointer;
`;

const ArticleItemHolder = styled.div`
    pointer-events: none;
`;

const ImgPost = styled.img`
    border-radius: 9px;
    margin-bottom: 24px;
    pointer-events: none;
`;

const TitlePostWrapper = styled.div`
    min-height: 80px;
    pointer-events: none;
`;

const CategoriesWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    pointer-events: none;
`;

export default ArticleItem
