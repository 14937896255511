
import React from 'react'
import styled from 'styled-components'
import { CircleWaveDecor } from '../../components/StyledComponents';
import { config } from '../../fixtures/config';
import { screenSizes } from '../../fixtures/ScreenSizes';
import Images from '../../images/Images';
import Colors from '../../theme/Colors';

const Header = () => {
    return (
        <HeaderWrapper className='header-wrapper mb-1'>
            <CircleWaveDecor absolute className='img-fluid' src={Images.circleWaveDecor} />
            <div className='container'>
                <div className='row d-flex justify-content-md-center'>
                    <div className='col-10 d-block d-md-none'>
                        <Title>Reserva un Chef a domicilio y disfruta del momento</Title>
                    </div>
                    <div className='col-10 d-block d-md-none'>
                        <ParagraphHeader className='d-block d-md-none'>Comparte tus mejores momentos con aquellos que más te importan. Descarga la app y vive la experiencia KaiChef</ParagraphHeader>
                    </div>
                    <div className='col-md-6 mb-3 mg-md-0'>
                        <ImgHeaderHome src={Images.imgHeaderHome} />
                    </div>
                    <div className='col-md-6 align-self-center'>
                        <Title className='d-none d-md-block'>Reserva un Chef a domicilio y disfruta del momento</Title>
                        <ParagraphHeader className='d-none d-md-block'>Comparte tus mejores momentos con aquellos que más te importan. Descarga la app y vive la experiencia KaiChef</ParagraphHeader>
                        <ButtonsHolder>
                            <a href={config.urlAppStore} target="_blank" rel='noreferrer'>
                                <AppStore src={Images.Appstore} />
                            </a>
                            <a href={config.urlPlayStore} target="_blank" rel='noreferrer'>
                                <PlayStore src={Images.Playstore} />
                            </a>
                        </ButtonsHolder>
                    </div>
                </div>
                <div className='row justify-content-end'>
                    <div className='col-3'>
                        <CircleWaveDecor className='img-fluid' src={Images.circleWaveDecor} />
                    </div>
                </div>
            </div>
        </HeaderWrapper>
    )
}

const HeaderWrapper = styled.header`
    position: relative;
`;

const ImgHeaderHome = styled.img`
    height: 554px;
    display: block;
    margin: 0 auto;
    width: 343px;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        height: 325px;
        width: 205px;
    }
`;

const Title = styled.h1`
    color: ${Colors.blue};
    font-size: 35px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin-bottom: 20px;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 20px;
    }
`;

const ParagraphHeader = styled.p`
    color: ${Colors.blue};
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    margin-bottom: 40px;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 12px;
    }
`;

const ButtonsHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const AppStore = styled.img`
    height: 40px;
    margin-right: 50px;
    width: 120px;
`;

const PlayStore = styled.img`
    height: 35px;
    width: 120px;
`;

export default Header
