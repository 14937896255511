import React from 'react';
import {IoCloseCircle} from 'react-icons/io5';
import Colors from '../../theme/Colors';

const CloseIcon = ({color, size, onClick}) => {
    return (
        <IoCloseCircle 
            className='justify-content-end' 
            color={color || Colors.mainColor}
            size={size || 30}
            onClick={onClick}
            cursor='pointer'
        />
    )
}

export default CloseIcon
