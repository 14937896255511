import { createSlice } from "@reduxjs/toolkit"; 

const minComensales = 2;

export const InitialState = {
    menu: {},
    estado: '',
    municipio: '',
    fecha: '',
    hora: '',
    comensales: minComensales,
    codigo_promocion: '',
    costo_total: 0,
    costo_total_descuento: 0,
    cocina: [],
    fuegos: '',
    restricciones_alimentarias: [],
    otras_alergias: false,
    otras_alergias_text: '',
    usuario_id: '',
    usuario_nombre: '',
    usuario_celular: '',
    direccion: {},
    chef_nombre: '',
    chef_id: '',
    numero_reserva: '',
    fecha_solicitud: '',
    production: '',
};

export const reservationSlice = createSlice({
    name: 'reservation',
    initialState: InitialState,
    reducers: {
        setMenu: (state, action)=> {
            state.menu = action.payload;
            state.costo_total = state.comensales*state.menu.precio;
        },
        setEstado: (state, action)=> {
            state.estado = action.payload;
        },
        setMunicipio: (state, action)=> {
            state.municipio = action.payload;
        },
        setFecha: (state, action)=> {
            state.fecha = action.payload;
        }, 
        setHora: (state, action)=> {
            state.hora = action.payload;
        },
        addComensales: (state, action)=> {
            if(state.active){
                state.comensales = state.comensales + 1;
                state.costo_total = (state.comensales*(state.fechas ? state.fechas.length : 0))*state.costo_dia; 
            }else{
                state.comensales = state.comensales + 1;
                state.costo_total = state.comensales*state.menu.precio; 
            }
        },
        removeComensales: (state)=> {
            if(state.active){
                if(state.comensales !== minComensales){
                    state.comensales = state.comensales - 1;
                    state.costo_total = (state.comensales*(state.fechas ? state.fechas.length : 0))*state.costo_dia;
                }
            }else{
                if(state.comensales !== minComensales){
                    state.comensales = state.comensales - 1;
                    state.costo_total = state.comensales*state.menu.precio; 
                }
            }
        },
        setCodigoPromocion: (state, action)=>{
            state.codigo_promocion = action.payload;
        },
        setCostoTotal: (state, action)=> {
            state.costo_total = action.payload;
        },
        setCostoTotalDescuento: (state, action)=> {
            state.costo_total_descuento = action.payload;
        },
        setCocina: (state, action)=> {
            state.cocina = action.payload
        },
        setFuegos: (state, action)=>{
            state.fuegos = action.payload;
        },
        setRestriccionesAlimentarias: (state, action)=> {
            state.restricciones_alimentarias = action.payload;
        },
        setOtrasAlergias: (state, action)=>{
            state.otras_alergias = action.payload;
        },
        setOtrasAlergiasText: (state, action)=>{
            state.otras_alergias_text = action.payload;
            state.otras_alergias = action.payload !== '';
        },
        setUsuario: (state, action)=> {
            state.usuario_id = action.payload.id;
            state.usuario_nombre = action.payload.nombre;
            state.usuario_celular = action.payload.celular;
        },
        setDireccion: (state, action)=> {
            state.direccion = action.payload;
        },
        setChef: (state, action)=>{
            state.chef_id = action.payload.id;
            state.chef_nombre = `${action.payload.nombre} ${action.payload.apellido_materno}`;
        },
        setNumeroReserva: (state, action)=> {
            state.numero_reserva = action.payload;            
        },
        setFechaSolicitud: (state, action)=> {
            state.fecha_solicitud = action.payload;
        },
        unsetReservation: ()=>{
            return InitialState;
        },
        setReservation: (state, action)=>{
            return action.payload;
        },
        setProductionReservation: (state, action)=> {
            state.production = action.payload
        },
        setReservationActive: (state,action)=> {
            state.active = true;
            state.costo_dia = action.payload;
        },
        setFechas: (state, action)=> {
            state.fechas = action.payload;
            state.costo_total = (state.comensales*action.payload.length)*state.costo_dia;
        },
        setHoraInicial: (state, action)=> {
            state.hora_inicial = action.payload;
        },
        setHoraFinal: (state, action)=> {
            state.hora_final = action.payload;
        }
    }
});

export const {
    setMenu, 
    setEstado,
    setMunicipio,
    setFecha,
    setHora,
    addComensales,
    removeComensales,
    setCodigoPromocion,
    setCostoTotal,
    setCostoTotalDescuento,
    setCocina,
    setFuegos,
    setRestriccionesAlimentarias,
    setOtrasAlergias,
    setOtrasAlergiasText,
    setUsuario,
    setDireccion,
    setChef,
    setNumeroReserva,
    setFechaSolicitud,
    unsetReservation,
    setReservation,
    setProductionReservation,
    setReservationActive,
    setFechas,
    setHoraInicial,
    setHoraFinal
} = reservationSlice.actions;

export default reservationSlice.reducer;