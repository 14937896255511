import { createSlice } from "@reduxjs/toolkit";

const userLoggedSlice = createSlice({
    name: 'userLogged',
    initialState: {
        userData: {}
    },
    reducers: {
        setUserLogged: (state, action)=> {
            state.userData = action.payload;
        },
        unsetUserLogged: (state)=> {
            state.userData = {};
        },
        setUserConfiguration: (state, action)=> {
            state.userData.configuracion = action.payload
        },
        setUserName: (state, action)=> {
            state.userData.nombre = action.payload
        },
        setUserLastName: (state, action)=> {
            state.userData.apellidos = action.payload
        },
        setOpenPayId: (state, action)=> {
            state.userData.openpay_id = action.payload;
        },
        setOpenPayIdProd: (state, action)=> {
            state.userData.openpay_id_prod = action.payload;
        }
    }
});

export const {
    setUserLogged,
    unsetUserLogged,
    setUserConfiguration,
    setUserName,
    setUserLastName,
    setOpenPayId,
    setOpenPayIdProd
} = userLoggedSlice.actions;

export default userLoggedSlice.reducer;