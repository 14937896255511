const gregorian_en_lowercase = {
    name: "lang_es",
    months: [
      ["Enero", "Ene"],
      ["Febrero", "Feb"],
      ["Marzo", "Mar"],
      ["Abril", "Abr"],
      ["Mayo", "May"],
      ["Junio", "Jun"],
      ["Julio", "Jul"],
      ["Agosto", "Ago"],
      ["Septiembre", "Sep"],
      ["Octubre", "Oct"],
      ["Noviembre", "Nov"],
      ["Diciembre", "Dic"],
    ],
    weekDays: [
      ["Sábado", "Sa"],
      ["Domingo", "Do"],
      ["Lunes", "Lu"],
      ["Martes", "Ma"],
      ["Miércoles", "Mi"],
      ["Jueves", "Ju"],
      ["Viernes", "Vi"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["AM", "am"],
      ["PM", "pm"],
    ],
  };
  
  export default gregorian_en_lowercase;