import React from 'react';
import styled from 'styled-components';
import { screenSizes } from '../../../fixtures/ScreenSizes';
import Colors from '../../../theme/Colors';

const StyledText = ({
    text, 
    color, 
    fontSize, 
    fontWeight, 
    className, 
    counter, 
    onClick, 
    isClickeable, 
    textAlign,
    marginBottom,
    marginTop,
    marginRight,
    marginLeft,
    style,
    border,
    borderRadius
}) => {
    return (
        <Text 
            style={style}
            counter={counter || null}
            className={className}
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
            onClick={onClick}
            isClickeable={isClickeable}
            textAlign={textAlign}
            marginBottom={marginBottom}
            marginTop={marginTop}
            marginRight={marginRight}
            marginLeft={marginLeft}
            border={border}
            borderRadius={borderRadius}
        >
            {text}
        </Text>    
    )
}

const Text = styled.p`
    color: ${props => props.color || Colors.blue};
    font-size: ${props => props.fontSize || '16px'};
    font-weight: ${props => props.fontWeight || 'normal'};
    text-decoration: ${props => props.textDecoration || 'none' };
    pointer-events: ${props => props.isClickeable ? 'all' : 'none'};
    cursor: ${props => props.isClickeable ? 'pointer' : 'none'};
    text-align: ${props => props.textAlign || 'left' };
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-top: ${props => props.marginTop || '0px'};
    margin-left: ${props => props.marginLeft || '0px'};
    margin-right: ${props => props.marginRight || '0px'};
    border: ${props => props.border};
    border-radius: ${props => props.borderRadius};
    padding-left: ${props => props.border && '16px' };
    padding-right: ${props => props.border && '16px' };

    @media (max-width: ${screenSizes.mediumMax}){
        font-size: 12px;
    }
`;

export default StyledText;
