import React from 'react'
import styled from 'styled-components'
import { screenSizes } from '../../fixtures/ScreenSizes';
import Colors from '../../theme/Colors';

const StyledSubtitle = ({
    text, 
    color, 
    fontSize, 
    fontWeight, 
    className, 
    marginTop, 
    marginBottom,
    isClickeable,
    onClick
}) => {
    return (
        <Subtitle
            className={className}
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
            marginBottom={marginBottom}
            marginTop={marginTop}
            isClickeable={isClickeable}
            onClick={isClickeable ? onClick : null}
        >
            {text}
        </Subtitle>
    )
}

const Subtitle = styled.h2`
    color: ${props => props.color || Colors.blue};
    font-size: ${props => props.fontSize || '20px'};
    font-weight: ${props => props.fontWeight || '500'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-top: ${props => props.marginTop || '0px'};
    cursor: ${props => props.isClickeable && 'pointer'};

    @media (max-width: ${screenSizes.mediumMax}) {
        font-size: 16px;
    }
`;

export default StyledSubtitle;
