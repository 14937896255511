import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Images from '../../images/Images';
import Colors from '../../theme/Colors';
import Youtube from 'react-youtube';
import Carousel, { consts }  from 'react-elastic-carousel';
import { screenSizes } from '../../fixtures/ScreenSizes';
import { IconPlusMinusCircle } from '../../components/StyledComponents';

const Faqs = () => {

    const [showCategoryFaqs, setShowCategoryFaqs] = useState('general');
    const [enableSwipe, setEnableSwipe] = useState(false);

    useEffect(() => {
        const handleResize = ()=> {
            if(window.innerWidth < 992){
                setEnableSwipe(true);
            }
            if(window.innerWidth > 992){
                setEnableSwipe(false);
            };
        };

        if(window.innerWidth < 992){
            setEnableSwipe(true);
        };
        if(window.innerWidth > 992){
            setEnableSwipe(false);
        };

        window.addEventListener('resize', handleResize);

        return ()=>{
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const resetVideo1 = (e)=> {
        e.target.stopVideo();
    }

    const resetVideo2 = (e)=> {
        e.target.stopVideo();
    }

    const chandeIconShowedAnser = (e)=> {
        const iconCircle = e.target.lastChild.getAttribute('src');
        const questionActive = e.target.firstChild.getAttribute('style');
        if(questionActive){
            e.target.firstChild.setAttribute('style', '');
        }else{
            e.target.firstChild.setAttribute('style', `color: ${Colors.mainColor};`);
        }
        if(iconCircle === Images.iconMinusCircleMain){
            e.target.lastChild.setAttribute('src',Images.iconPlusCircleGray);
        }else{
            e.target.lastChild.setAttribute('src',Images.iconMinusCircleMain);
        }
    }

    return (
        <FaqsWrapper>
            
            <div className='container d-md-none mb-3'>
                <div className='row'>
                    <div className='col-8 col-sm-6'>
                        <Title blue>Preguntas frecuentes</Title>
                    </div>
                </div>
            </div>
            <Header className='container mb-5 d-flex flex-column justify-content-end'>
                <div className='row d-none d-md-block'>
                    <div className='col-sm-8 col-lg-5 offset-lg-1'>
                        <Title>Preguntas frecuentes</Title>
                    </div>
                </div>
            </Header>
            
            <IntroFaqsWrapper className='container intro-faqs-wrapper py-5'>
                <TitleSection className='mb-3 mb-md-5'>Las respuestas a todas tus dudas</TitleSection>
                <Text>En Kaichef somos transparentes y honestos acerca de cómo funciona la plataforma, lo que incluye el servicio, tus responsabilidades como Chef privado o Anfitrión y qué hacemos en caso de que nuestro servicio no se lleve a cabo como debería. En esta sección desglosamos las preguntas más relevantes para ti.</Text>
            </IntroFaqsWrapper>

            <SliderWrapper className='mb-5 py-5'>
                <Carousel
                        enableSwipe={enableSwipe}
                        itemsToShow={1}
                        showArrows={false}
                        outerSpacing={0}
                        itemPosition={consts.START}
                        itemPadding={[0, 5]}
                        focusOnSelect={false}
                >
                    <SliderHolder className='container'>
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-lg-5 offset-lg-1 mb-4 mb-lg-0 justify-content-center'>
                                <div className='d-none d-md-block'>
                                    <SubTitleWhite>Las dudas más frecuentes</SubTitleWhite>
                                    <TitleWhite className='mb-4'>Platillo principal</TitleWhite>
                                </div>
                                <div className='d-md-none'>
                                    <TitleWhite className='mb-4'>Descubre que es KaiChef</TitleWhite>
                                </div>
                                <TextWhite>¿Qué hacemos, cómo funciona y qué hacemos en caso de que no funcione?</TextWhite>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-center'>
                                <VideoWrapper>
                                    <Youtube videoId='1SAzd901fwE' opts={{width: 444, height: 320, playerVars: {rel: 0}}} onEnd={resetVideo1}/>
                                </VideoWrapper>
                            </div>
                        </div>
                    </SliderHolder>
                    <SliderHolder className='container'>
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-lg-5 offset-lg-1 mb-4 mb-lg-0 justify-content-center'>
                                <div className='d-none d-md-block'>
                                    <SubTitleWhite>Bienvenido a</SubTitleWhite>
                                    <TitleWhite className='mb-4'>La aventura Kaichef</TitleWhite>
                                </div>
                                <div className='d-md-none'>
                                    <TitleWhite className='mb-4'>Bienvenido a la aventura</TitleWhite>
                                </div>
                                <TextWhite>Sé un chef excepcional con estos consejos.  La excelencia en tu servicio lograran experiencias inolvidables, cuida cada detalle todo saldrá perfecto.</TextWhite>
                            </div>
                            <div className='col-lg-6'>
                                <VideoWrapper>
                                    <Youtube videoId='GQfbaQ83jkI' opts={{width: 444, height: 320, playerVars: {rel: 0}}} onEnd={resetVideo2}/>
                                </VideoWrapper>
                            </div>
                        </div>
                    </SliderHolder>
                </Carousel>
            </SliderWrapper>

            <FaqsHolder className='container faqs-holder mb-5'>
                <div className='row'>
                    <div className='col-12 col-lg-2 mb-2 mb-lg-0'>
                        <div className='row'>
                            <div className='col-3 col-lg-12'>
                                <TitleFaqs selected={showCategoryFaqs === 'general'} onClick={()=>{setShowCategoryFaqs('general')}}>GENERAL</TitleFaqs>
                            </div>
                            <div className='col-3 col-lg-12'>
                                <TitleFaqs selected={showCategoryFaqs === 'comensal'} onClick={()=>{setShowCategoryFaqs('comensal')}}>COMENSAL</TitleFaqs>
                            </div>
                            <div className='col-3 col-lg-12'>
                                <TitleFaqs selected={showCategoryFaqs === 'chef'} onClick={()=>{setShowCategoryFaqs('chef')}}>CHEF</TitleFaqs>
                            </div>
                            {/* <div className='col-3 col-lg-12'>
                                <TitleFaqs selected={showCategoryFaqs === 'politicas} onClick={()=>{setShowCategoryFaqs('politicas')}}>POLITICAS</TitleFaqs>
                            </div> */}
                        </div>
                    </div>
                    <div className='col-12 col-lg-10'>
                        {
                            showCategoryFaqs === 'general' &&
                                <div className='faqs-general-wrapper'>
                                    <div className='FaqsGeneral'>
                                        {/* <FaqTitle data-bs-toggle="collapse" href="#FaqsGeneral">General</FaqTitle>
                                        <hr /> */}
                                        <FaqsCategoryHolder className='' id='FaqsGeneral'>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-1">
                                                    <Faq>¿Qué es KaiChef?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-1'>
                                                    <Answer>KaiChef es una plataforma y una aplicación móvil que te permite contratar el servicio de un Chef privado a domicilio. KaiChef da la oportunidad a los Comensales de probar nuevas experiencias culinarias y, por otra parte, permite a los cocineros ganar un ingreso extra de su apasionante profesión.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-2">
                                                    <Faq>¿Debo pagar para poder descargar KaiChef?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-2'>
                                                    <Answer>No. Descargar KaiChef es totalmente gratuito.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-3">
                                                    <Faq>¿Cómo puedo saber si kaichef esta disponible en mi ciudad?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-3'>
                                                    <Answer>KaiChef esta disponible en varias ciudades del país, para saber si el servicio esta disponible en tu ciudad ingresa a la aplicación, y consulta los chefs disponibles en tu ciudad actual.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-4">
                                                    <Faq>¿Quiénes son los Chefs?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-4'>
                                                    <Answer>Los Chefs que puedes contratar en KaiChef son cocineros profesionales, con al menos un año de experiencia como Chefs privados o Chefs en un restaurante y que buscan ofrecer un servicio diferente a su trabajo habitual.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-5">
                                                    <Faq>¿Por qué reservar un Chef a domicilio?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-5'>
                                                    <Answer>KaiChef ofrece una nueva forma de vivir experiencias gastronómicas. Ahora, una reunión con amigos, una cena romántica o una comida de negocios puede convertirte en un momento único. Sin preocupaciones acerca de la sazón, de lavar los platos y de la limpieza de tu cocina al final de la velada.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-6">
                                                    <Faq>¿Qué incluyen los servicios de KaiChef?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-6'>
                                                    <AnswerList>
                                                        <li>Elaboración de los platos en el domicilio del comensal.</li>
                                                        <li>Servicio de mesa.</li>
                                                        <li>Limpieza de área de trabajo.</li>
                                                    </AnswerList>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-7">
                                                    <Faq>¿Qué Chef debería elegir?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-7'>
                                                    <Answer>Deberías revisar el perfil del Chef, su experiencia y los menús que te ofrece para tomar la decisión. También te invitamos a leer la opinión de otros Comensales para que puedas tomar la mejor decisión. Además, los Chefs que mejor valoración tengan en la plataforma, estarán en la sección de Chefs Destacados. Recuerda dejar tu opinión, es muy importante.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-8">
                                                    <Faq>¿Qué pasa si el Chef cancela el servicio?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-8'>
                                                    <Answer>¡No te preocupes! Te devolveremos tu dinero y revisaremos profundamente el caso para llegar a la deliberación correspondiente. Para más información, puedes consultar la sección de Política de Cancelaciones.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-9">
                                                    <Faq>¿Cómo funcionan las opiniones e insignias?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-9'>
                                                    <Answer>Para KaiChef, las opiniones son fundamentales. Al finalizar el servicio, ambas partes recibirán un cuestionario de evaluación. Al Comensal se le asignará una evaluación gastronómica y de servicio completo. Las buenas calificaciones permitirán a los Chefs obtener mayor confianza y podrán aparecer en la sección de Chefs Destacados. Además, una buena forma de agradecer algún apartado del servicio que haya sido particularmente bueno, es otorgando una insignia destacada.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-10">
                                                    <Faq>¿Qué métodos de pago acepta kaichef?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-10'>
                                                    <Answer>Los métodos de pago aceptados en KaiChef son tarjeta bancaria y PayPal.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-11">
                                                    <Faq>¿Puedo pagar en efectivo mi reservación?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-11'>
                                                    <Answer>Por el momento esta forma de pago no esta incluida. Puedes utilizar tu cuenta Paypal como forma de pago segura y/o tarjeta bancaria.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                        </FaqsCategoryHolder>
                                    </div>
                                </div>
                        }
                        {
                            showCategoryFaqs === 'comensal' &&
                                <div className='faqs-comensal-wrapper'>
                                    <div className='FaqsCrearCuentaUsuario'>
                                        <FaqTitle data-bs-toggle="collapse" href="#FaqsCrearCuentaUsuario">Crear una cuenta de usuario</FaqTitle>
                                        <hr />
                                        <FaqsCategoryHolder className='collapse' id='FaqsCrearCuentaUsuario'>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-1">
                                                    <Faq>¿Cómo funciona Kaichef?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-1'>
                                                    <Answer>KaiChef permite a los Comensales realizar reservaciones del servicio de un Chef privado. El Comensal llena una solicitud donde proporciona todos los detalles del evento y elije el menú del Chef de su preferencia.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-2">
                                                    <Faq>¿Cómo creo una cuenta kaichef?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-2'>
                                                    <Answer>Para crear una cuenta en KaiChef, es necesario que proporciones una dirección de correo electrónico y un número de telefono válidos. Además deberás ingresar una contraseña segura y aceptar nuestros Términos y Condiciones, y el Aviso de Privacidad.</Answer>
                                                    <Answer>Una vez que brindes esta información, te enviaremos un correo electrónico para confirmar el registro de tu cuenta. Después de la confirmación, podrás usar la app para reservar al chef de tu elección.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                        </FaqsCategoryHolder>
                                    </div>
                                    <div className='FaqsDownloadApp'>
                                        <FaqTitle data-bs-toggle="collapse" href="#FaqsDownloadApp">¿Cómo descargar la app de usuario?</FaqTitle>
                                        <hr />
                                        <FaqsCategoryHolder className='collapse' id='FaqsDownloadApp'>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-3">
                                                    <Faq>¿Cómo descargar la app de usuario (iOS)?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-3'>
                                                    <Answer>Puedes descargar e instalar la app de KaiChef para usuarios en dispositivos con iOS 11.0 o posterior. Abre la App Store en tu dispositivo móvil y, luego, sigue los pasos a continuación.</Answer>
                                                    <AnswerOrderList>
                                                        <li>Toca Buscar en la barra de menú en la parte inferior y, luego, escribe KaiChef en la barra de búsqueda.</li>
                                                        <li>Selecciona KaiChef en los resultados de búsqueda.</li>
                                                        <li>Toca Obtener, junto al icono de KaiChef. Puede que debas ingresar tu Apple ID o contraseña.</li>
                                                        <li>Una vez que se complete la descarga, la opción Obtener cambiará a Abrir. Toca Abrir para iniciar la app.</li>
                                                        <li>En la app de Kaichef, toca Iniciar sesión si tienes una cuenta o Regístrate para crear una.</li>
                                                    </AnswerOrderList>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-4">
                                                    <Faq>¿Cómo descargar la app de usuario (Android)?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-4'>
                                                    <Answer>Puedes descargar e instalar la app de KaiChef para usuarios en dispositivos con Android 5.1 o posterior. Abre la Playstore en tu dispositivo móvil y, luego, sigue los pasos a continuación.</Answer>
                                                    <AnswerOrderList>
                                                        <li>Toca Buscar en la barra de menú en la parte superior y, luego, escribe KaiChef en la barra de búsqueda.</li>
                                                        <li>Selecciona KaiChef en los resultados de búsqueda.</li>
                                                        <li>Toca Instalar, debajo del icono de KaiChef.</li>
                                                        <li>Una vez que se complete la descarga, la opción Instalar cambiará a Abrir. Toca Abrir para iniciar la app.</li>
                                                        <li>En la app de KaiChef, toca Iniciar sesión si tienes una cuenta o Regístrate para crear una.</li>
                                                    </AnswerOrderList>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                        </FaqsCategoryHolder>
                                    </div>
                                    <div className='FaqsReservar'>
                                        <FaqTitle data-bs-toggle="collapse" href="#FaqsReservar">¿Cómo reservar?</FaqTitle>
                                        <hr />
                                        <FaqsCategoryHolder className='collapse' id='FaqsReservar'>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-5">
                                                    <Faq>¿Cómo pedir una reservación?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-5'>
                                                    <Answer>Para hacer una reservación, primero debes ingresar en la app de KaiChef e ingresar de manera exitosa. Una vez hayas ingresado, sigue estos pasos:</Answer>
                                                    <AnswerOrderList>
                                                        <li>Ingresa tu localidad o el lugar donde quieras que se lleve a cabo la reservación.</li>
                                                        <li>En la pantalla se mostrarán los Chefs que están disponibles en tu zona. En caso de que no haya ningún Chef disponible, no te preocupes, ya que trabajamos constantemente para aumentar el alcance de la app de KaiChef.</li>
                                                        <li>Se desplegarán todos los estilos gastronómicos y los Chefs que se encuentran disponibles en tu localidad. Elige el que más te guste. </li>
                                                        <li>Una vez que hayas seleccionado a tu Chef preferido, seleccionad la modalidad de Reserva por día o Reservar por menú. En caso de elegir Reserva por menú, deberás seleccionar el menú del Chef que más te haya llamado la atención.</li>
                                                        <li>Una vez seleccionado el menú, se desplegará un pequeño formulario para especificar el estado y municipio, así como la fecha, hora y número de comensales.</li>
                                                        <li>Una vez hayas completado el formulario, presiona el botón continuar y seleccionad tu método de pago preferido.</li>
                                                    </AnswerOrderList>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-6">
                                                    <Faq>¿Cuándo y dónde está disponible Kaichef en mi ciudad?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-6'>
                                                    <Answer>En KaiChef trabajamos diariamente para ofrecer un servicio de excelencia en la mayor parte de México. Si ningún Chef se encuentra disponible en tu localidad, puedes comunicarte con nosotros a ayuda@kaichef.com para que estemos al tanto de tu petición y prioricemos tu localidad al momento de expandir el alcance de KaiChef.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                        </FaqsCategoryHolder>
                                    </div>
                                    <div className='FaqsTiposReservacion'>
                                        <FaqTitle data-bs-toggle="collapse" href="#FaqsTiposReservacion">Tipos de reservación</FaqTitle>
                                        <hr />
                                        <FaqsCategoryHolder className='collapse' id='FaqsTiposReservacion'>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-7">
                                                    <Faq>¿Qué es una reservación por menú?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-7'>
                                                    <Answer>Una reservación por menú es un servicio de catering en KaiChef, donde tu Chef privado promueve su propuesta gastronómica, a través un menú compuesto por platillos en un mismo estilo gastronómico. En esta modalidad, tu Chef llevará los insumos y materiales requeridos (vajilla, cristalería y cubiertos) y preparará los alimentos para servir al número de comensales incluidos en tu reservación.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-8">
                                                    <Faq>¿Qué es una reservación por día?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-8'>
                                                    <Answer>Es una contratación de un Chef privado para trabajar en tu domicilio (usuario contratante) durante 1 o más días en una jornada máxima de 10 horas. En esta modalidad, los insumos deberán ser proporcionados por ti (usuario contratante). El Chef deberá preparar desayuno, comida y cena, mantener limpia el área de trabajo durante y al terminar sus jornadas de servicio. Durante su jornada de servicio el chef deberá coordinarse contigo (usuario contratante) para decidir qué platillos deberá realizar y proporcionarle la lista de insumos que deberás comprar.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                        </FaqsCategoryHolder>
                                    </div>
                                    <div className='FaqsTiemposContacto'>
                                        <FaqTitle data-bs-toggle="collapse" href="#FaqsTiemposContacto">Tiempos, programación y contacto</FaqTitle>
                                        <hr />
                                        <FaqsCategoryHolder className='collapse' id='FaqsTiemposContacto'>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-9">
                                                    <Faq>¿Cuál es el tiempo mínimo para realizar una reservación?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-9'>
                                                    <Answer>Debes realizar al menos 24 horas antes la reservación para que pueda llevarse a cabo la misma.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-10">
                                                    <Faq>¿Cómo programo una reservación con anticipación?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-10'>
                                                    <Answer>Dentro de la aplicación de KaiChef, una vez que seleccionaste tu menú y Chef favorito, se te indicará los días y horas disponibles de cada uno de nuestros Chefs privados.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                        </FaqsCategoryHolder>
                                    </div>
                                    <div className='FaqsDuranteReserva'>
                                        <FaqTitle data-bs-toggle="collapse" href="#FaqsDuranteReserva">Durante la reserva</FaqTitle>
                                        <hr />
                                        <FaqsCategoryHolder className='collapse' id='FaqsDuranteReserva'>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-11">
                                                    <Faq>Selecciona el método de pago para una reservación</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-11'>
                                                    <Answer>Una vez hayas completado de personalizar tu reservación, puedes seleccionar tu método de pago preferido. Dentro de la aplicación de KaiChef, se aceptan la mayoría de las tarjetas de crédito y débito del país, incluido Paypal. En caso de que te encuentres con algún problema al momento de seleccionar tu método de pago o no sea aceptado, comunícate con nuestro soporte técnico al correo ayuda@kaichef.com para recibir atención a la brevedad.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-12">
                                                    <Faq>¿Cómo consultar mi historial de reservas activas?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-12'>
                                                    <Answer>Para consultar tu historial de reservas deberás ingresar a la aplicación, ir a la sección mis reservas, seleccionar reservas activas y ver más para que puedas visualizar as reservaciones actuales que has realizado.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-13">
                                                    <Faq>¿Cómo finalizar mi reservación?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-13'>
                                                    <Answer>Una vez concluida tu reservación, deberás ingresar a la aplicación, ir a la sección mis reservas, elegir tu reservación en curso y finalizar el servicio. Dando por concluido el servicio correctamente.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                        </FaqsCategoryHolder>
                                    </div>
                                    <div className='FaqsDespuesReserva'>
                                        <FaqTitle data-bs-toggle="collapse" href="#FaqsDespuesReserva">Despues de la reserva</FaqTitle>
                                        <hr />
                                        <FaqsCategoryHolder className='collapse' id='FaqsDespuesReserva'>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-14">
                                                    <Faq>Calificar a un Chef Aliado</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-14'>
                                                    <Answer>Una vez concluida tu reservación, deberás ingresar a la aplicación, ir a la sección mis reservas, elegir tu reservación en curso y finalizar el servicio. Al finalizar la reservación podras calificar a tu chef, dejar una reseña del servicio y otrogarle un insignia como reconocimiento (opcioal).</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-15">
                                                    <Faq>¿Puedo facturar mi reservación?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-15'>
                                                    <Answer>Si necesitas facturar alguno de tus servicios kaichef, debes ponerte en contacto al correo facturas@kaichef.com para solicitar la facturación correspondiente de tus reservaciones finalizadas.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-16">
                                                    <Faq>¿En caso de un incidente como puedo contactarlos?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-16'>
                                                    <Answer>Si tienes alguna incidente o necesitas ayuda los canales de soporte de kaichef para nuestros  usuarios son la página web www.kaichef.com , las redes sociales oficiales (facebook, Twitter e instagram) y el correo ayuda@kaichef.com con gusto te apoyaremos.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                        </FaqsCategoryHolder>
                                    </div>
                                    <div className='FaqsInsigniasKaiChef'>
                                        <FaqTitle data-bs-toggle="collapse" href="#FaqsInsigniasKaiChef">Insignias KaiChef</FaqTitle>
                                        <hr />
                                        <FaqsCategoryHolder className='collapse' id='FaqsInsigniasKaiChef'>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-17">
                                                    <Faq>¿Qué son las Insignias de KaiChef?</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-17'>
                                                    <Answer>En KaiChef, estamos comprometidos con las buenas costumbres cuando están los platos sobre la mesa. Por eso, queremos mostrarle al mundo personas extraordinarias. Chefs y comensales que, ante todo, demuestran su valor humano y hacen de una simple comida, la mejor experiencia. Aquí te explicamos qué son estás insignias y cómo funcionan.</Answer>
                                                    <Answer>Una vez terminado el servicio, se les proporcionará un formulario de satisfacción tanto al Chef como al Comensal. Una buena forma de agradecer cuando algún apartado del servicio fue particularmente bueno, es otorgando una insignia destacada.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                        </FaqsCategoryHolder>
                                    </div>
                                    <div className='FaqsSoporteUsuarios'>
                                        <FaqTitle data-bs-toggle="collapse" href="#FaqsSoporteUsuarios">Soporte para usuarios</FaqTitle>
                                        <hr />
                                        <FaqsCategoryHolder className='collapse' id='FaqsSoporteUsuarios'>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-18">
                                                    <Faq>Necesito ayuda para registrarme en una cuenta de usuario</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-18'>
                                                    <Answer>Si presentas algun problema para registrate en la aplicación es necesario  que te comuniques a soporte técnico al correo ayuda@kaichef.com para recibir atención.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-19">
                                                    <Faq>Mi teléfono o correo electrónico ya están en uso.</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-19'>
                                                    <Answer>Si tu número telefonico y/o correo electrónico se encuentra activo en la plataforma con una cuenta kaichef, deberás restaurar tu contraseña y generar una nueva para poder recuperar el acceso a tu cuenta, en la sección olvide mi contraseña. La aplicación te pedirá por seguridad que confirmes tu identidad con un mensaje de verificacion al número o correo electrónico que diste de alta al crear la cuenta kaichef.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                            <FaqWrapper>
                                                <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-20">
                                                    <Faq>Error al agregar un método de pago</Faq>
                                                    <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-20'>
                                                    <Answer>Si presentas algun problema para agregar un método de pago es necesario te comuniques a soporte para usuarios al correo ayuda@kaichef.com, si el problema persiste será necesario comunicarte directamente con tu entidad financiera correspondiente.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                        </FaqsCategoryHolder>
                                    </div>
                                </div>
                        }
                        {
                            showCategoryFaqs === 'chef' &&
                            <div className='faqs-chefs-wrapper'>
                                <div className='FaqsRegisterChef'>
                                    <FaqTitle data-bs-toggle="collapse" href="#FaqsRegisterChef">Registrarme como Chef Aliado</FaqTitle>
                                    <hr />
                                    <FaqsCategoryHolder className='collapse' id='FaqsRegisterChef'>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-1">
                                                <Faq>¿Quién puede ser Chef?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-1'>
                                                <Answer>Cualquier persona que tenga trayectoria en el área culinaria que avale sus conocimientos como cocinero profesional y que posea al menos un año de experiencia.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-2">
                                                <Faq>¿Cómo puedo registrarme como chef aliado?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-2'>
                                                <Answer>Si deseas integrarte al programa de KaiChef, debes saber qué necesitas para registrarte como Chef Aliado. Para poder afiliarte como Chef deberás cumplir con ciertos requisitos, entre ellos comprobar tu experiencia que avale tus conocimientos como cocinero profesional, un año de experiencia en el sector gastronómico, y tener en regla tu documentación, como tu identificación oficial, carta de no antecedentes penales, entre otros.  Además de estos documentos, recuerda que debes tener 18 años o más, pasar por una revisión de tu perfil, y cumplir con la documentación correctamente.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-3">
                                                <Faq>¿Qué necesito para darme de alta?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-3'>
                                                <Answer>Los documentos que tendrás que tener en regla, son:</Answer>
                                                <AnswerList>
                                                    <li>Identificación Oficial (INE o Pasaporte Vigente)</li>
                                                    <li>CURP</li>
                                                    <li>Comprobante de domicilio</li>
                                                    <li>Curriculum</li>
                                                    <li>Fotografía</li>
                                                </AnswerList>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                    </FaqsCategoryHolder>
                                </div>
                                <div className='FaqsBeneficiosChef'>
                                    <FaqTitle data-bs-toggle="collapse" href="#FaqsBeneficiosChef">Beneficios</FaqTitle>
                                    <hr />
                                    <FaqsCategoryHolder className='collapse' id='FaqsBeneficiosChef'>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-4">
                                                <Faq>¿Qué beneficios tengo como chef aliado?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-4'>
                                                <Answer>Con Kaichef puedes generar ingresos como chef aliado adaptados a tu disponibilidad de horario y capacidad de servicio.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                    </FaqsCategoryHolder>
                                </div>
                                <div className='FaqsPreciosChef'>
                                    <FaqTitle data-bs-toggle="collapse" href="#FaqsPreciosChef">Precio</FaqTitle>
                                    <hr />
                                    <FaqsCategoryHolder className='collapse' id='FaqsPreciosChef'>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-5">
                                                <Faq>¿Qué precio pongo a mis servicios?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-5'>
                                                <Answer>Como Chef Aliado eres libre de poner el precio que tú consideres oportuno por tus servicios.  KaiChef se limita únicamente a proveer de nuevos comensales a nuestros chefs aliados.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-6">
                                                <Faq>¿Por qué es importante definir el alcance de servicio?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-6'>
                                                <Answer>El alcance del servicio indicará donde estará disponible tu servicio como chef privado dentro de la aplicación. Es muy importante que definas las ciudades donde podrás realizar reservaciones, con Kaichef tienes la libertad de prestar tus servicios en uno o más estados según tus capacidades de atención.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                    </FaqsCategoryHolder>
                                </div>
                                <div className='FaqsDisponibilidad'>
                                    <FaqTitle data-bs-toggle="collapse" href="#FaqsDisponibilidad">Disponibilidad</FaqTitle>
                                    <hr />
                                    <FaqsCategoryHolder className='collapse' id='FaqsDisponibilidad'>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-7">
                                                <Faq>¿Por qué es importante definir la disponibilidad de horario?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-7'>
                                                <Answer>Tu disponibilidad de horario se basa en seleccionar los días y el horario en que te encontrarás disponible para recibir reservaciones de tus comensales como chef privado.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                    </FaqsCategoryHolder>
                                </div>
                                <div className='FaqsFacturacionChef'>
                                    <FaqTitle data-bs-toggle="collapse" href="#FaqsFacturacionChef">Facturación y datos bancarios</FaqTitle>
                                    <hr />
                                    <FaqsCategoryHolder className='collapse' id='FaqsFacturacionChef'>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-8">
                                                <Faq>¿Por qué kaichef necesita mis datos de facturación?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-8'>
                                                <Answer>Para generar una factura mensual que incluye la totalidad de los cobros realizados en el mes. Esta factura se expide en términos de las reglas misceláneas 2.7.1.16. y 12.1.4., por lo que tiene validez para ser deducida del ISR y para acreditar el IVA.</Answer>
                                                <Answer>Así como enviar mensualmente a las autoridades fiscales mexicanas determinada información proporcionada por los afiliados tal como su Nombre, RFC, CURP, CLABE bancaria, e ingresos e impuestos retenidos. La información que se comparte corresponde tanto a personas físicas como a personas morales.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-9">
                                                <Faq>¿Por qué kaichef solicita mis datos bancarios?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-9'>
                                                <Answer>Esto es algo que se requiere para que tus cobros lleguen. Como empresa de pagos, también debe solicitar esta información a fin de cumplir con las normas globales de servicios financieros.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-10">
                                                <Faq>¿Deberé pagar impuestos sobre mis ganancias?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-10'>
                                                <Answer>Te recomendamos que acudas con tu contador o asesor para conocer tus obligaciones tributarias o visita nuestro <strong>obligaciones fiscales kaichef aliado</strong></Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-11">
                                                <Faq>¿Qué debo considerar para compartir información sobre mi trayectoria como chef?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-11'>
                                                <Answer>Para Kaichef es muy importante destacar la trayectoria profesional de nuestros chefs aliados, para redactar tu trayectoria deberas considerar lo siguiente:</Answer>
                                                <AnswerList>
                                                    <li>Experiencia dentro del área</li>
                                                    <li>Años de servicio</li>
                                                    <li>Empresas destacadas en las cuales has trabajado</li>
                                                    <li>Colaboraciones importantes con chefs destacados</li>
                                                    <li>Áreas de especialización</li>
                                                </AnswerList>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                    </FaqsCategoryHolder>
                                </div>
                                <div className='FaqsReservationsChefs'>
                                    <FaqTitle data-bs-toggle="collapse" href="#FaqsReservationsChefs">Como tener reservaciones</FaqTitle>
                                    <hr />
                                    <FaqsCategoryHolder className='collapse' id='FaqsReservationsChefs'>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-12">
                                                <Faq>¿Qué es una reservación?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-12'>
                                                <Answer>Es la contratación pagada que realiza un usuario registrado en la plataforma kaichef, en la cual solicita los servicios programados en mes/día/hora, para un número especifico de comensales. El usuario registrado seleccionará el chef aliado de su preferencia para que le brinde los servicios de catering y/o servicio de chef privado, según sea el caso.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-13">
                                                <Faq>¿Qué debo considerar para realizar correctamente mi reservación como chef aliado?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-13'>
                                                <Answer>La experiencia gastronomica es 100% responsabilidad del chef aliado, es por ello que deberas cuidar cada detalle y considerar la vajilla que utilizaras para montar los platillos del menu degustacion que tu anfitrión ha contratado. Así mismo la cristaleria y cubiertos. Deberas considerar también la puntualidad para servir el menu, la calidad y organización de tu servicio.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-14">
                                                <Faq>¿Cuántas reservaciones tengo capacidad de atender simultaneamente en un día?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-14'>
                                                <Answer>Sabemos que algunos de nuestros chefs aliados tienen la capacidad y estructura que les permite atender mas de una reservación de manera simultanea, ya que en su estructura cuentan con personal de apoyo que podran acudir en el momento requerido en su represesentacion bajo el estandar de calidad y supervisión del chef titular.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                    </FaqsCategoryHolder>
                                </div>
                                <div className='FaqsReservaMenuChef'>
                                    <FaqTitle data-bs-toggle="collapse" href="#FaqsReservaMenuChef">Reservación por menú</FaqTitle>
                                    <hr />
                                    <FaqsCategoryHolder className='collapse' id='FaqsReservaMenuChef'>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-15">
                                                <Faq>¿Qué es una reservación por menú?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-15'>
                                                <Answer>Una reservación por menú es un servicio de catering en kaichef, donde el chef promueve su propuesta gastronomica, mediante un menú compuesto por platillos en un mismo estilo gastronómico. En esta modalidad al ser un servicio de catering el chef debe considerar los insumos que requiera para preparar los alimentos, vajilla, cristaleria y cubiertos para servir al numero de comensales incluidos en su reservación.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-16">
                                                <Faq>¿Qué debo considerar para asignarle a un precio al menú que he diseñado en la modalidad de reservación por menú?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-16'>
                                                <Answer>Debes incluir en el precio por menú:</Answer>
                                                <AnswerList>
                                                    <li>Costo de los insumos.</li>
                                                    <li>Costo de mano de obra por la preparación de los platillos.</li>
                                                    <li>Costo por transportación y viaticos.</li>
                                                    <li>Costo del auxiliar de apoyo para servir y/o limpieza.</li>
                                                    <li>Limpieza del area de la cocina/comedor donde trabajaste.</li>
                                                    <li>Margen de ganancia</li>
                                                    <li>I.VA.</li>
                                                </AnswerList>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                    </FaqsCategoryHolder>
                                </div>
                                <div className='FaqsReservaDiaChef'>
                                    <FaqTitle data-bs-toggle="collapse" href="#FaqsReservaDiaChef">Reservación por día completo</FaqTitle>
                                    <hr />
                                    <FaqsCategoryHolder className='collapse' id='FaqsReservaDiaChef'>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-17">
                                                <Faq>¿Qué es una reservación por día completo?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-17'>
                                                <Answer>Es una contratación como chef privado, para trabajar en un domicilio durante 1 o más días en una jornada máxima de 10 horas. En esta modalidad los insumos deberán ser porporcionados por el usuario que contrato el servicio. El chef deberá preparar desayuno, comida y cena, mantener limpia el área de trabajo durante y al terminar sus jornada de servicio. Durante su jornada de servicio el chef deberá coordinarse con el usuario contratante para decidir que platillos deberá realizar y a su vez proporcionarle la lista de insumos que el usuario debera comprar.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-18">
                                                <Faq>¿Qué debo considerar para asignarle a un precio al menú que he diseñado en la modalidad de reservación por día completo?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-18'>
                                                <Answer>Debes incluir en el precio:</Answer>
                                                <AnswerList>
                                                    <li>Costo de mano de obra por la preparación de los platillos.</li>
                                                    <li>Costo por transportación y viaticos.</li>
                                                    <li>Costo del auxiliar de apoyo para servir y/o limpieza.</li>
                                                    <li>Limpieza del area de la cocina/comedor donde trabajaste.</li>
                                                    <li>Margen de ganancia</li>
                                                    <li>I.VA.</li>
                                                </AnswerList>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                    </FaqsCategoryHolder>
                                </div>
                                <div className='FaqsAntesReservaChef'>
                                    <FaqTitle data-bs-toggle="collapse" href="#FaqsAntesReservaChef">Antes de la reservación</FaqTitle>
                                    <hr />
                                    <FaqsCategoryHolder className='collapse' id='FaqsAntesReservaChef'>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-19">
                                                <Faq>¿Cómo puedo saber si tengo una reservación nueva?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-19'>
                                                <Answer>Una vez que el comensal registre su reservación, Kaichef te notificará mediante un email para confirmarte todos los datos de tu próximo servicio, como el menú que eligieron tus comensales, sus restricciones alimenticias y las características de la cocina para que tu puedas llevar a acabo el servicio apropiadamente.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-20">
                                                <Faq>¿Por qué no puedo contactar al Comensal antes de que reserven?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-20'>
                                                <Answer>Requerimos que todo contacto entre Chef y Comensal sea hasta que se formalice una reserva para poder mantener la privacidad de nuestros usuarios, proporcionarles servicio al cliente y adherirnos a las Condiciones de Uso de KaiChef.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-21">
                                                <Faq>¿Qué ocurre si no asisto a la cita con un comensal?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-21'>
                                                <Answer>Para KaiChef lo más importantes es la responsabilidad y respeto entre el comensal y el Chef. Cada caso será evaluado individualmente para decidir si el Chef en cuestión merece ser expulsado de la plataforma o el tipo de sanción que será aplicada.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-22">
                                                <Faq>¿Qué sucede si mi comensal cancela su reserva?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-22'>
                                                <Answer>En caso de que, derivado de cancelaciones, devoluciones, descuentos o modificaciones en los servicios, KaiChef restituya a los afiliados el cobro por el uso de la plataforma, así como el IVA correspondiente, KaiChef emitirá un comprobante a los afiliados en el que conste el monto del cobro restituido, así como del IVA trasladado correspondiente siempre que el afiliado haya informado a KaiChef su RFC, y que este sea válido.</Answer>
                                                <Answer>En caso de que se restituyan a los comensales los montos pagados por los servicios derivado de cancelaciones, devoluciones, descuentos, bonificaciones o modificaciones en dichos servicios, y siempre que los afiliados no hubieran optado porque las retenciones efectuadas por KaiChef se considerarán como pagos definitivos, los afiliados podrán disminuir el monto restituido por dichas operaciones a través de la presentación de una declaración complementaria del mes al que corresponda dicha operación, debiendo reflejar dicho cambio en su contabilidad para poder aplicar dicho beneficio.</Answer>
                                                <Answer>Adicionalmente los afiliados deberán emitir un Comprobante Fiscal Digital por Internet (CFDI) de Egresos, conocido como “Nota de Crédito”, a los comensales en el que conste el monto restituido, así como el IVA trasladado correspondiente. Si la operación fue cancelada en su totalidad, en lugar de emitir una nota de crédito, el afiliado deberá cancelar la factura emitida por dicha operación.</Answer>
                                                <Answer>Este monto devuelto o cancelado lo disminuirás de tus ingresos cuando calcules tu pago provisional, y el IVA devuelto también lo disminuirás del IVA a pagar cuando calcules tu pago mensual.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                    </FaqsCategoryHolder>
                                </div>
                                <div className='FaqsInsigniasChef'>
                                    <FaqTitle data-bs-toggle="collapse" href="#FaqsInsigniasChef">Insignias KaiChef</FaqTitle>
                                    <hr />
                                    <FaqsCategoryHolder className='collapse' id='FaqsInsigniasChef'>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-23">
                                                <Faq>¿Cómo puedo obtener más calificaciones de cinco estrellas?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-23'>
                                                <Answer>Las calificaciones son muy importantes para la comunidad de KaiChef, como plataforma nos ayuda a evaluar tus cualidades como cocinero profesional. Para nosotros es fundamental ofrecer una experiencia gastronómica única, y cuidar todos los detalles para que tu reservación sea perfecta.</Answer>
                                                <Answer>Confiamos en tus cualidades más allá de la cocina: tu compromiso, amabilidad, puntualidad, limpieza y talento. Son cualidades que te harán brillar durante cada servicio frente a tus comensales y mantener una calificación alta dentro de la plataforma.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-24">
                                                <Faq>¿Por qué es importante ganar insignias?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-24'>
                                                <Answer>Las insignias KaiChef son el reconocimiento que los comensales otorgan al chef contratado para resaltar las cualidades de sus ervicio, estas insignias te permitiran destacar dentro de la plataforma.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-25">
                                                <Faq>¿Qué significan las insignias de KaiChef?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-25'>
                                                <Answer>La insignia de limpieza va para los Chefs que enfocaron su esfuerzo en la limpieza de tu cocina y eran muy cuidadosos de no ensuciar nada mientras preparaban tus platillos.</Answer>
                                                <Answer>La insignia de puntualidad es para el Chef que tuvo los tiempos siempre presentes y entregó todo en el momento exacto y conforme al horario estipulado.</Answer>
                                                <Answer>La insignia de sabor va dirigida a aquellos Chefs cuyos platillos superaron ampliamente las expectativas del comensal, haciendo de cada bocado una nueva experiencia.</Answer>
                                                <Answer>La insignia de servicio es para los Chefs que se esforzaron en hacerte sentir único, esta insignia es una excelente manera de retribuirle.</Answer>
                                                <Answer>La insignia de amabilidad es para los Chefs que son particularmente atentos y corteses. Esto puede crear una atmósfera más relajada y amistosa.</Answer>
                                                <Answer>La insignia de excepcional es cuando consideras que el Chef sobresalió en todos los aspectos y actuó correctamente ante cualquier adversidad que se le presentó para ofrecerte un servicio que superó con creces tus expectativas. Son contados los Chefs que consiguen esta insignia.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                    </FaqsCategoryHolder>
                                </div>
                                <div className='FaqsSoporteChef'>
                                    <FaqTitle data-bs-toggle="collapse" href="#FaqsSoporteChef">Soporte para Chefs Aliados</FaqTitle>
                                    <hr />
                                    <FaqsCategoryHolder className='collapse' id='FaqsSoporteChef'>
                                        <FaqWrapper>
                                            <FaqHolder onClick={chandeIconShowedAnser} className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-26">
                                                <Faq>¿Existe algún espacio de comunicación entre kaichef y los chefs aliados?</Faq>
                                                <IconPlusMinusCircle src={Images.iconPlusCircleGray} />
                                            </FaqHolder>
                                            <AnswerWrapper className='collapse' id='a-26'>
                                                <Answer>Si tienes alguna inquietud o necesitas ayuda los canales de soporte de kaichef para nuestros Chefs Afiliados son la página web www.kaichef.com , las redes sociales oficiales (facebook, Twitter e instagram) y el correo de ayuda soporte@kaichef.com o puedes  comunicarte a nuestro canal de whats app de soporte al (55) 7853 3785.</Answer>
                                            </AnswerWrapper>
                                            <hr />
                                        </FaqWrapper>
                                    </FaqsCategoryHolder>
                                </div>
                            </div>
                        }
                        {/* {
                            showCategoryFaqs === 'politicas' &&
                                <div className='faqs-politicas-wrapper'>
                                    <div className='FaqsPoliticas'>
                                        <FaqTitle data-bs-toggle="collapse" href="#FaqsPoliticas">Politicas</FaqTitle>
                                        <hr />
                                        <FaqsCategoryHolder className='collapse' id='FaqsPoliticas'>
                                            <FaqWrapper>
                                                <FaqHolder className='d-flex align-items-center justify-content-between mb-2' data-bs-toggle="collapse" href="#a-1">
                                                    <Faq>¿Quién puede ser Chef?</Faq>
                                                    <Icon.AiOutlinePlusCircle color={Colors.grayFooter} size={24} />
                                                </FaqHolder>
                                                <AnswerWrapper className='collapse' id='a-1'>
                                                    <Answer>No existe un límite de tiempo, pero te invitamos a que sea lo más rápido posible, ya que el comensal podría elegir otro Chef si tardas demasiado tiempo.</Answer>
                                                </AnswerWrapper>
                                                <hr />
                                            </FaqWrapper>
                                        </FaqsCategoryHolder>
                                    </div>
                                </div>
                        } */}
                    </div>
                </div>
            </FaqsHolder>

        </FaqsWrapper>
    )
}

const FaqsWrapper = styled.div`

`;

const Header = styled.section`
    min-height: 416px;
    background: url(${Images.imgHeaderFaqs}) top center no-repeat;
    background-size: cover;
`;

const Title = styled.h2`
    color: ${props => props.blue ? Colors.blue : Colors.white};
    font-size: 35px;
    font-weight: bold;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}){
        font-size: 20px;
    }
`;

const IntroFaqsWrapper = styled.section`

`;

const TitleSection = styled.h2`
    font-size: 35px;
    color: ${Colors.blue};
    font-weight: bold;
    text-align: center;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 18px;
        text-align: left;
    }
`;

const Text = styled.p`
    font-size: 20px;
    text-align: center;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 14px;
        text-align: left;
    }
`;

const SliderWrapper = styled.section`
    background-color: ${Colors.blue};
`;

const SliderHolder = styled.div`
    
`;

const TitleWhite = styled.h3`
    font-size: 42px;
    color: ${Colors.white};
    font-weight: bold;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 28px;
    }
`;

const SubTitleWhite = styled.h4`
    font-size: 28px;
    color: ${Colors.white};
    font-weight: bold;
    margin-bottom: 0;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 20px;
    }
`;

const TextWhite = styled.p`
    font-size: 24px;
    color: ${Colors.white};

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 14px;
    }
`;

const VideoWrapper = styled.div`
    width: 444px;
    height: 320px;
    border-radius: 8px;
    overflow: hidden;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        width: 345px;
    }
`;

const FaqWrapper = styled.div`

`;

const FaqsHolder = styled.section`

`;

const TitleFaqs = styled.h4`
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    color: ${(props => props.selected ? Colors.mainColor : Colors.grayFooter)};
    font-weight: 700;
    cursor: pointer;

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 18px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 12px;
    }
`;

const FaqTitle = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    color: ${Colors.grayParagraph};
    font-weight: 700;
    cursor: pointer;

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 18px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 12px;
    }
`;

const FaqsCategoryHolder = styled.div`

`;

const FaqHolder = styled.div`
    cursor: pointer;
`;

const Faq = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    color: ${Colors.blue};
    font-weight: 700;
    pointer-events: none;

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 18px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 12px;
    }
`;

const AnswerWrapper = styled.div`

`;

const Answer = styled.p`
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: ${Colors.grayParagraph};
    padding-left: 90px;
    margin-bottom: 8px;

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 12px;
        padding-left: 30px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 12px;
        padding-left: 20px;
    }
`;

const AnswerList = styled.ul`
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: ${Colors.grayParagraph};
    padding-left: 90px;
    margin-bottom: 8px;

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 12px;
        padding-left: 30px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 12px;
        padding-left: 20px;
    }
`;

const AnswerOrderList = styled.ol`
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: ${Colors.grayParagraph};
    padding-left: 90px;
    margin-bottom: 8px;

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 12px;
        padding-left: 30px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 12px;
        padding-left: 20px;
    }
`;


export default Faqs;
