import React from 'react'
import styled from 'styled-components'

const ChefProfilePicture = ({chefProfilePicture}) => {
    return (
        <ChefProfilePictureWrapper className='chef-profile-picture-wrapper'> 
            <Picture className='img-fluid' src={chefProfilePicture} />
        </ChefProfilePictureWrapper>
    )
}

const ChefProfilePictureWrapper = styled.div` 
`;

const Picture = styled.img`
    
`;

export default ChefProfilePicture
