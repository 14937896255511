import React, { useEffect, useState } from 'react';
import {NavLink} from "react-router-dom";
import styled from 'styled-components';
import { screenSizes } from '../../fixtures/ScreenSizes';
import Images from '../../images/Images';
import Colors from '../../theme/Colors';
import * as Icon from 'react-icons/ai';
import gsap from 'gsap';
import { Col } from 'react-bootstrap';
import MainButton from '../../design/molecules/MainButton';
import {IoPersonCircle} from 'react-icons/io5';
import { useSelector } from 'react-redux';
import UserAvatar from '../../design/atoms/UserAvatar';
import UserLoggedOption from '../../design/organisms/UserLoggedOptions/UserLoggedOption';
import LoginModal from '../../design/organisms/LoginModal/LoginModal';
import RegisterModal from '../../design/organisms/RegisterModal/RegisterModal';
import RecoveryPassModal from '../../design/organisms/RecoveryPassModal/RecoveryPassModal';

const MainMenu = () => {

    const {userData} = useSelector(state => state.userLogged);
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showRecoveryPassModal, setShowRecoveryPassModal] = useState(false);
    const [showUserOptions, setShowUserOptions] = useState(false);

    useEffect(()=>{
        document.getElementById('root').addEventListener('click', closeUserOptions);
        return ()=> {
            window.removeEventListener('click', closeUserOptions);
        }
    },[]);

    const closeUserOptions = ()=> {
        setShowUserOptions(false);
    }

    const openMenu = ()=> {
        gsap.to('.btn-mobile-holder', {opacity: 0});
        gsap.to('.menu-holder', {height: '200px', opacity: 1, display: 'flex', duration: 1});
        gsap.to('.btn-close-menu-holder', {display: 'block', opacity: 1});
    }

    const closeMenu = ()=> {
        gsap.to('.btn-mobile-holder', {opacity: 1});
        gsap.to('.menu-holder', {height: '0px', opacity: 0, display: 'none', duration: 1});
        gsap.to('.btn-close-menu-holder', {display: 'none', opacity: 0});
    }

    const NavActive = {
        color: Colors.mainColor
    };

    const handleClickRegister = ()=> {
        setShowRegisterModal(true);
    }

    const handleClickProfile = (e)=> {
        e.stopPropagation();
        if(Object.keys(userData).length > 0){
            setShowUserOptions(true);
        }else{
            setShowLoginModal(true);
        }
    }

    return (
        <>
            <MenuWrapper className='menu-wrapper mb-5'>
                <TopMenu className='container'>
                    <div className='row'>
                        <div className='col-4 col-lg-2 d-flex align-items-center'>
                            <Logo>
                                <NavLink to='/'>KAICHEF</NavLink>
                            </Logo>
                        </div>
                        <div className='col-2 col-lg-7 d-flex align-self-center justify-content-end order-2 order-lg-1'>
                            <BtnMenuMobileHolder className='btn-mobile-holder'>
                                <Icon.AiOutlineMenu onClick={openMenu} color={Colors.mainColor} size={32} />
                            </BtnMenuMobileHolder>
                            <MainMenuWrapper className='main-menu-wrapper'>
                                <MenuHolder className='menu-holder'>
                                    <MenuItem>
                                        <NavLink activeStyle={NavActive} onClick={closeMenu} to='/chef_aliado'>CHEF ALIADO</NavLink>
                                    </MenuItem>
                                    <MenuItem>
                                        <NavLink activeStyle={NavActive} onClick={closeMenu} to='/preguntas_frecuentes'>PREGUNTAS FRECUENTES</NavLink>
                                    </MenuItem>
                                    <MenuItem>
                                        <NavLink activeStyle={NavActive} onClick={closeMenu} to='/blog'>BLOG</NavLink>
                                    </MenuItem>
                                    <MenuItem>
                                        <NavLink activeStyle={NavActive} onClick={closeMenu} to='/contacto'>CONTACTO</NavLink>
                                    </MenuItem>
                                </MenuHolder>
                                <BtnMenuCloseHolder className='btn-close-menu-holder'>
                                    <Icon.AiOutlineClose onClick={closeMenu} color={Colors.mainColor} size={32} />
                                </BtnMenuCloseHolder>
                            </MainMenuWrapper>
                        </div>
                        <Col lg='3' className='col-6 order-1 order-lg-2'>
                            <UserActionsWrapper className='d-flex align-items-center justify-content-end'>    
                                {
                                    Object.keys(userData).length === 0 &&
                                        <MainButton 
                                            text='Regístrate' 
                                            border={`solid 1px ${Colors.grayBorder}`} 
                                            backGroundColor='transparent'
                                            color={Colors.gray}
                                            onClick={handleClickRegister}
                                        />
                                }
                                {
                                    userData.avatar ?
                                        <UserAvatar isClickeable userImage={userData.avatar} onClickCapture={handleClickProfile} />
                                    :
                                        <IconUser onClickCapture={handleClickProfile} size={40} />
                                }
                            </UserActionsWrapper>
                            {
                                    showUserOptions &&
                                <UserLoggedOption />
                            }
                        </Col>
                    </div>
                </TopMenu>
            </MenuWrapper>
            {
                showLoginModal &&
                <LoginModal 
                    closeLoginModal={()=>{setShowLoginModal(false)}}
                    showRecoveryPassModal={()=>{setShowRecoveryPassModal(true)}}
                    showRegisterModal={()=>{setShowRegisterModal(true)}}
                />
            }
            {
                showRegisterModal &&
                <RegisterModal closeRegisterModal={()=>{setShowRegisterModal(false)}} />
            }
            {
                showRecoveryPassModal &&
                <RecoveryPassModal closeRecoveryPassModal={()=>{setShowRecoveryPassModal(false)}} />
            }
        </>

    )
}

const MenuWrapper = styled.div`
    padding-top: 14px;
    padding-Bottom: 33px;
`;

const TopMenu = styled.div`
    
`;

const Logo = styled.h1`
    margin: 0px;
    width: 146px;
    height: 39px;

    a {
        display: block;
        text-indent: -10000px;
        width: 100%;
        height: 100%;
        background: url(${Images.LogoMain}) no-repeat center top;
        background-size: contain;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        width: 114px;
        height: 30px;
    }
`;

const BtnMenuMobileHolder = styled.div`
    display: none;

    @media (max-width: ${screenSizes.mediumMax}) {
        display: block;
    }
`;

const MenuHolder = styled.ul`
    display: flex;
    list-style: none;
    margin: 0px;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        display: none;
        height: 0px;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding-left: 0;
        flex-direction: column;
        z-index: 1000;
        padding-top: 60px;
    }

    @media (min-width: ${screenSizes.mediumMax}) {
        display: flex !important;
        opacity: 1 !important;
        height: auto !important;
    }
    
`;

const MainMenuWrapper = styled.div`
    position: relative;
`;

const BtnMenuCloseHolder = styled.div`
    opacity: 0;
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 1000;
    background-color: ${Colors.white};
`;

const MenuItem = styled.li`
    padding: 0px 15px;
    font-family: 'Oswald', 'sans-serif';
    font-size: 18px;
    color: ${Colors.black};
    background-color: ${Colors.white};

    a {
        text-decoration: none;   
    }
    
    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        padding: 14px 30px;
        font-size: 20px;
        border-bottom: solid 1px ${Colors.grayBenefitsHolder}
    }

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        flex-direction: column;
        padding: 0px 15px;
        font-size: 16px;
    }

`;

const UserActionsWrapper = styled.div``;

const IconUser = styled(IoPersonCircle)`
    cursor: pointer;
    color: ${Colors.gray};

    :hover {
        color: ${Colors.mainColor};
    }
`;


export default MainMenu;
