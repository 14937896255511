import React from 'react';
import styled from 'styled-components';
import {BiMedal} from 'react-icons/bi';
import Colors from '../../theme/Colors';

const MedalIcon = ({className, color, size}) => {
    return (
        <MedalIconWrapper className='medal-icon-wrapper'>
            <BiMedal className={className} color={color || Colors.blue} size={size || 12} />
        </MedalIconWrapper>
    )
}

const MedalIconWrapper = styled.div`
`;

export default MedalIcon
