import React from 'react'
import styled from 'styled-components'

const InputCheckBox = ({name, className, checked, onChange}) => {
    return (
        <CheckBox className={className} type='checkbox' checked={checked} name={name} onChange={onChange} />
    )
}

const CheckBox = styled.input``;

export default InputCheckBox
