import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { addComensales, removeComensales, setCocina, setCodigoPromocion, setCostoTotalDescuento, setDireccion, setEstado, setFecha, setFechas, setFuegos, setHora, setHoraFinal, setHoraInicial, setMunicipio, setOtrasAlergiasText, setRestriccionesAlimentarias } from '../../../redux/slices/reservationSlice';
import Colors from '../../../theme/Colors';
import StyledSubtitle from '../../atoms/StyledSubtitle';
import StyledText from '../../atoms/typography/StyledText';
import "react-multi-date-picker/styles/colors/red.css";
import Calendar from '../../molecules/Calendar/Calendar';
import { CurrencyFormat, GetTimeRange } from '../../../fixtures/helpers';
import moment from 'moment';
import StepCompleted from '../../molecules/StepCompleted/StepCompleted';
import Counter from '../../molecules/Counter.js';
import BtnPromoCode from '../../atoms/BtnPromoCode';
import swal from 'sweetalert';
import Loading from '../../../components/Loading';
import { callApi } from '../../../fixtures/api';
import MainButton from '../../molecules/MainButton';
import KitchenItem from '../../molecules/KitchenItem/KitchenItem';
import Img2fuegos from '../../../images/img_2_fuegos.png';
import Img3fuegos from '../../../images/img_3_fuegos.png';
import Img4fuegos from '../../../images/img_4_fuegos.png';
import Img5fuegos from '../../../images/img_5_fuegos.png';
import {IoCheckmarkCircle, IoRadioButtonOff} from 'react-icons/io5';
import RestrictionItem from '../../molecules/RestrictionItem/RestrictionItem';
import InputAddress from '../../molecules/InputAddress/InputAddress';
import { config } from '../../../fixtures/config';
import useGetUserCards from '../../../hooks/useGetUserCards';
import CardItem from '../../molecules/CardItem/CardItem';
import RegisterModal from '../../organisms/RegisterModal/RegisterModal';
import { screenSizes } from '../../../fixtures/ScreenSizes';

window.OpenPay.setId(config.openpayMerchantId);
window.OpenPay.setApiKey(config.openpayPublicKey);
window.OpenPay.setSandboxMode(!config.openpayIsProduction);

const DetailReservation = () => {

    const {userData} = useSelector(state => state.userLogged);
    const {reservation} = useSelector(state => state);
    const {chef} = useSelector(state => state.chefSelected);
    const dispatch = useDispatch();
    const {
        cardsLoading,
        cards, 
        handleCardDelete, 
        handleCardSelected, 
        cardSelected,
        handleNombre,
        handleTarjeta,
        handleMesExpiracion,
        handleYearExpiracion,
        handleCvc,
        validateForm,
        nombre,
        tarjeta,
        mesExp,
        yearExp,
        cvc,
        Process3DSecure,
        showRegisterModal,
        closeRegisterModal
    } = useGetUserCards();
    
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const [loading, setLoading] = useState(false);
    const [horario, setHorario] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [promoCode, setPromoCode] = useState('');
    const [detailReservationCompleted, setDetailReservationCompleted] = useState(false);
    const [kitchenCompleted, setKitchenCompleted] = useState(false);
    const [restrictionsCompleted, setRestrictionCompleted] = useState(false);
    const [contactInfoCompleted, setContactInfoCompleted] = useState(false);
    const [calle, setCalle] = useState('');
    const [numExt, setNumExt] = useState('');
    const [numInt, setNumInt] = useState('');
    const [colonia, setColonia] = useState('');
    const [cp, setCP] = useState('');
    const [delegacion, setDelegacion] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [estadoForm, setEstadoForm] = useState('');
    const [referencias, setReferencias] = useState('');
    const [metodoPagoCompleted] = useState('');
    const [horarioFullDay, setHorarioFullDay] = useState([]);

    useEffect(()=>{
        checkReservationData();
        if(reservation.active){
            configureHooursFullDay();
        }
    },[]);

    const configureHooursFullDay = ()=> {
        let rangeTime = [];
        let horas = [];
        for (let i = parseInt('08:00'); i <= parseInt('22:00'); i++) {
            if (rangeTime.length % 2 == 0) {
                rangeTime.push(moment(i.toString(), "k").format("HH:mm"));
            }
            if (i === parseInt('22:00')) {
                break;
            }
            rangeTime.push(moment(i.toString(), "k").add(30, "minutes").format("HH:mm"));
        }
        rangeTime.forEach((time)=>{
            horas.push(time);
        });

        setHorarioFullDay(horas);
    }

    const checkReservationData = ()=>{
        if(reservation.estado){
            GetMunicipiosByEstado(reservation.estado);
        }
        if(reservation.fecha){
            let nameDay = moment(reservation.fecha, 'DD-MM-YYYY').format('dddd');
            nameDay = `${nameDay[0].toUpperCase()}${nameDay.substr(1)}`
            getRangeTime(nameDay);
        }
    };

    const getRangeTime = (nameDay)=> {
        const [horarioDia] = chef.horarios.filter(horarioChef => horarioChef.dia === nameDay);
        const horario = GetTimeRange(horarioDia.desde, horarioDia.hasta);
        setHorario(horario);
    }

    const GetMunicipiosByEstado = (estadoSelected)=> {
        const [area_servicio] = chef.areas_servicio.filter(area_servicio => area_servicio.estado === estadoSelected);
        const municipios  = area_servicio.municipios.map(municipio => municipio);
        setMunicipios(municipios);
    }

    const setEstadoSelected = (e)=> {
        const estadoSelected = e.target.value;
        if(estadoSelected !== ''){
            dispatch(setEstado(estadoSelected));
            GetMunicipiosByEstado(estadoSelected);
        }else{
            setMunicipios([]);
            dispatch(setMunicipio(''));
            dispatch(setEstado(''));
        }
    }

    const setMunicipioSelected = (e)=> {
        const municipioSelected = e.target.value;
        dispatch(setMunicipio(municipioSelected));
    }

    const setFechaSelected = (e)=> {
        if(!reservation.active){
            const nameDay = e.weekDay.name;
            const date = moment.unix(e.unix).format('DD-MM-YYYY');
            dispatch(setFecha(date));
            getRangeTime(nameDay);
        }else{
            const fechas_selected = e.map((d)=> d.format());
            dispatch(setFechas(fechas_selected));
        }
    }

    const setHoraSelected = (e)=> {
        const horaSelected = e.target.value;
        dispatch(setHora(horaSelected));
    }

    const setHoraInicialSelected = (e)=> {
        const horaSelected = e.target.value;
        dispatch(setHoraInicial(horaSelected));
    }

    const setHoraFinalSelected = (e)=> {
        const horaSelected = e.target.value;
        dispatch(setHoraFinal(horaSelected));
    }

    const ValidatePromoCode = async()=> {
        if(!userData.id){
            swal({
                text: 'Debes iniciar sesión para validar el código de promoción.',
                icon: 'warning'
            });
            return;
        }
        if(!promoCode){
            swal({
                text: 'Debes escribir un código.',
                icon: 'warning'
            });
            return;
        }

        const data = {
            promo_code: promoCode,
            usuario_id: userData.id,
            today: moment().format('YYYY-MM-DD') 
        };

        setLoading(true);
        const result = await callApi('validate_promo_code', 'post', data);
        if(result.status === 200){
            dispatch(setCodigoPromocion(result.promo_code));
            //setPromoCodeApplied(result.promo_code);
            CalculateNewTotal(result.promo_code);
            swal({
                text: result.message,
                icon: 'success'
            });
        }else{
            setLoading(false);
            swal({
                text: result.message,
                icon: 'warning'
            });
        }
    }

    const CalculateNewTotal = async(promo_code)=> {
        const costo_total = reservation.costo_total;
        let descuento;
        if(promo_code.cantidad_descuento){
            descuento = promo_code.cantidad_descuento;
        }else{
            descuento = parseFloat(((promo_code.porcentaje_descuento*costo_total)/100).toFixed(2));
        }
        dispatch(setCostoTotalDescuento(descuento));
        setLoading(false);
    }

    const DeletePromoCode = async()=> {
        dispatch(setCodigoPromocion(''));
        dispatch(setCostoTotalDescuento(0));
    }

    const validateDetailReservation = ()=>{
        if(!reservation.estado){
            swal({
                text: 'Selecciona un estado.',
                icon: 'warning'
            });
            return;
        }
        if(!reservation.municipio){
            swal({
                text: 'Selecciona un municipio.',
                icon: 'warning'
            });
            return;
        }
        if(reservation.active){
            if(reservation.fechas.length === 0){
                swal({
                    text: 'Selecciona al menos una fecha.',
                    icon: 'warning'
                });
                return;
            }
        }else{
            if(!reservation.fecha){
                swal({
                    text: 'Selecciona un fecha.',
                    icon: 'warning'
                });
                return;
            }
        }
        if(reservation.active){
            if(!reservation.hora_inicial){
                swal({
                    text: 'Selecciona un hora inicial.',
                    icon: 'warning'
                });
                return;
            }
            if(!reservation.hora_final){
                swal({
                    text: 'Selecciona un hora final.',
                    icon: 'warning'
                });
                return;
            }
            if(reservation.hora_inicial === reservation.hora_final){
                swal({
                    text: 'La hora inicial y la hora final no pueden ser iguales.',
                    icon: 'warning'
                });
                return;
            }
        }else{
            if(!reservation.hora){
                swal({
                    text: 'Selecciona un hora.',
                    icon: 'warning'
                });
                return;
            }
        }
        setDetailReservationCompleted(true);
    }

    const setFeatureKitchen = (e)=> {
        e.preventDefault();
        const feature = e.target.children[0].children[0].children[1].innerText;
        let kitchenFeatures = [...reservation.cocina];
        if(!kitchenFeatures.includes(feature)){
            kitchenFeatures.push(feature);
            dispatch(setCocina(kitchenFeatures));
        }else{
            kitchenFeatures = kitchenFeatures.filter(kf => kf !== feature);
            dispatch(setCocina(kitchenFeatures));
        }
    }

    const setFuegosSelected = (e)=> {
        const fuegos = e.target.children[1].children[1].innerText;
        dispatch(setFuegos(fuegos));
    }

    const validateKitchen = ()=> {
        if(reservation.cocina.length === 0){
            swal({
                text: 'Debes elegir las características de tu cocina.',
                icon: 'warning'
            });
            return;
        }
        if(reservation.cocina.indexOf('Gas') === -1 && reservation.cocina.indexOf('Eléctrica') === -1){
            swal({
                text: 'Debes elegir al menos un tipo de cocina, Gas o Eléctrica.',
                icon: 'warning'
            });
            return;
        }
        if(!reservation.fuegos){
            swal({
                text: 'Selecciona la cantidad de fuegos.',
                icon: 'warning'
            });
            return;
        }
        setKitchenCompleted(true);
    }

    const setRestrictionSelected = (e)=> {
        let restrictions = [...reservation.restricciones_alimentarias];
        const restriction = e.target.children[0].children[0].children[0].innerText;
        if(!restrictions.includes(restriction)){
            restrictions.push(restriction);
            dispatch(setRestriccionesAlimentarias(restrictions));
        }else{
            restrictions = restrictions.filter(r => r !== restriction);
            dispatch(setRestriccionesAlimentarias(restrictions));
        }
    }

    const handleRestrictionText = (e)=> {
        const restrictionText = e.target.value;
        dispatch(setOtrasAlergiasText(restrictionText));
    }

    const handleCalle = (e)=> {
        const calle = e.target.value;
        setCalle(calle);
    }
    
    const handleNumExt = (e)=> {
        const numExt = e.target.value;
        setNumExt(numExt);
    }

    const handleNumInt = (e)=> {
        const numInt = e.target.value;
        setNumInt(numInt);
    }

    const handleColonia = (e)=> {
        const colonia = e.target.value;
        setColonia(colonia);
    }

    const handleCP = (e)=> {
        const cp = e.target.value;
        setCP(cp);
    }

    const handleDelegacion = (e)=> {
        const delegacion = e.target.value;
        setDelegacion(delegacion);
    }

    const handleCiudad = (e)=> {
        const ciudad = e.target.value;
        setCiudad(ciudad);
    }

    const handleEstado = (e)=> {
        const estado = e.target.value;
        setEstadoForm(estado);
    }

    const handleReferencias = (e)=> {
        const ref = e.target.value;
        setReferencias(ref);
    }
    
    const validateAddres = ()=> {
        if(!calle){
            swal({
                text: 'Escribe tu calle.',
                icon: 'warning'
            });
            return;
        }
        if(!numExt){
            swal({
                text: 'Escribe tu numero exterior.',
                icon: 'warning'
            });
            return;
        }
        if(!colonia){
            swal({
                text: 'Escribe tu colonia.',
                icon: 'warning'
            });
            return;
        }
        if(!cp){
            swal({
                text: 'Escribe tu código postal.',
                icon: 'warning'
            });
            return;
        }
        if(!delegacion){
            swal({
                text: 'Escribe tu delegación o municipio.',
                icon: 'warning'
            });
            return;
        }
        if(!ciudad){
            swal({
                text: 'Escribe tu ciudad.',
                icon: 'warning'
            });
            return;
        }
        if(!estadoForm){
            swal({
                text: 'Escribe tu estado.',
                icon: 'warning'
            });
            return;
        }
        if(!referencias){
            swal({
                text: 'Escribe las referencias o entre calles.',
                icon: 'warning'
            });
            return;
        }
        const descripcion = `${calle} ${numExt},${numInt ? ' int '+numInt+',' : ''} ${colonia}, ${cp} ${delegacion}, ${estadoForm}`;
        const formatted_address = `${calle} ${numExt},${numInt ? ' int '+numInt+',' : ''} ${colonia}, ${cp} ${delegacion}, ${estadoForm}`;
        const addressObj = {
            descripcion, 
            formatted_address,
            referencias
        }
        dispatch(setDireccion(addressObj));
        setContactInfoCompleted(true);
    }
    

    return (
        <>
        
            <DetailReservationWrapper className='detail-reservation-wrapper'>
                
                {
                    Object.keys(reservation.menu).length > 0 &&
                    <StepCompleted text='Menú' />
                }

                {
                    detailReservationCompleted ? 
                        <StepCompleted text='Detalles de tu reservación' />
                    :
                        <DetailReservationHolder>
                            <StyledSubtitle text='Detalles de tu reservación' marginBottom='12px' />
                            <StyledText 
                                color={Colors.gray} 
                                text='Selecciona la ciudad, hora y fecha y número se anfitriones para tu reservación. En caso de tener algún cupón de descuento, puedes incluirlo también.' 
                                marginBottom='23px'    
                            />
                            <InputContainer className='input-container d-flex align-items-center'>
                                <StyledText marginRight='20px' text='Estado' />
                                <Select value={reservation.estado} onChange={setEstadoSelected}>
                                    <option value=''>Selecciona...</option>
                                    {
                                        chef.areas_servicio.map((area)=>(
                                            <option value={area.estado}>{area.estado}</option>
                                        ))
                                    }
                                </Select>
                            </InputContainer>
                            <InputContainer className='input-container d-flex align-items-center'>
                                <StyledText marginRight='20px' text='Municipio' />
                                <Select disabled={municipios.length === 0} value={reservation.municipio} onChange={setMunicipioSelected}>
                                    <option value=''>Selecciona...</option>
                                    {
                                        municipios.map((municipio)=>(
                                            <option value={municipio}>{municipio}</option>
                                        ))
                                    }
                                </Select>
                            </InputContainer>
                            <DateAndHourWrapper reservationActive={reservation.active} className='date-hour-wrapper'>
                                <InputContainer width={reservation.active ? '100%' : '48%'} className='input-container d-flex align-items-center'>
                                    <StyledText marginRight='20px' text={reservation.active ? 'Fechas' : 'Fecha'} />
                                    <Calendar multiple={reservation.active} value={reservation.fecha} onChange={setFechaSelected} />
                                </InputContainer>
                                {
                                    reservation.active ?
                                        <>
                                            <InputContainer width='100%' className='input-container d-flex align-items-center'>
                                                <StyledText marginRight='20px' text='Hora inicial' />
                                                <Select disabled={reservation.active ?  !reservation.fechas  : horario.length === 0} value={reservation.hora_inicial} onChange={setHoraInicialSelected}>
                                                    <option value=''>Selecciona...</option>
                                                    {
                                                        horarioFullDay.map((hora)=>(
                                                            <option value={hora}>{hora}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </InputContainer>
                                            <InputContainer width='100%' className='input-container d-flex align-items-center'>
                                                <StyledText marginRight='20px' text='Hora final' />
                                                <Select disabled={reservation.active ?  !reservation.fechas  : horario.length === 0} value={reservation.hora_final} onChange={setHoraFinalSelected}>
                                                    <option value=''>Selecciona...</option>
                                                    {
                                                        horarioFullDay.map((hora)=>(
                                                            <option value={hora}>{hora}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </InputContainer>
                                        </>
                                    :
                                        <InputContainer width='48%' className='input-container d-flex align-items-center'>
                                            <StyledText marginRight='20px' text='Hora' />
                                            <Select disabled={horario.length === 0} value={reservation.hora} onChange={setHoraSelected}>
                                                <option value=''>Selecciona...</option>
                                                {
                                                    horario.map((hora)=>(
                                                        <option value={hora}>{hora}</option>
                                                    ))
                                                }
                                            </Select>
                                        </InputContainer>
                                }
                            </DateAndHourWrapper>
                            <InputContainer className='input-container d-flex align-items-center'>
                                <StyledText marginRight='20px' text='Elegir Comensales' />
                                <Counter text='' addPeople={()=>{dispatch(addComensales())}} removePeople={()=>{dispatch(removeComensales())}} people={reservation.comensales} />
                            </InputContainer>
                            <PromoCodeWrapper>
                                <StyledSubtitle text='Código de promoción' marginBottom='16px' />
                                <PromoCodeHolder className='d-flex align-items-center justify-content-between'>
                                    <InputPromoCode value={promoCode} onChange={(e)=>{setPromoCode(e.target.value.toLocaleUpperCase())}} />
                                    <BtnPromoCode text={reservation.codigo_promocion ? 'Descartar' : 'Aplicar'} onClick={reservation.codigo_promocion ? DeletePromoCode : ValidatePromoCode} />
                                </PromoCodeHolder>
                                {
                                    reservation.codigo_promocion &&
                                    <div>
                                        <StyledText 
                                            marginTop='16px !important'
                                            text={reservation.codigo_promocion.porcentaje_descuento ? 
                                                `Porcentaje de descuento ${reservation.codigo_promocion.porcentaje_descuento}%` :
                                                `Cantidad de descuento ${CurrencyFormat(reservation.codigo_promocion.cantidad_descuento)}`
                                            }
                                            color={Colors.succes}
                                            marginRight='6px'
                                        />
                                        <StyledText 
                                            text={reservation.codigo_promocion.terminos_condiciones}
                                            color={Colors.gray}
                                        />
                                        <StyledText 
                                            text={reservation.codigo_promocion.vigencia}
                                            color={Colors.gray}
                                        />
                                    </div>
                                    
                                }
                                <div style={{marginTop: '42px'}}>
                                    <StyledSubtitle text='Detalle del servicio' fontWeight='600' marginBottom='24px' />
                                    <ResumeOrderWrapper>
                                        <ResumeOrderHolder>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <StyledText text='Tarifa por servicio' color={Colors.gray} />
                                                {
                                                    reservation.active ?
                                                        <StyledText text={CurrencyFormat((reservation.comensales*chef.costo_dia_chef)*(reservation.fechas ? reservation.fechas.length : 0))} />
                                                    :
                                                        <StyledText text={CurrencyFormat(reservation.comensales*reservation.menu.precio_chef)} />
                                                }
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <StyledText text='Comisión Kaichef' color={Colors.gray} />
                                                {
                                                    reservation.active ?
                                                        <StyledText text={CurrencyFormat((reservation.comensales*chef.costo_dia)*(reservation.fechas ? reservation.fechas.length : 0)-(reservation.comensales*chef.costo_dia_chef)*(reservation.fechas ? reservation.fechas.length : 0))} />
                                                    :
                                                        <StyledText text={CurrencyFormat((reservation.menu.precio*reservation.comensales)-(reservation.comensales*reservation.menu.precio_chef))} />
                                                }
                                            </div>
                                            {
                                                reservation.codigo_promocion &&
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <StyledText text='Código de Promoción' color={Colors.gray} />
                                                        <StyledText text={CurrencyFormat(reservation.costo_total_descuento)} />
                                                    </div>
                                            }
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <StyledText text='Precio total' fontWeight='bold' />
                                                {
                                                    reservation.active ?
                                                        <StyledText text={CurrencyFormat(reservation.costo_total - reservation.costo_total_descuento)} fontWeight='bold' />
                                                    :
                                                        <StyledText text={CurrencyFormat(reservation.costo_total-reservation.costo_total_descuento)} fontWeight='bold' />
                                                }
                                            </div>
                                        </ResumeOrderHolder>
                                        <MainButton style={{display: 'flex', justifyContent: 'flex-end'}} marginTop='16px' text='Continuar' onClick={validateDetailReservation} />
                                    </ResumeOrderWrapper>
                                    
                                </div>
                            </PromoCodeWrapper>
                        </DetailReservationHolder>
                }

                {
                    detailReservationCompleted ?
                        !kitchenCompleted ?
                            <DetailReservationHolder>
                                <StyledSubtitle 
                                    text='Características de la cocina' marginBottom='12px' 
                                />
                                <StyledText 
                                    text='El saber cómo está conformada tu cocina ayudará al Chef a conocer las herramientas que requiere para la elaboración de los platillos.'    
                                    color={Colors.gray}
                                    marginTop='42px'
                                    marginBottom='32px'
                                />
                                <div style={{marginBottom: '46px'}}>    
                                    <KitchenItem onClick={setFeatureKitchen} item='Mi estufa' feature='Gas' checked={reservation.cocina.includes('Gas')} />
                                    <KitchenItem onClick={setFeatureKitchen} item='Mi estufa' feature='Eléctrica' checked={reservation.cocina.includes('Eléctrica')} />
                                    <KitchenItem onClick={setFeatureKitchen} item='Mi estufa' feature='Tenemos Horno' checked={reservation.cocina.includes('Tenemos Horno')} />
                                    <KitchenItem onClick={setFeatureKitchen} item='Accesorios' feature='Microondas' checked={reservation.cocina.includes('Microondas')} />
                                </div>
                                <StyledSubtitle text='Nuestra cocina tiene' fontWeight='600' marginBottom='24px' />
                                <StyledText 
                                    text='Saber las características de tu cocina ayudará al Chef a estar preparado para realizar el servicio de forma más eficiente.' 
                                    color={Colors.gray}
                                    marginBottom='24px'
                                />
                                <div style={{marginBottom:'60px'}} className='d-flex align-items-center justify-content-between'>
                                    
                                    <FuegosOptionWrapper onClick={setFuegosSelected}>
                                        <ImgFuegos src={Img2fuegos} />
                                        <DescriptionWrapper className='d-flex align-items-center'>
                                            <IconFireHolder style={{marginRight: '6px'}}>
                                                {
                                                    reservation.fuegos === '2 fuegos' ?
                                                        <IoCheckmarkCircle style={{pointerEvents: 'none'}} color={Colors.mainColor} size={16} />
                                                    :
                                                        <IoRadioButtonOff style={{pointerEvents: 'none'}} color={Colors.gray} size={16} />
                                                }
                                            </IconFireHolder>
                                            <StyledText text='2 fuegos' color={Colors.gray} />
                                        </DescriptionWrapper>
                                    </FuegosOptionWrapper>

                                    <FuegosOptionWrapper onClick={setFuegosSelected}>
                                        <ImgFuegos src={Img3fuegos} />
                                        <DescriptionWrapper className='d-flex align-items-center'>
                                            <IconFireHolder style={{marginRight: '6px'}}>
                                                {
                                                    reservation.fuegos === '3 fuegos' ?
                                                        <IoCheckmarkCircle style={{pointerEvents: 'none'}} color={Colors.mainColor} size={16} />
                                                    :
                                                        <IoRadioButtonOff style={{pointerEvents: 'none'}} color={Colors.gray} size={16} />
                                                }
                                            </IconFireHolder>
                                            <StyledText text='3 fuegos' color={Colors.gray} />
                                        </DescriptionWrapper>
                                    </FuegosOptionWrapper>

                                    <FuegosOptionWrapper onClick={setFuegosSelected}>
                                        <ImgFuegos src={Img4fuegos} />
                                        <DescriptionWrapper className='d-flex align-items-center'>
                                            <IconFireHolder style={{marginRight: '6px'}}>
                                                {
                                                    reservation.fuegos === '4 fuegos' ?
                                                        <IoCheckmarkCircle style={{pointerEvents: 'none'}} color={Colors.mainColor} size={16} />
                                                    :
                                                        <IoRadioButtonOff style={{pointerEvents: 'none'}} color={Colors.gray} size={16} />
                                                }
                                            </IconFireHolder>
                                            <StyledText text='4 fuegos' color={Colors.gray} />
                                        </DescriptionWrapper>
                                    </FuegosOptionWrapper>

                                    <FuegosOptionWrapper onClick={setFuegosSelected}>
                                        <ImgFuegos src={Img5fuegos} />
                                        <DescriptionWrapper className='d-flex align-items-center'>
                                            <IconFireHolder style={{marginRight: '6px'}}>
                                                {
                                                    reservation.fuegos === '5 fuegos' ?
                                                        <IoCheckmarkCircle style={{pointerEvents: 'none'}} color={Colors.mainColor} size={16} />
                                                    :
                                                        <IoRadioButtonOff style={{pointerEvents: 'none'}} color={Colors.gray} size={16} />
                                                }
                                            </IconFireHolder>
                                            <StyledText text='5 fuegos' color={Colors.gray} />
                                        </DescriptionWrapper>
                                    </FuegosOptionWrapper>

                                </div>
                                <div className='d-flex justify-content-end'>
                                    <MainButton text='Continuar' onClick={validateKitchen} />
                                </div>
                            </DetailReservationHolder>
                        :
                            <StepCompleted text='Características de la cocina' />
                    :
                        null
                }

                {
                    detailReservationCompleted && kitchenCompleted ?
                        !restrictionsCompleted ?
                            <DetailReservationHolder>
                                <StyledSubtitle 
                                    text='Restricciones alimenticias' marginBottom='12px' 
                                />
                                <StyledText 
                                    text='Tu estilo de vida es lo más importante. Si tienes alguna restricción alimenticia, hazlo saber para que el chef lo tenga contemplado al preparar los platillos.'    
                                    color={Colors.gray}
                                    marginTop='42px'
                                    marginBottom='32px'
                                />
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div style={{flex: 1, marginRight: '8px'}}>
                                        <RestrictionItem 
                                            onClick={setRestrictionSelected} 
                                            feature='Vegetariano'
                                            checked={reservation.restricciones_alimentarias.includes('Vegetariano')}
                                        />
                                    </div>
                                    <div style={{flex: 1}}>
                                        <RestrictionItem 
                                            onClick={setRestrictionSelected} 
                                            feature='Frutos secos'
                                            checked={reservation.restricciones_alimentarias.includes('Frutos secos')}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div style={{flex: 1, marginRight: '8px'}}>
                                        <RestrictionItem 
                                            onClick={setRestrictionSelected} 
                                            feature='Mariscos'
                                            checked={reservation.restricciones_alimentarias.includes('Mariscos')}
                                        />
                                    </div>
                                    <div style={{flex: 1}}>
                                        <RestrictionItem 
                                            onClick={setRestrictionSelected} 
                                            feature='Gluten'
                                            checked={reservation.restricciones_alimentarias.includes('Gluten')}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div style={{flex: 1, marginRight: '8px'}}>
                                        <RestrictionItem 
                                            onClick={setRestrictionSelected} 
                                            feature='Lácteos'
                                            checked={reservation.restricciones_alimentarias.includes('Lácteos')}
                                        />
                                    </div>
                                    <div style={{flex: 1}}>
                                        <RestrictionItem 
                                            onClick={setRestrictionSelected} 
                                            feature='Vegano'
                                            checked={reservation.restricciones_alimentarias.includes('Vegano')}
                                        />
                                    </div>
                                </div>
                                <StyledSubtitle 
                                    text='Otras alergias' marginBottom='12px' 
                                />
                                <StyledText 
                                    text='En caso de no estar dentro de restricciones alimenticias, especifica los alimentos que no entran en tu dieta o te provocan alergia.'    
                                    color={Colors.gray}
                                    marginTop='42px'
                                    marginBottom='32px'
                                />
                                <TextArea value={reservation.otras_alergias_text} placeholder='Escribe tus restricciones alimentarias' onChange={handleRestrictionText} />
                                <div className='d-flex justify-content-end'>
                                    <MainButton text='Continuar' onClick={()=>{setRestrictionCompleted(true)}} />
                                </div>
                            </DetailReservationHolder>
                        :
                            <StepCompleted text='Restricciones alimenticias' />
                    :
                        null
                }

                {
                    detailReservationCompleted && kitchenCompleted && restrictionsCompleted ?
                        !contactInfoCompleted ?
                            <DetailReservationHolder>
                                <StyledSubtitle 
                                    text='¿Donde será la reservación?' marginBottom='12px' 
                                />
                                <StyledText 
                                    text='Saber la dirección hará que nuestro Chef aliado llegue sin ningún inconveniente. Recomendamos anexar referencias en caso de que la ubicación se encuentre en alguna zona residencial o se requiera algun acceso especifco.'    
                                    color={Colors.gray}
                                    marginTop='42px'
                                    marginBottom='32px'
                                />
                                <InputAddress value={calle} label='Calle' placeholder='Calle' onChange={handleCalle} />
                                <InputAddress value={numExt} label='Num. Exterior' placeholder='Num. Exterior' onChange={handleNumExt} />
                                <InputAddress value={numInt} label='Num. Interior' placeholder='Num. Interior' onChange={handleNumInt} />
                                <InputAddress value={colonia} label='Colonia' placeholder='Colonia' onChange={handleColonia} />
                                <InputAddress value={cp} label='Código Postal' placeholder='Código Postal' onChange={handleCP} />
                                <InputAddress value={delegacion} label='Del./Mun.' placeholder='Del./Mun.' onChange={handleDelegacion} />
                                <InputAddress value={ciudad} label='Ciudad' placeholder='Ciudad' onChange={handleCiudad} />
                                <InputAddress value={estadoForm} label='Estado' placeholder='Estado' onChange={handleEstado} />
                                <TextArea placeholder='Referencias y/o entre calles' onChange={handleReferencias} />
                                <div className='d-flex justify-content-end'>
                                    <MainButton text='Continuar' onClick={validateAddres} />
                                </div>
                            </DetailReservationHolder>
                        :
                            <StepCompleted text='Información de contacto' />
                    :
                        null

                }

                {
                    detailReservationCompleted && kitchenCompleted && restrictionsCompleted && contactInfoCompleted ?
                        !metodoPagoCompleted ?
                            <DetailReservationHolder>
                                <StyledSubtitle 
                                    text='Método de pago' marginBottom='12px' 
                                />
                                <StyledText 
                                    text='Aceptamos la mayoria de tarjetas de crédito y débito en México'    
                                    color={Colors.gray}
                                    marginTop='42px'
                                    marginBottom='32px'
                                />
                                {/* <ItemCreditCard label='Tarjeta de débito' marginBottom='40px' /> */}
                                {
                                    cards.length > 0 &&
                                        <div>
                                            <StyledSubtitle text='Tarjetas guardadas' marginBottom='32px' />
                                            <div>
                                                {
                                                    cards.map((card)=>{
                                                        return(
                                                            <CardItem 
                                                                card_name={card.bank_name} 
                                                                card_number={card.card_number}
                                                                onClickTrash={()=>{handleCardDelete(card)}}
                                                                onClick={()=>{handleCardSelected(card)}}
                                                                selected={cardSelected === card.id}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                cards.length === 3 &&    
                                                <StyledText 
                                                    text='Por seguridad solo puedes guardar 3 tarjetas, si necesitas agregar otra tarjeta, debes eliminar una.'
                                                    marginBottom='16px'
                                                />
                                            }
                                            {
                                                cardSelected &&
                                                    <div className='d-flex justify-content-end'>
                                                        <MainButton text='Pagar' onClick={Process3DSecure} />
                                                    </div>
                                            }
                                        </div>
                                }
                                {
                                    cards.length < 3 &&
                                    <div style={{marginTop: '32px'}}>
                                        <StyledSubtitle 
                                            text='Agregar nueva tarjeta' marginBottom='12px' 
                                            marginBottom={'32px'}
                                            fontWeight='bold'
                                        />
                                        <StyledSubtitle 
                                            text='Información del titular' marginBottom='12px' 
                                        />
                                        <StyledText 
                                            text='Información requerida para realizar el pago con tarjeta de crédito o débito.'    
                                            color={Colors.gray}
                                            marginTop='42px'
                                            marginBottom='32px'
                                        />
                                        <form id="processCard" name="processCard">
                                            <InputAddress 
                                                dataOpenpayCard="holder_name" 
                                                label='Nombre del titular' 
                                                onChange={handleNombre}
                                                value={nombre}
                                            />
                                            <InputAddress 
                                                maxLength={16} 
                                                label='Número de tarjeta'
                                                mask
                                                placeholder='1234 1234 1234 1234'
                                                onChange={handleTarjeta}
                                                value={tarjeta}
                                            />
                                            <input type='hidden' value={tarjeta} data-openpay-card="card_number" />
                                            <InputAddress 
                                                dataOpenpayCard="expiration_month" 
                                                label='Mes Exp.' 
                                                placeholder='MM'
                                                maxLength={2}
                                                onChange={handleMesExpiracion}
                                                value={mesExp}
                                            />
                                            <InputAddress 
                                                dataOpenpayCard="expiration_year" 
                                                label='Año Exp.'
                                                placeholder='AA'
                                                maxLength={2}
                                                onChange={handleYearExpiracion}
                                                value={yearExp}
                                            />
                                            <InputAddress 
                                                dataOpenpayCard="cvv2" 
                                                label='CVC'
                                                maxLength={4}
                                                onChange={handleCvc}
                                                value={cvc}
                                            />
                                        </form>
                                        <div className='d-flex justify-content-end'>
                                            <MainButton text='Agregar Tarjeta' onClick={validateForm} />
                                        </div>
                                    </div>
                                }
                            </DetailReservationHolder>
                        :
                            <StepCompleted text='Método de pago' />
                    :
                        null
                    
                }

            </DetailReservationWrapper>
            <Loading visible={loading || cardsLoading} />
            {
                showRegisterModal &&
                <RegisterModal closeRegisterModal={closeRegisterModal} />
            }
        </>
    )
}

const DetailReservationWrapper = styled.div`
`;

const DetailReservationHolder = styled.div`
    border: solid 1px ${Colors.grayBorder};
    border-radius: 35px;
    padding: 24px;
`;

export const InputContainer = styled.div`
    position: relative;
    border: solid 1px ${Colors.grayBorder};
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 36px;
    flex: ${props => props.width ? null : 1};
    width: ${props => props.width ? props.width : null};

    @media(max-width: ${screenSizes.mediumMax}){
        width: auto;
    }
`;

export const Select = styled.select`
    border: 0px;
    flex: 1;

    :focus {
        outline: none;
    }
`;

const DateAndHourWrapper = styled.div`
    display: ${props => props.reservationActive ? 'block' : 'flex'};
    align-items: center;
    justify-content: space-between;

    @media(max-width: ${screenSizes.mediumMax}){
        display: block;
    }
`;

const PromoCodeWrapper = styled.div``;

const PromoCodeHolder = styled.div``;

const InputPromoCode = styled.input`
    height: 50px;
    flex: 1;
    margin-right: 12px;
    text-align: center;
    text-transform: uppercase;

    :focus {
        outline: none;
    }
`;

const ResumeOrderWrapper = styled.div`
    /* display: flex;
    align-items: flex-end;
    justify-content: space-between; */

    @media(max-width: ${screenSizes.mediumMax}){
        display: block;
    }
`;

const ResumeOrderHolder = styled.div`
    flex:1;

    @media(max-width: ${screenSizes.mediumMax}){
        margin-right: 0px;
    }
`;

const FuegosOptionWrapper = styled.div`
    text-align: center;
    cursor: pointer;
`;

const ImgFuegos = styled.img`
    width: 80px;
    height: 80px;
    pointer-events: none;
`;

const DescriptionWrapper = styled.div`
    pointer-events: none;
`;

const IconFireHolder = styled.div`
    pointer-events: none;
`;

const TextArea = styled.textarea`
    border: solid 1px ${Colors.gray};
    resize: none;
    width: 100%;
    height: 150px;
    padding-left: 16px;
    padding-top: 16px;
    margin-bottom: 32px;

    :placeholder {
        font-style: italic;
    }

    :focus {
        outline: none;
    }
`;

export default DetailReservation
