import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { callApi } from "../../../fixtures/api";


const useMyReservations = () => {
    
    const {userData} = useSelector(state => state.userLogged);
    const [loading, setLoading] = useState(true);
    const [reservasActivas, setReservasActivas] = useState([]);
    const [reservasCanceladas, setReservasCanceladas] = useState([]);
    const [reservasFinalizadas, setReservasFinalizadas] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [reservaSelected, setReservaSelected] = useState(undefined);

    useEffect(()=> {
        getReservs();
    },[]);

    const getReservs = async()=> {
        const data = {
            usuario_id: userData.id
        };
        const resultReservs = await callApi('get_reservs', 'post', data);
        if(resultReservs.status === 200){
            const reservsFounded = resultReservs.reservaciones.filter(reserv => reserv.production === false);
            reservsFounded.forEach((reserv)=>{
                if(reserv.fechas){
                    reserv.date_unix = moment(`${reserv.fechas[0]} ${reserv.hora_inicial}`, 'DD-MM-YYYY HH:mm').unix();
                }else{
                    reserv.date_unix = moment(`${reserv.fecha} ${reserv.hora}`, 'DD-MM-YYYY HH:mm').unix();
                }
            });
            DistributeReservs(reservsFounded);
        }else{
            setLoading(false);
            swal({
                text: resultReservs.message,
                icon: 'warning'
            });   
        }
    };

    const DistributeReservs = (reservaciones)=> {
        const activas = reservaciones.filter(r => r.estatus === 'activa');
        const canceladas = reservaciones.filter(r => r.estatus === 'cancelada');
        const finalizadas = reservaciones.filter(r => r.estatus === 'finalizada');
        setReservasActivas(activas);
        setReservasCanceladas(canceladas);
        setReservasFinalizadas(finalizadas);
        setLoading(false);
    }

    const handleShowDetail = ()=> {
        setShowDetail(!showDetail);
    }

    const handleReservSelected = (reserv)=> {
        setReservaSelected(reserv);
    }
    
    return {
        loading,
        reservasActivas,
        reservasCanceladas,
        reservasFinalizadas,
        handleShowDetail,
        handleReservSelected,
        showDetail,
        reservaSelected
    }
}

export default useMyReservations
