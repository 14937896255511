import React from 'react'
import styled from 'styled-components'

const UserAvatar = ({userImage, onClickCapture, isClickeable}) => {
    return (
        <UserAvatarWrapper
            isClickeable={isClickeable}
            className='user-avatar-wrapper'
            onClickCapture={onClickCapture}
        >
            <UserAvatarImage
                src={userImage}
            />
        </UserAvatarWrapper>
    )
}

const UserAvatarWrapper = styled.div`
    width: 45px;
    height: 45px;
    border-radius: 25px;
    overflow: hidden;
    cursor: ${props => props.isClickeable && 'pointer'};
`;

const UserAvatarImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export default UserAvatar
