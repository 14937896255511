import React from 'react';
import styled from 'styled-components';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Colors from '../theme/Colors';

const LigthBox = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.90);
    z-index: 10001;
    display:flex;
    justify-content: center;
    align-items: center;
`;

export default function Loading({visible}) {
    if(visible){
        return (
            <LigthBox>
                <Loader
                    type='Oval'
                    color={Colors.mainColor}
                    height={100}
                    width={100}
                    visible={visible}
                />
            </LigthBox>
            
        )
    }
    return null
}
