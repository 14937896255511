import React from 'react'
import styled from 'styled-components'
import Colors from '../../../theme/Colors'
import StyledText from '../../atoms/typography/StyledText'

const MenuTimeItem = ({nombrePlatillo, descripcion, opciones, index}) => {
    
    const timesFood = ['Primer tiempo', 'Segundo tiempo', 'Tercer tiempo', 'Cuarto tiempo', 'Quinto tiempo', 'Sexto tiempo'];

    return (
        <MenuTimeItemWrapper className='menu-time-item-wrapper'>
            <StyledText text={timesFood[index]} color={Colors.gray} />
            <StyledText text={nombrePlatillo} />
            <StyledText text={descripcion} color={Colors.black} />
            {
                opciones &&
                    <ul>
                        {
                            opciones.map((opcion)=>{
                                return(
                                    <li>
                                        <StyledText 
                                            text={opcion.nombre_platillo}
                                        />
                                        <StyledText 
                                            text={opcion.descripcion}
                                        />
                                    </li>
                                )
                            })
                        }
                    </ul>
            }
        </MenuTimeItemWrapper>
    )
}

const MenuTimeItemWrapper = styled.div`
    margin-bottom: 25px;
`;

export default MenuTimeItem
