import { useState } from 'react';
import swal from 'sweetalert';
import { callApi } from '../../../../fixtures/api';
import { ValidateEmail } from '../../../../fixtures/helpers';
import md5 from 'md5';

const useRecoveryPass = (closeRecoveryPassModal) => {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [codeSended, setCodeSended] = useState(false);
    const [code, setCode] = useState('');
    const [codeValidated, setCodeValidated] = useState(false);
    const [newPass, setNewPass] = useState('');

    const handleEmail = (e)=> {
        const email = e.target.value;
        setEmail(email);
    }

    const handleCode = (e)=> {
        const code = e.target.value;
        setCode(code);
    }

    const handleNewPass = (e)=> {
        const newPass = e.target.value;
        setNewPass(newPass);
    }

    const sendCodeRecoveryPass = async()=> {
        setLoading(true);
        const data = {
            email
        };
        const result = await callApi('recovery_pass', 'post', data);
        setLoading(false);
        if(result.status === 200){
            setCodeSended(true);
            swal({
                text: `Se ha enviado el código de confirmación al email ${email}.`,
                icon: 'success'
            });
        }else{
            swal({
                text: result.message,
                icon: 'warning'
            });
        }
    }

    const validateForm = ()=> {
        if(!email){
            swal({
                text: 'Escribe tu email.',
                icon: 'warning'
            });
            return;
        }
        if(!ValidateEmail(email)){
            swal({
                text: 'Escribe un email válido.',
                icon: 'warning'
            });
            return;
        }
        sendCodeRecoveryPass();
    }

    const confirmCode = async()=> {
        setLoading(true);
        const data = {
            codigo: code,
            email,
        };
        const result = await callApi('confirm_code_recovery_pass', 'post', data);
        setLoading(false);
        if(result.status === 200){
            setCodeValidated(true);
        }else{
            swal({
                text: result.message,
                icon: 'warning'
            });   
        }
    }

    const validateCode = ()=> {
        if(!code){
            swal({
                text: 'Escribe el código que recibiste.',
                icon: 'warning'
            });
            return;
        }
        confirmCode();
    }

    const changePass = async()=> {
        setLoading(true);
        const passMd5 = md5(newPass);
        const data = {
            password: passMd5,
            email: email
        };
        const result = await callApi('change_pass', 'post', data);
        setLoading(false);
        if(result.status === 200){
            swal({
                text: `La contraseña se ha actualizado, ahora puedes iniciar sesión.`,
                icon: 'success',
            });
            closeRecoveryPassModal();
        }else{
            swal({
                text: result.message,
                icon: 'warning',
            });
        }
    }

    const validateNewPass = ()=> {
        if(!newPass){
            swal({
                text: 'Escribe tu nueva contraseña.',
                icon: 'warning'
            });
            return;
        }
        changePass();
    }
    
    return {
        loading,
        handleEmail,
        handleCode,
        validateForm,
        validateCode,
        handleNewPass,
        validateNewPass,
        email,
        codeSended,
        code,
        codeValidated,
        newPass,
    }
}

export default useRecoveryPass
