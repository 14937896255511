import React from 'react';
import styled from 'styled-components';
import LogoKaiChef from '../../images/logo.png';

const Logo = ({width, height}) => {
    return (
        <LogoImg 
            src={LogoKaiChef}
            width={width}
            height={height}
        />
    )
}

const LogoImg = styled.img`
    object-fit: contain;
`;

export default Logo
