import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Colors from '../../../theme/Colors';
import FilterOrderChefs from '../../molecules/FilterOrderChefs';
import TabsChefs from '../../molecules/TabsChefs';

const TabsSelectChefs = () => {

    return (
        <TabsSelectChefsWrapper className='tabs-select-chefs mb-5'>
            <Container>
                <TabsSelectChefsHolder className='tabs-select-chefs-holder'>
                    <Row>
                        <Col lg='10'>
                            <TabsChefs />
                        </Col>
                        <Col lg='2'>
                            {/* <FilterOrderChefs /> */}
                        </Col>
                    </Row>
                </TabsSelectChefsHolder>
            </Container>
        </TabsSelectChefsWrapper>
    )
}

const TabsSelectChefsWrapper = styled.div`
`;

const TabsSelectChefsHolder = styled.div`
    border-bottom: 1px solid ${Colors.gray} ;
`;

export default TabsSelectChefs
