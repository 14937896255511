import moment from 'moment';
import React from 'react'
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components'
import ArticleDetail from '../../../screens/ArticleDetail.js/ArticleDetail';
import StyledTitle from '../../atoms/StyledTitle';
import StyledText from '../../atoms/typography/StyledText';

const ArticleItemRelated = ({post, onClick}) => {
    return (
        <ArticleItemRelatedWrapper onClick={onClick} key={post.id} className='article-item-related-wrapper'>
            <ArticleItemRelatedHolder className='article-item-related-holder'>
                <Row className='align-items-center'>
                    <Col sm='6'>
                        <PostImg className='img-fluid' src={post.imagen_principal} />
                    </Col>
                    <Col sm='6'>
                        <StyledTitle 
                            text={post.titulo}
                            fontSize='12px'
                        />
                        <StyledText 
                            text={moment.unix(post.created_at.seconds).format('DD MMMM YYYY')}
                            fontSize='10px'
                        />
                    </Col>
                </Row>
            </ArticleItemRelatedHolder>
        </ArticleItemRelatedWrapper>
    )
}

const ArticleItemRelatedWrapper = styled.div`
    margin-bottom: 24px;
    cursor: pointer;
`;

const ArticleItemRelatedHolder = styled.div`
    pointer-events: none;
`;

const PostImg = styled.img`
    border-radius: 8px;
    pointer-events: none;
`;

export default ArticleItemRelated
