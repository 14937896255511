import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ChefMenuItem from '../../molecules/ChefMenuItem';

const ListChefMenus = () => {

    const {chef} = useSelector(state => state.chefSelected);
    const {menus} = chef;

    return (
        <ListChefMenusWrapper className='list-chef-menus-wrapper'>
            {
                menus.map((menu)=>(
                    <>
                        <ChefMenuItem
                            menu={menu}
                        />
                    </>
                ))
            }
        </ListChefMenusWrapper>
    )
}

const ListChefMenusWrapper = styled.div`
`;

export default ListChefMenus
