import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IoCheckmarkCircle, IoLocation, IoPin, IoStar } from 'react-icons/io5';
import { Divider } from 'rsuite';
import styled from 'styled-components';
import StyledText from '../../../design/atoms/typography/StyledText';
import { CurrencyFormat } from '../../../fixtures/helpers';
import Colors from '../../../theme/Colors';
import { ChefInfoWrapper, ChefPhoto, ChefPhotoWrapper, IconReservStatusWrapper } from './MyReservationList';

const ReservationDetail = ({reservaSelected}) => {

    return (
        <ReservationDetailWrapper className='reservation-detail-wrapper'>
            <ChefWrapper className='d-flex align-items-center'>
                
                <IconReservStatusWrapper>
                    <IoCheckmarkCircle color={Colors.mainColor} size={20} />
                </IconReservStatusWrapper>
                
                <ChefPhotoWrapper>
                    <ChefPhoto src={reservaSelected.chef.avatar} />
                </ChefPhotoWrapper>

                <ChefInfoWrapper>
                    <StyledText text={reservaSelected.chef_nombre} fontSize='18px' fontWeight='bold' />
                    {
                        reservaSelected.chef.promedio_rating > 0 &&
                            <RatingWrapper className='d-flex align-items-center'>
                                <IoStar style={{marginRight: '6px'}} color={Colors.mainColor} size={16} />
                                <StyledText text={reservaSelected.chef.promedio_rating} marginRight='6px' />
                                <StyledText text={`(${reservaSelected.chef.evaluaciones.length} evaluaciones)`} color={Colors.gray} />
                            </RatingWrapper>
                    }
                    <LocationWrapper className='d-flex align-items-center'>
                        <IoLocation style={{marginRight: '6px'}} color={Colors.blue} size={16} />
                        <StyledText text={reservaSelected.chef.areas_servicio[0].estado} />
                    </LocationWrapper>
                </ChefInfoWrapper>
            </ChefWrapper>

            <ReservDetailInfo>
                
                <Row style={{marginBottom: '40px'}} className='align-items-center'>
                    <Col md='5'>
                        <div className=''>
                            <StyledText text='Cita' marginRight='16px' />
                            <StyledText 
                                text={`${reservaSelected.fecha || reservaSelected.fechas.join(',')},`} 
                                color={Colors.gray} 
                            />
                            <StyledText 
                                text={reservaSelected.hora || `${reservaSelected.hora_inicial} - ${reservaSelected.hora_final}`} 
                                color={Colors.gray} 
                            />
                        </div>
                    </Col>
                    <Col>
                        <StyledText text='No. de reserva' marginRight='16px' />
                        <StyledText 
                            text={reservaSelected.numero_reserva}
                            color={Colors.gray} 
                        />
                    </Col>
                </Row>
                
                <Row style={{marginBottom: '40px'}}>
                    <Col md='5'>
                        <div className='d-flex'>
                            <StyledText text='Comensales' marginRight='16px' />
                        </div>
                    </Col>
                    <Col>
                        <StyledText 
                            text={reservaSelected.comensales}
                            color={Colors.gray} 
                        />
                    </Col>
                </Row>
                
                <Row style={{marginBottom: '40px'}}>
                    <Col md='5'>
                        <div className='d-flex'>
                            <StyledText text='Domicilio' marginRight='16px' />
                        </div>
                    </Col>
                    <Col>
                        <div className='d-flex'>
                            <IoPin color={Colors.mainColor} size={24} />
                            <StyledText 
                                text={reservaSelected.direccion.formatted_address}
                                color={Colors.gray} 
                            />
                        </div>
                    </Col>
                </Row>

                <Row style={{marginBottom: '10px'}} className='align-items-center'>
                    <Col md='5'>
                        <StyledText text='Características de cocina' marginRight='16px' />
                    </Col>
                    <Col>
                        <StyledText 
                            text={reservaSelected.cocina.includes('Gas') ? 'Gas' : 'Eléctrica'}
                            color={Colors.gray} 
                        />
                    </Col>
                </Row>

                <Row style={{marginBottom: '10px'}}>
                    <Col md='5'>
                        <StyledText text='Número de fuegos' marginRight='16px' />
                    </Col>
                    <Col>
                        <StyledText 
                            text={reservaSelected.fuegos}
                            color={Colors.gray} 
                        />
                    </Col>
                </Row>

                <Row style={{marginBottom: '10px'}}>
                    <Col md='5'>
                        <StyledText text='Cuenta con horno' marginRight='16px' />
                    </Col>
                    <Col>
                        <StyledText 
                            text={reservaSelected.cocina.includes('Tenemos horno') ? 'Si' : 'No'}
                            color={Colors.gray} 
                        />
                    </Col>
                </Row>

                <Row style={{marginBottom: '10px'}}>
                    <Col md='5'>
                        <StyledText text='Microondas' marginRight='16px' />
                    </Col>
                    <Col>
                        <StyledText 
                            text={reservaSelected.cocina.includes('Microondas') ? 'Si' : 'No'}
                            color={Colors.gray} 
                        />
                    </Col>
                </Row>

                <div style={{marginBottom: '50px'}}>
                    <StyledText text='Restricciones alimentarias' />
                    <Row style={{marginBottom: '10px'}} className='align-items-center'>
                        <Col md='5'>
                            <StyledText 
                                text={reservaSelected.restricciones_alimentarias.length > 0 ? reservaSelected.restricciones_alimentarias.join(',') : 'No' } marginRight='16px'
                                color={Colors.gray} 
                            />
                        </Col>
                        <Col>
                            <StyledText 
                                text={`Otras alergias: ${reservaSelected.otras_alergias_text ? reservaSelected.otras_alergias_text : 'No' }`}
                                color={Colors.gray} 
                            />
                        </Col>
                    </Row>
                </div>
                
                {
                    reservaSelected.menu &&
                    <Row style={{marginBottom: '35px'}}>
                        <Col>
                            <StyledText text={`Menú ${reservaSelected.menu.nombre_menu}`} />
                        </Col>
                    </Row>
                }
                
                {
                    reservaSelected.menu &&
                    <Row>
                        <Col>
                            {
                                reservaSelected.menu.tiempos.map((time, index)=>{
                                    return(    
                                        <TimeMenuWrapper>
                                            <StyledText text={`Tiempo ${index+1}`} fontSize='11px' color={Colors.gray} />
                                            <StyledText text={time.nombre_platillo} />
                                            <StyledText text={time.descripcion} fontSize='11px' />
                                        </TimeMenuWrapper>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                }
                
                {
                    reservaSelected.menu &&
                        <div className='d-flex justify-content-end'>
                            <StyledText text='Precio por comensal' marginRight='8px' />
                            <StyledText 
                                text={CurrencyFormat(reservaSelected.menu.precio)} 
                                color={Colors.gray}
                                border={`solid 1px ${Colors.gray}`}
                                borderRadius='10px'
                            />
                        </div>
                }

                <Divider />

                <div className='d-flex justify-content-end'>
                    <StyledText text='Total' marginRight='8px' />
                    <StyledText 
                        text={CurrencyFormat(reservaSelected.costo_total)}
                    />
                </div>

            </ReservDetailInfo>
        </ReservationDetailWrapper>
    )
}

const ReservationDetailWrapper = styled.div``;

const ChefWrapper = styled.div``;

const RatingWrapper = styled.div``;

const LocationWrapper = styled.div``;

const ReservDetailInfo = styled.div`
    padding: 50px;
`;

const TimeMenuWrapper = styled.div`
    margin-bottom: 24px;
`;


export default ReservationDetail
