import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import Loading from '../../components/Loading';
import { callApi } from '../../fixtures/api';
import { config } from '../../fixtures/config';
import { CurrencyFormat } from '../../fixtures/helpers';
import Colors from '../../theme/Colors';
import IconWrong from '../../images/icon_wrong.png';
import IconCheckGreen from '../../images/icon_check_green.png';
import MainButton from '../../design/molecules/MainButton';
import { store } from '../../redux/store';
import swal from 'sweetalert';


const PaymentDetail = () => {
    
    const history = useHistory();
    const {userData} = useSelector(state => state.userLogged);
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({
        authorization: '',
        amount: '',
        error_message: '',
        error_code: '',
        order_id: ''
    });
    const location = useLocation();
    const id3dSecure = new URLSearchParams(location.search).get('id');

    useEffect(() => {
        getDetailPayment();
    }, [userData]);

    const RegisterReserv = async()=> {
        const reservation = store.getState().reservation;
        const data = {
            reservation: reservation,
            pay3dSecure: true,
            openpay_is_production: config.openpayIsProduction
        };
        const result = await callApi('registrar_reservacion', 'post', data);
        if(result.status == 200){
            setLoading(false);
        }else{
            setLoading(false);
            swal({
                text: result.message,
                icon: 'waring'
            });
        }
    }

    const getDetailPayment = async()=> {
        if(Object.keys(userData).length > 0){
            const openpayId = config.openpayIsProduction ? userData.openpay_id_prod : userData.openpay_id;
            const dataPayment = {
                "openpay_is_production": config.openpayIsProduction,
                "charge_id": id3dSecure,
                "customer_id": openpayId
            };
            const result = await callApi(`get_detail_payment`, 'post', dataPayment);
            if(result.status === 500){
                setLoading(false);
                swal({
                    text: result.message,
                    icon: 'warning'
                });
                setState((prevState)=>({
                    ...prevState,
                    error_code: result.error.error_code || 'error'
                }));
            }else{
                const {authorization, amount, error_message, order_id} = result;
                setState((prevState)=>({
                    ...prevState,
                    authorization,
                    amount,
                    error_message,
                    order_id,
                }));
                if(!error_message){
                    RegisterReserv();
                }else{
                    setLoading(false);
                    swal({
                        text: error_message,
                        icon: 'warning'
                    });
                }
            }
        }
    }

    const backChefProfile = ()=> {
        history.replace('/chef_profile');
    }

    const finishReservation = ()=> {
        localStorage.removeItem('chefSelected');
        localStorage.removeItem('reservation');
        history.replace('/');
    }

    return (
        <>
            {
                loading ?
                    <Loading visible={loading} />
                :
                    <>
                        <table align="center" border="0" width="90%" cellPadding="0" cellSpacing="0" style={{borderCollapse: 'collapse'}}>
                            <tbody>
                                <tr>
                                    <Td bgcolor="#ECEDF1" style={{color: "#2D2D67", fontSize: "17px", fontWeight: "bold", padding: "10px 0px", textAlign: "center"}}>
                                        <span face="Arial, Helvetica, sans-serif">Detalles de la transacción</span>
                                    </Td>
                                </tr>
                                <tr>
                                    <Td>
                                        <table border="1" bordercolor="#D2D2D3" cellPadding="0" cellSpacing="0" style={{borderCollapse: 'collapse'}} width='100%'>
                                            <tr>
                                                <Td>Estatus</Td>
                                                <Td><span id="estatus">{state.error_message || state.error_code ? 'Declinado' : 'Pagado'}</span></Td>
                                            </tr>
                                            <tr>
                                                <Td>Monto</Td>
                                                <Td><span id="amount">{CurrencyFormat(state.amount)}</span></Td>
                                            </tr>
                                            <tr>
                                                <Td>No. de autorización</Td>
                                                <Td><span id="authorization">{state.error_message || state.error_code ? 'N/A' : state.authorization}</span></Td>
                                            </tr>
                                            <tr>
                                                <Td>No. de reservación</Td>
                                                <Td><span id="reservation">{state.error_message || state.error_code ? 'N/A' : state.order_id}</span></Td>
                                            </tr>
                                        </table>
                                    </Td>
                                </tr>
                                <tr>
                                    <Td align="center" style={{fontSize: '20px', color:"#25CE92"}}>
                                        <span id="charge-result" style={{color: state.error_message || state.error_code ? Colors.mainColor : null}} face="Arial, Helvetica, sans-serif">{state.error_message || state.error_code ? 'Reserva no registrada' : 'Reserva registrada.'}</span>
                                    </Td>
                                </tr>
                                <tr>
                                    <Td align="center" style={{fontSize: '16px', color:"#2D2D67", paddingBottom: "16px"}}>
                                        <span id="text-result" face="Arial, Helvetica, sans-serif">{state.error_message || state.error_code ? 'Tu reserva no pudo ser registrada, intenta con otro método de pago. No se realizó ningún cargo a tu tarjeta.' : 'Tu reserva ha sido registrada con éxito.'}</span>
                                    </Td>
                                </tr>
                                <tr>
                                    <Td align="center">
                                        <img id="img-result" style={{width: "51px", height: "51px"}} src={state.error_message || state.error_code ? IconWrong : IconCheckGreen} alt='imagen de estatus de la transacción' />
                                    </Td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='d-flex justify-content-center mt-5'>
                            <MainButton text='Aceptar' onClick={state.error_message || state.error_code ? backChefProfile : finishReservation} />
                        </div>
                    </>
            }
        </>
    )
}

const Td = styled.td`
    padding: 8px;
    font-family: Helvetica, Arial, sans-serif;
    color: #2D2D67;
    font-size: 16px;
    text-align: ${props => props.align || null};
`;

export default PaymentDetail
