import React from 'react'
import styled from 'styled-components';

const GastronomicStyleImage = ({imageUrl, className}) => {
    return (
        <GastronomicStyleImageWrapper className={`${className} gastronomic-style-image-wrapper`}>
            <Image src={imageUrl} />
        </GastronomicStyleImageWrapper>
    )
}

const GastronomicStyleImageWrapper = styled.div`
    width: 79px;
    height: 79px;
    border-radius: 35px;
    overflow: hidden;
    pointer-events: none;
`;

const Image = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
`;

export default GastronomicStyleImage
