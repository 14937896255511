import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import swal from 'sweetalert';
import { ValidateEmail } from "../../../../fixtures/helpers";
import { callApi } from "../../../../fixtures/api";
import md5 from 'md5';
import { auth, authProvider } from "../../../../fixtures/firebase";
import { setUserLogged } from "../../../../redux/slices/userLoggedSlice";
import { config } from "../../../../fixtures/config";


const useRegisterModal = (closeRegisterModal) => {

    useEffect(() => {
        const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
        return subscriber;
    }, []);

    const onAuthStateChanged = (user)=> {
        if(user){}
    };

    const [loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [email, setEmail] = useState('');
    const [celular, setCelualr] = useState('');
    const [password, setPassword] = useState('');
    const [aceptaTerminos, setAceptaTerminos] = useState(false);
    const [codeSended, setCodeSended] = useState('');
    const [codeUser, setCodeUser] = useState('');
    
    const dispacth = useDispatch();

    const handleNombre = (e)=>{
        const nombre = e.target.value;
        setNombre(nombre);
    }

    const handleApellidos = (e)=>{
        const apellidos = e.target.value;
        setApellidos(apellidos);
    }

    const handleEmail = (e)=> {
        const email = e.target.value;
        setEmail(email);
    }

    const handleCelular = (celular)=>{
        setCelualr(celular);
    }

    const handlePassword = (e)=>{
        const password = e.target.value;
        setPassword(password);
    }

    const handleTerminos = (e)=>{
        const aceptaTerms = e.target.checked;
        setAceptaTerminos(aceptaTerms);
    }

    const handleRegister = async()=> {
        if(!nombre){
            swal({
                text: 'Escribe tu nombre.',
                icon: 'warning'
            });
            return;
        }
        if(!apellidos){
            swal({
                text: 'Escribe tus apellidos.',
                icon: 'warning'
            });
            return;
        }
        if(!email){
            swal({
                text: 'Escribe tu email.',
                icon: 'warning'
            });
            return;
        }
        if(!ValidateEmail(email)){
            swal({
                text: 'Escribe un email válido.',
                icon: 'warning'
            });
            return;
        }
        if(!celular){
            swal({
                text: 'Escribe tu celular.',
                icon: 'warning'
            });
            return;
        }
        if(!password){
            swal({
                text: 'Escribe tu contraseña.',
                icon: 'warning'
            });
            return;
        }
        if(!aceptaTerminos){
            swal({
                text: 'Debes aceptar los Términos y Condiciones.',
                icon: 'warning'
            });
            return;
        }

        setLoading(true);

        const dataEmail = {
            email
        };
        const resultValidateEmail = await callApi('validate_email','post', dataEmail);
        if(resultValidateEmail.status === 200){
            const dataCelular = {
                nombre,
                celular: `+${celular}`
            };
            const resultValidateCelular = await callApi('send_code_social_user','post',dataCelular);
            if(resultValidateCelular.status === 200){
                setLoading(false);
                setCodeSended(resultValidateCelular.codigo);
            }else{
                setLoading(false);
                swal({
                    text: resultValidateCelular.message,
                    icon: 'warning'
                });    
            }
        }else{
            setLoading(false);
            swal({
                text: resultValidateEmail.message,
                icon: 'warning'
            });
        }
        
    }

    const handleCodeUser = (e)=> {
        const codeUser = e.target.value;
        setCodeUser(codeUser);
    }

    const Auth = async(usuario)=> {
        try {
            const passMd5 = md5(password);
            const resultAuth = await auth.signInWithEmailAndPassword(email, passMd5);
            if(resultAuth.user){
                dispacth(setUserLogged(usuario));
                localStorage.setItem('userLogged', JSON.stringify(usuario));
                closeRegisterModal();
                setLoading(false);
            }else{
                swal({
                    text: 'No fue posible iniciar sesión.',
                    icon: 'warning'
                }); 
            }
        }catch (error) {
            setLoading(false);
            swal({
                text: 'No fue posible iniciar sesión intente mas tarde.',
                icon: 'warning'
            });
        }
    };

    const LoginExecute = async()=> {
        const passMd5 = md5(password);
        const data = {
            email: email,
            password: passMd5
        };
        const result = await callApi('login', 'post', data);
        if(result.status === 200){
            Auth(result.usuario);
        }else{
            swal({
                text: result.message,
                icon: 'warning'    
            });    
        }
    }

    const registerUserExecute = async()=> {
        setLoading(true);
        const passMd5 = md5(password);
        const data = {
            nombre,
            apellidos,
            email,
            celular: `+${celular}`,
            password: passMd5,
            provider: 'email',
            acepta_tyc_ap: aceptaTerminos,
            platform_register: 'web',
            interno: !config.openpayIsProduction
        }
        const resultRegister = await callApi('register_user', 'post', data);
        if(resultRegister.status === 200){
            LoginExecute();
        }else{
            setLoading(false);
            swal({
                text: resultRegister.message,
                icon: 'warning'
            });
        }
    }

    const handleVerifyCode = ()=> {
        if(!codeUser){
            swal({
                text: 'Escribe el código.',
                icon: 'warning'
            });
            return;
        }
        if(codeUser !== codeSended){
            swal({
                text: 'El código no coincide con el código enviado.',
                icon: 'warning'
            });
        }else{
            registerUserExecute();
        }
    }

    return {
        loading,
        handleNombre,
        handleApellidos,
        handleEmail,
        handleCelular,
        handlePassword,
        handleTerminos,
        handleRegister,
        handleCodeUser,
        handleVerifyCode,
        nombre,
        apellidos,
        email,
        celular,
        password,
        aceptaTerminos,
        codeSended,
        codeUser
    }
}

export default useRegisterModal
