import React, { useEffect, useState } from 'react';
import { callApi } from '../../fixtures/api';
import Images from '../../images/Images';
import styled from 'styled-components';
import Colors from '../../theme/Colors';
import { screenSizes } from '../../fixtures/ScreenSizes';
import 'moment/locale/es-mx';
import moment from 'moment';
import Loading from '../../components/Loading';

export default function Invite({match}){

    const [reservation, setReservation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(()=>{
        GetDetailInvite();
    },[]);

    const GetDetailInvite = async()=> {
        const data = {
            reservation_id: match.params.reservation_id
        };
        const resultInvite = await callApi('get_detail_invite', 'post', data);
        if(resultInvite.status === 200){
            setReservation(resultInvite.reservation);
            setLoading(false);
        }else{
            setErrorMessage(resultInvite.message);
            setLoading(false);
        }
    }

    const Holder = styled.div`
        max-width: 978px;
        margin: 0px auto;
        margin-bottom: 32px;
    `;

    const HolderContent = styled.div`
        padding: 0px 16px;
    `;

    const ImageHolder = styled.div`
        width: 100%;
        margin: 0px auto;
        position: relative;
        margin-bottom: 44px;
    `;

    const Title = styled.h1`
        position: absolute;
        top: 50%;
        box-sizing: border-box;
        color: ${Colors.white};
        font-size: 2.5em;
        left: 10%;
        width: 70%;

        @media (max-width: ${screenSizes.smallMax}){
            font-size: 1.2em;
        }

        @media (min-width: ${screenSizes.medium}){
            font-size: 2em;
        }
    `;

    const SubTitle = styled.h2`
        color: ${Colors.mainColor};
        font-size: 1.6em;
        text-align: center;
        margin-bottom: 40px;

        @media (max-width: ${screenSizes.mobile}){
            font-size: 1em;
        }

        @media (min-width: ${screenSizes.min_tablet}) and (max-width: ${screenSizes.tablets}){
            font-size: 1.3em;
        }
    `;

    const DateSubtitle = styled.span`
        text-transform: capitalize;
    `;

    const ErrorMessage = styled.h3`
        color: ${Colors.mainColor};
        text-align: center;
    `;

    return (
        <>
            {
                loading ?
                    <Loading visible={loading} />
                :
                errorMessage ?
                    <ErrorMessage>
                        {errorMessage}
                    </ErrorMessage>
                :
                    <Holder>
                        <ImageHolder>
                            <img className='img-fluid' src={Images.imgInviteReservation} alt='invitado a una reservación' />
                            <Title>{reservation.usuario_nombre},<br/> Te ha invitado a una reservación</Title>
                        </ImageHolder>
                        <HolderContent>
                            <SubTitle>Reserva #{reservation.numero_reserva} <DateSubtitle>({moment(reservation.fecha ? reservation.fecha : reservation.fechas[0], 'DD-MM-YYYY').format('MMMM DD, YYYY')})</DateSubtitle></SubTitle>
                            <table cellspacing="0" cellpadding="0" width='100%'>
                                <tr>
                                    <td bgcolor={Colors.mainColor} style={{fontSize: '15px', color: Colors.white, fontWeight: 'bold', padding: 8}}>
                                        Datos de la reservación
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table border="1" cellspacing="0" width="100%">
                                            <tr>
                                                <td style={{padding: '8px', fontWeight: 'bold'}}>
                                                    <font face="Arial, Helvetica, sans-serif">No.</font>
                                                </td>
                                                <td style={{padding: '8px', fontSize: '15px'}}>
                                                    <font face="Arial, Helvetica, sans-serif">{reservation.numero_reserva}</font>
                                                </td>
                                            </tr>
                                            {
                                                reservation.fechas ?
                                                    <tr>
                                                        <td style={{padding: '8px', fontWeight: 'bold'}}>
                                                            <font face="Arial, Helvetica, sans-serif">Fechas</font>
                                                        </td>
                                                        <td style={{padding: '8px', fontSize: '15px'}}>
                                                            {
                                                                reservation.fechas.map((fecha)=>{
                                                                    return(
                                                                        <font face="Arial, Helvetica, sans-serif">{`${fecha}, `}</font>
                                                                    )
                                                                })
                                                            }
                                                        </td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <td style={{padding: '8px', fontWeight: 'bold'}}>
                                                            <font face="Arial, Helvetica, sans-serif">Fecha</font>
                                                        </td>
                                                        <td style={{padding: '8px', fontSize: '15px'}}>
                                                            <font face="Arial, Helvetica, sans-serif">{reservation.fecha}</font>
                                                        </td>
                                                    </tr>
                                            }
                                            {
                                                reservation.fechas ?
                                                    <tr>
                                                        <td style={{padding: '8px', fontWeight: 'bold'}}>
                                                            <font face="Arial, Helvetica, sans-serif">Hora inicial - final</font>
                                                        </td>
                                                        <td style={{padding: '8px', fontSize: '15px'}}>
                                                            <font face="Arial, Helvetica, sans-serif">{`${reservation.hora_inicial} - ${reservation.hora_final}`}</font>
                                                        </td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <td style={{padding: '8px', fontWeight: 'bold'}}>
                                                            <font face="Arial, Helvetica, sans-serif">Hora</font>
                                                        </td>
                                                        <td style={{padding: '8px', fontSize: '15px'}}>
                                                            <font face="Arial, Helvetica, sans-serif">{reservation.hora}</font>
                                                        </td>
                                                    </tr>
                                            }
                                            <tr>
                                                <td style={{padding: '8px', fontWeight: 'bold'}}>
                                                    <font face="Arial, Helvetica, sans-serif">Lugar</font>
                                                </td>
                                                <td style={{padding: '8px', fontSize: '15px'}}>
                                                    <font face="Arial, Helvetica, sans-serif">{reservation.direccion.descripcion}</font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '8px', fontWeight: 'bold'}}>
                                                    <font face="Arial, Helvetica, sans-serif">Referencias</font>
                                                </td>
                                                <td style={{padding: '8px', fontSize: '15px'}}>
                                                    <font face="Arial, Helvetica, sans-serif">{reservation.direccion.referencias}</font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '8px', fontWeight: 'bold'}}>
                                                    <font face="Arial, Helvetica, sans-serif">Chef</font>
                                                </td>
                                                <td style={{padding: '8px', fontSize: '15px'}}>
                                                    <font face="Arial, Helvetica, sans-serif">{reservation.chef_nombre}</font>
                                                </td>
                                            </tr>
                                            {
                                                reservation.fecha && 
                                                    <tr>
                                                        <td style={{padding: '8px', fontWeight: 'bold'}}>
                                                            <font face="Arial, Helvetica, sans-serif">Menu</font>
                                                        </td>
                                                        <td style={{padding: '8px', fontSize: '15px'}}>
                                                            <font face="Arial, Helvetica, sans-serif">{reservation.menu.nombre_menu}</font>
                                                        </td>
                                                    </tr>
                                            }
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </HolderContent>
                    </Holder>
            }
        </>
    )
}
