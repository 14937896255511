import React from 'react'
import styled from 'styled-components'

const ChefListImage = ({imageUrl}) => {
    return (
        <ChefListImageWrapper className='chef-image-list-wrapper mb-2'>
            <ChefImage className='img-fluid' src={imageUrl} />
        </ChefListImageWrapper>
    )
}

const ChefListImageWrapper = styled.div`
    width: 237px;
    height: 238px;
    border-radius: 35px;
    overflow: hidden;
`;

const ChefImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export default ChefListImage
