import React from 'react'
import styled from 'styled-components'
import Colors from '../../theme/Colors';
import StyledText from './typography/StyledText';

const LabelCategory = ({category}) => {
    return (
        <LabelCategoryWrapper>
            <LabelCategoryHolder>
                <StyledText 
                    style={{textAlign: 'center'}}
                    text={category}
                    fontSize='8px'
                    color={Colors.gray}
                />
            </LabelCategoryHolder>
        </LabelCategoryWrapper>
    )
}

const LabelCategoryWrapper = styled.div`
    min-width: 62px;
    margin-right: 2px;
    margin-bottom: 4px;
`;

const LabelCategoryHolder = styled.div`
    border: solid 1px ${Colors.grayBorderB};
    border-radius: 6px;
    padding: 6px;
`;

export default LabelCategory

