import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { config } from './config';

firebase.initializeApp(config.firebaseConfig);
firebase.analytics();

export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const appleProvider = new firebase.auth.OAuthProvider('apple.com');
export const facebookProvider = new firebase.auth.FacebookAuthProvider;