import React from 'react';
import styled from 'styled-components';

const TextInput = ({
    value, 
    onChange, 
    isPassword, 
    placeholder, 
    disabled,
    maxLength,
    dataOpenpayCard
}) => {
    return (
        <Input
            value={value}
            onChange={onChange}
            type={isPassword ? 'password' : 'text'}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            data-openpay-card={dataOpenpayCard}
        />
    )
}

const Input = styled.input`
    background: transparent;
    border: 0;
    flex: 1;

    :focus {
        outline: none;
    }
`;

export default TextInput
