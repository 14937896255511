import { useEffect, useState } from 'react';
import { firestore } from '../../../fixtures/firebase';

const useBlog = () => {
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [trendPosts, setTrendPosts] = useState([]);
    const [featuredPosts, setFeaturedPosts] = useState([]);
    const [textSearch, setTextSearch] = useState([]);
    const [filteredPosts, setFilteredPosts] = useState([]);

    useEffect(() => {
        let unsubscribe = firestore.collection("Posts").where('active','==',true).orderBy('created_at','desc').onSnapshot((posts) => {
            const posts_founded = posts.docs.map((post)=>{
                let post_info = post.data();
                post_info.id = post.id;
                return post_info;
            });
            const trend_posts = posts_founded.filter(post => post.categorias.includes('Actualidad'));
            const featured_posts = posts_founded.filter(post => post.categorias.includes('Destacado'));
            setPosts(posts_founded);
            setTrendPosts(trend_posts);
            setFeaturedPosts(featured_posts);
            setLoading(false);
        });
        return ()=> {
            unsubscribe();
        }
    }, []);

    const handleSearchPosts = (e)=> {
        const text_search = e.target.value;
        setTextSearch(text_search);
        if(text_search){
            const posts_copy = [...posts];
            const post_filtered = posts_copy.filter(post => post.titulo.includes(text_search) || post.contenido.includes(text_search));
            setFilteredPosts(post_filtered);
        }else{
            setFilteredPosts([]);
        }
    }
    
    return {
        loading,
        posts,
        textSearch,
        handleSearchPosts,
        filteredPosts,
        trendPosts,
        featuredPosts
    }
}

export default useBlog
