import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { firestore } from "../../../../fixtures/firebase";
import { resetGastronomicStyles, setGastronomicStyles, changeGastronomicStyles } from "../../../../redux/slices/gastronomicStylesSlice";


const useGetGastronomicStyles = () => {
    
    //const [originalStyles, setOriginalStyles] = useState([]);
    const {chefs} = useSelector((state)=>state.visibleChefs);
    const dispacth = useDispatch();

    useEffect(() => {
        getGastronimicStyles();
    }, []);

    useEffect(()=>{
        countMenusByGastronimicStyle();
    },[chefs]);

    const getGastronimicStyles = async()=> {
        const gastronomicStyles = await firestore.collection('EstilosGastronomicos').get();
        let gastronomicStylesFounded = gastronomicStyles.docs.map(gastronomicStyle => {
            const gastronomicStyleData = gastronomicStyle.data();
            gastronomicStyleData.id = gastronomicStyle.id;
            return gastronomicStyleData;
        });

        gastronomicStylesFounded = gastronomicStylesFounded.sort(function(a, b){return(a.order - b.order)});

        chefs.forEach((chef)=>{
            chef.menus.forEach((menu)=>{
                gastronomicStylesFounded.forEach((estiloGastronomico)=>{
                    if(menu.estilos_gastronomicos.includes(estiloGastronomico.nombre)){
                        estiloGastronomico.numero_menus_by_chef++;
                    }
                });
            });
        });

        dispacth(setGastronomicStyles(gastronomicStylesFounded));
    }

    const countMenusByGastronimicStyle = ()=> {
        dispacth(resetGastronomicStyles());
        dispacth(changeGastronomicStyles({chefs}));
    }
}

export default useGetGastronomicStyles;
