import moment from "moment";
import { analytics } from "../../fixtures/firebase";
import Images from "../../images/Images";

const styles = {
    btnHolder: {
        position: 'fixed',
        right: 20,
        bottom: 20,
        width: 60,
        height: 60,
        cursor: 'pointer'
    },
    imgBtn: {
        width: '100%',
        height: '100%'  
    }
};

const WhatsAppBtn = ()=> {

    const GotoWhatsApp = ()=> {
        analytics.logEvent('whats_app_button_pressed',{
            date: moment().format('DD-MM-YYYY HH:mm')
        });        
        window.open('https://wa.me/5215578533785', '_blank');
    }

    return(
        <div onClick={GotoWhatsApp} style={styles.btnHolder}>
            <img style={styles.imgBtn} src={Images.btnWhats} alt='contacto_whatsapp' />
        </div>
    )
}

export default WhatsAppBtn;