import React from 'react'
import styled from 'styled-components'
import Colors from '../../../theme/Colors';
import {IoSearchOutline} from 'react-icons/io5';

const SearchField = ({textSearch, handleTextSearch}) => {

    return (
        <SearchFieldWrapper>
            <SearchFieldHolder className='d-flex align-items-center'>
                <IoSearchOutline  size={24} />
                <InputText 
                    value={textSearch} 
                    onChange={handleTextSearch} 
                    placeholder='Buscar artículos'
                />
            </SearchFieldHolder>
        </SearchFieldWrapper>
    )
}

const SearchFieldWrapper = styled.div`

`;

const SearchFieldHolder = styled.div`
    border: solid 1px ${Colors.grayBorder};
    border-radius: 28px;
    padding: 8px;
`;

const InputText = styled.input`
    width: 100%;
    border: none;
    outline: none;
`;

export default SearchField
