import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import ChefReviewItem from '../../molecules/ChefReviewItem/ChefReviewItem';
import AvatarPlaceholder from '../../../images/avatar_placeholder.png';

const ListChefReviews = () => {

    const {chef} = useSelector(state => state.chefSelected);
    const evaluaciones = chef.evaluaciones.slice(0,2);

    return (
        <ListChefReviewsWrapper className='list-chef-reviews-wrapper'>
            {
                evaluaciones.map((evaluacion)=>(
                    <ChefReviewItem 
                        imgAvatar={evaluacion.usuario.avatar !== "" ? evaluacion.usuario.avatar : AvatarPlaceholder }
                        commensalName={evaluacion.usuario.nombre}
                        review={evaluacion.comment}
                    />
                ))
            }
        </ListChefReviewsWrapper>
    )
}

const ListChefReviewsWrapper = styled.div`
    margin-bottom: 32px;
`;

export default ListChefReviews
