import React from 'react'
import styled from 'styled-components'
import Colors from '../../theme/Colors';

const TimeLineNumberItem = ({number}) => {
    return (
        <TimeLineNumber>
            <Number>{number}</Number>
        </TimeLineNumber>
    )
}

const TimeLineNumber = styled.div`
    width: 40px;
    height: 40px;
    background: ${Colors.mainColor};
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
`;

const Number = styled.span`
    font-weight: bold;
    color: ${Colors.white};
    font-family: 'Montserrat', sans-serif;
`;

export default TimeLineNumberItem
