const screenSizes = {
    small: '320px',
    smallMin: '479px',
    smallMiddle: '576px',
    smallMax: '767px',
    medium: '768px',
    mediumMax: '991px',
    large: '992px',
    largeMax: '1199px',
    xLarge: '1200px'
};

export {screenSizes};