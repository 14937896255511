import React from 'react'
import styled from 'styled-components'
import { screenSizes } from '../fixtures/ScreenSizes';
import Colors from '../theme/Colors';

const MainButton = ({text, onClick, width, height, fontSize, children}) => {
    return (
        <Button width={width} height={height} fontSize={fontSize} onClick={onClick}>{children ? children : text}</Button>
    )
}

const Button = styled.button`
    background-color: ${Colors.mainColor};
    color: ${Colors.white};
    border: 0;
    border-radius: 32px;
    font-family: 'Monyserrat', sans-serif;
    font-size: ${props => props.fontSize ? props.fontSize : '0.8em'};
    font-weight: bold;
    padding: 5px 15px;
    width: ${props => props.width && props.width};
    height: ${props => props.height && props.height};
    
    :focus {
        outline: none;
    }

    @media (max-width: ${screenSizes.mediumMax}){
        font-size: 14px;
    }
`;

export default MainButton
