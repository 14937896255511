import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { auth } from '../../../fixtures/firebase';
import { unsetUserLogged } from '../../../redux/slices/userLoggedSlice';
import Colors from '../../../theme/Colors';
import {FacebookLoginClient} from '@greatsumini/react-facebook-login';

const ListUserOptions = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const activeStyle = {
        color: Colors.mainColor
    };

    const logout = async()=> {
        FacebookLoginClient.getLoginStatus((res) => {
            if(res.status === "connected"){
                FacebookLoginClient.logout();
            }
        });
        await auth.signOut();
        localStorage.removeItem('userLogged');
        dispatch(unsetUserLogged());
        history.replace('/');
    }

    return (
        <ListUserOptionsWrapper className='list-user-options-wrapper'>
            <ListUserOptionsHolder>
                <ListUserOption exact activeStyle={activeStyle} borderer to='/mi_cuenta' >Mi cuenta</ListUserOption>
                <ListUserOption exact activeStyle={activeStyle} borderer to='/mis_reservaciones' >Mis reservas</ListUserOption>
                <ListUserOption exact activeStyle={activeStyle} borderer to='/metodos_pago' >Pagos</ListUserOption>
                <ListUserOption exact activeStyle={activeStyle} to='/configuracion_notificaciones' >Notificaiones</ListUserOption>
                <LinkUserOption onClick={logout} marginTop='20px' color={Colors.mainColor} >Cerrar sesión</LinkUserOption>
            </ListUserOptionsHolder>
        </ListUserOptionsWrapper>
    )
}

const ListUserOptionsWrapper = styled.div`
    margin-top: 24px;
`;

const ListUserOptionsHolder = styled.ul`
    margin: 0;
    padding: 0;
`;

const ListUserOption = styled(NavLink)`
    display: block;
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: ${props => props.borderer ? 'solid 1px' : '0px'};
    border-color: ${props => props.borderer ? Colors.grayInputForm : null};
    margin-top: ${props => props.marginTop || 0};
    color: ${props => props.color || Colors.gray};
    font-weight: 600;
    text-decoration: none;

    :hover{
        color: ${props => props.color || Colors.gray};
        text-decoration: none;
    }

    :visited {
        color: ${props => props.color || Colors.gray};
        text-decoration: none;
    }

    :active {
        color: ${props => props.color || Colors.gray};
        text-decoration: none;
    }
`;

const LinkUserOption = styled(Link)`
    display: block;
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: ${props => props.borderer ? 'solid 1px' : '0px'};
    border-color: ${props => props.borderer ? Colors.grayInputForm : null};
    margin-top: ${props => props.marginTop || 0};
    color: ${props => props.color || Colors.gray};
    font-weight: 600;
    text-decoration: none;

    :hover{
        color: ${props => props.color || Colors.gray};
        text-decoration: none;
    }

    :visited {
        color: ${props => props.color || Colors.gray};
        text-decoration: none;
    }

    :active {
        color: ${props => props.color || Colors.gray};
        text-decoration: none;
    }
`;

export default ListUserOptions
