import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import SearchChefs from '../../molecules/SearchChefs';
import MainBanner from '../../../images/main_banner.png';
import MainBannerMobile from '../../../images/main_banner_mobile.png';
import { screenSizes } from '../../../fixtures/ScreenSizes';
import StyledTitle from '../../atoms/StyledTitle';
import Colors from '../../../theme/Colors';
import MainButton from '../../../components/MainButton';
import moment from 'moment';
import { analytics } from '../../../fixtures/firebase';
import { useHistory } from 'react-router';

export const HeaderReservation = () => {

    const history = useHistory();
    
    const GotoWhatsApp = async ()=> {
        analytics.logEvent('budget_button_pressed',{
            date: moment().format('DD-MM-YYYY HH:mm')
        });
        window.open('https://wa.me/5215578533785', '_blank');
    }

    return (
        <HeaderReservationWrapper className='header-reservation-wrapper mb-5'>
            <CointainerB className='position-relative'>
                <SearchChefs />
            </CointainerB>
            <MainBannerWrapper className='main-banner-wrapper'>
                <SliderImageHederDesktop className='img-fluid' src={MainBanner} />
                <SliderImageHederMobile className='img-fluid' src={MainBannerMobile} />
                <TextMainBannerWrapper className='text-main-banner-wrapper'>
                    <StyledTitle
                        style={{textAlign: 'center'}}
                        text='¿Tienes un evento especial, una cena en pareja, una comida familiar ó entre amigos? contáctanos.'
                        fontSize='3vw'
                        color={Colors.white}
                        marginBottom='16px'
                    />
                    <div className='d-flex justify-content-center'>
                        <MainButton 
                            text='Cotizar' 
                            fontSize='1.4vw' 
                            onClick={GotoWhatsApp}
                            width='178px'
                        />
                    </div>
                </TextMainBannerWrapper>
            </MainBannerWrapper>
        </HeaderReservationWrapper>    
    )
}

const HeaderReservationWrapper = styled.div`

    @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
    }
`;

const CointainerB = styled(Container)`
    @media (max-width: 992px) {
        order: 2
    }
`;

const MainBannerWrapper = styled.div`
    position: relative;
    margin-bottom: 80px;

    @media (max-width: 992px) {
        order: 1
    }
`;

const TextMainBannerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    @media (max-width: ${screenSizes.mediumMax}){   
        text-align: center;
    }

    @media (max-width: ${screenSizes.smallMax}){   
        text-align: center;
        width: 90%;
    }
`;

const SliderImageHederDesktop = styled.img`
    object-fit: cover;
    margin: 0 auto;
    display: block;

    @media (max-width: ${screenSizes.large}) {
        display: none;
    }
`;

const SliderImageHederMobile = styled.img`
    object-fit: cover;
    display: none;
    margin: 0 auto;

    @media (max-width: ${screenSizes.mediumMax}) {
        display: block;
    }
`;

