import React, { useState } from 'react'
import styled from 'styled-components'
import InputCheckBox from '../../components/InputCheckBox';
import InputForm from '../../components/InputForm';
import Loading from '../../components/Loading';
import SelectForm from '../../components/SelectForm';
import { firestore } from '../../fixtures/firebase';
import { ValidateCelphoneMX, ValidateEmail } from '../../fixtures/helpers';
import { screenSizes } from '../../fixtures/ScreenSizes';
import Images from '../../images/Images';
import Colors from '../../theme/Colors';
import ReactPixel from 'react-facebook-pixel';
import MainButtonAnchor from '../../components/MainButtonAnchor';
import { Estados } from '../../fixtures/Estados';

const Header = () => {

    const [loading, setLoading] = useState(false);
    const [estados] = useState(Estados);
    const [nombre, setNombre] = useState('');
    const [errorNombre, setErrorNombre] = useState(' ');
    const [celular, setCelular] = useState('');
    const [errorCelular, setErrorCelular] = useState(' ');
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState(' ');
    const [estadoSelected, setEstadoSelected] = useState('');
    const [errorEstado, setErrorEstado] = useState(' ');
    const [aceptaTerminos, setAceptaTerminos] = useState(false);

    const handleName = (e)=> {
        const nombre = e.target.value;
        if(nombre.length < 12){
            setErrorNombre('Escribe tu nombre completo.');
        }else{
            setErrorNombre('');
        }
        setNombre(nombre);
    }

    const handleCelular = (e)=> {
        const celular = e.target.value.trim();
        if(!ValidateCelphoneMX(celular)){
            setErrorCelular('Escribe tu celular a 10 dígitos y solo números.');
        }else{
            setErrorCelular('');
        }
        setCelular(celular);
    }

    const handleEmail = (e)=> {
        const email = e.target.value.trim();
        if(!ValidateEmail(email)){
            setErrorEmail('Escribe un email válido');
        }else{
            setErrorEmail('');
        }
        setEmail(email);
    }

    const handleEstadoSelected = (e)=> {
        const estadoSelected = e.target.value;
        if(!estadoSelected){
            setErrorEstado('Selecciona un estado.');
            setEstadoSelected('');
        }else{
            setErrorEstado('');
            setEstadoSelected(estadoSelected);
        }
    }

    const handleCheckboxTerminos = (e)=> {
        setAceptaTerminos(!aceptaTerminos);
    }

    const resetForm = async()=>{
        setErrorNombre(' ');
        setErrorCelular(' ');
        setErrorEmail(' ');
        setErrorEstado(' ');
        setNombre('');
        setCelular('');
        setEmail('');
        setEstadoSelected('');
        setAceptaTerminos(false);
        setLoading(false);
        ReactPixel.trackCustom('Format Register Downloaded', {});
    }

    const downloadFormat = async()=> {
        const result = await firestore.collection('FormatosDescargados').add({
            aceptaPolitica: aceptaTerminos,
            aceptaTerminos: aceptaTerminos,
            celular: `+52${celular}`,
            email: email,
            nombre: nombre,
            estado: estadoSelected,
            created_at: new Date()
        });
        if(result.id){
            resetForm();
        }
    }

    return (
        <>
            <div className='container mb-3'>
                <div className='row'>
                    <div className='col-10 col-sm-7 d-md-none'>
                        <Title mobile>Haz que tus comensales descubran nuevos sabores</Title>
                    </div>
                </div>
            </div>
            <HeaderWrapper className='container header-wrapper py-5 mb-5'>
                <div className='row'>
                    <div className='col-md-6 align-items-end mb-lg-0 d-none d-md-flex'>
                        <Title>Haz que tus comensales descubran nuevos sabores</Title>
                    </div>
                    <div className='col-md-6 d-flex flex-column align-items-center justify-content-center justify-content-md-end'>
                        <img className='img-fluid d-md-none' src={Images.imgHeaderAlliedChefMobile} alt='descubre_nuevos_sabores' />
                        <FormHolder className='form-holder'>
                            <TitleForm className='mb-2'>Conviértete en chef aliado</TitleForm>
                            <div className='mb-2'>
                                <InputForm placeholder='Nombre Completo' value={nombre} onChange={handleName} />
                                <ErrroInput>{errorNombre}</ErrroInput>
                            </div>
                            <div className='mb-2'>
                                <InputForm maxLength={10} placeholder='Número Celular' value={celular} onChange={handleCelular} />
                                <ErrroInput>{errorCelular}</ErrroInput>
                            </div>
                            <div className='mb-2'>
                                <InputForm autocapitalize='none' placeholder='Correo electrónico' value={email} onChange={handleEmail} />
                                <ErrroInput>{errorEmail}</ErrroInput>
                            </div>
                            {
                                estados.length > 0 &&
                                <div className='mb-2'>
                                    <SelectForm estados={estados} value={estadoSelected} onChange={handleEstadoSelected} />
                                    <ErrroInput>{errorEstado}</ErrroInput>
                                </div>
                            }
                            <Caption className='mb-3'>Los datos proporcionados se apegan a lo dispuesto a la Ley Federal de Protección de Datos Personales en Posesión de Particulares, el cual se emite en nuestro aviso de privacidad.</Caption>
                            <div className='d-flex align-items-center justify-cntent-between mb-4'>
                                <InputCheckBox className='mr-2' name='agreeTerms' checked={aceptaTerminos} onChange={handleCheckboxTerminos} />
                                <Caption>Acepto <Link href="https://firebasestorage.googleapis.com/v0/b/kaichefapp.appspot.com/o/legal%2Fterminos_condiciones_kaichef.pdf?alt=media&token=48f7b5a6-c610-4038-891e-0305dba52d59" target='_blank' rel='noreferrer'>Términos y Condiciones</Link> y  <Link href="https://firebasestorage.googleapis.com/v0/b/kaichefapp.appspot.com/o/legal%2Faviso_privacidad_kaichef_mixto.pdf?alt=media&token=d3b62d47-e28e-4dd0-aa52-4bae8f6ecdaa" target='_blank' rel='noreferrer'>Aviso de Privacidad</Link>
                                </Caption>
                            </div>
                            <div className='d-flex justify-content-center'>
                                {
                                    !errorNombre && !errorCelular && !errorEmail && !errorEstado && aceptaTerminos &&
                                    <MainButtonAnchor onClick={downloadFormat} href='https://firebasestorage.googleapis.com/v0/b/kaichefapp.appspot.com/o/formatos%2Fformato_registro.pdf?alt=media&token=6f11d544-867e-450f-b7ce-dc6a9471034a' target='_blank' rel='noreferrer' text='DESCARGAR FORMATO' />
                                }
                            </div>
                        </FormHolder>
                    </div>
                </div>
            </HeaderWrapper>
            <Loading visible={loading} />
        </>
    )
}

const HeaderWrapper = styled.div`
    background: url(${Images.imgHeaderAlliedChef}) top center no-repeat;
    background-size: cover;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        background: ${Colors.blue}
    }  
`;

const Title = styled.h2`
    color: ${props => props.mobile ? Colors.blue : Colors.white};
    font-size: 35px;
    font-family: 'Monstserrat', sans-serif;
    font-weight: bold;
    margin-bottom: 0;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        text-align: left;
        font-size: 20px;
    }

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        text-align: left;
        font-size: 25px;
    }
`;

const FormHolder = styled.div`
    background-color: ${Colors.white};
    padding: 16px 40px;
    width: 297px;
`;

const TitleForm = styled.h3`
    color: ${Colors.blue};
    font-size: 1.1em;
    font-family: 'Monstserrat', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
`;

const Caption = styled.p`
    font-family: 'Monsterrat', sans-serif;
    font-size: 0.4em;
    color: ${Colors.blue};
`;

const Link = styled.a`
    color: ${Colors.mainColor};

    :visited {
        color: ${Colors.mainColor};
    }
`;
const ErrroInput = styled.span`
    font-size: 0.6em;
    color: ${Colors.mainColor};
`;

export default Header
