import React from 'react'
import styled from 'styled-components'
import Colors from '../theme/Colors';

const SelectForm = ({className, estados, onChange, value}) => {
    return (
        <Select className={className} onChange={onChange} value={value}>
            <Option value=''>Selecciona tu Estado</Option>
            {
                estados.map((estado)=>{
                    return(
                        <Option key={estado}>{estado}</Option>
                    )
                })
            }
        </Select>
    )
}

const Select = styled.select`
    background-color: ${Colors.grayInputForm};
    color: ${Colors.grayTextInput};
    font-size: 0.6em;
    height: 37px;
    border: 0;
    padding-left: 10px;
    width: 217px;

    :focus {
        outline: none;
    }
`;

const Option = styled.option`
    color: ${Colors.grayTextInput};
    font-size: 0.6em;
`;

export default SelectForm
