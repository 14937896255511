import { useEffect, useState } from "react"
import { useDispatch} from "react-redux";
import {setAllChefs} from '../../../redux/slices/allChefsSlice';
import { firestore } from "../../../fixtures/firebase";
import { config } from "../../../fixtures/config";

const useGetAllChefs = () => {

    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(()=>{
        getAllChefs();
    },[]);

    const getAllChefs = async()=> {
        const resultAllChefs = await firestore.collection('Chefs').where('test','==',!config.openpayIsProduction).where('habilitado','==',true).get();
        const allChefsFounded = resultAllChefs.docs.map((chef)=>{
            const chefData = chef.data();
            chefData.id = chef.id;
            return chefData;
        });

        dispatch(setAllChefs(allChefsFounded));
        setLoading(false);
    }

    return {
        loading
    }
    
}

export default useGetAllChefs
