import React from 'react';
import styled from 'styled-components';
import { FormatPrice } from '../../../fixtures/helpers';
import Colors from '../../../theme/Colors';
import StyledSubtitle from '../../atoms/StyledSubtitle';
import StyledText from '../../atoms/typography/StyledText';
import MainButton from '../../molecules/MainButton';
import ListMenuTimes from '../../organisms/ListMenuTimes';
import { setMenu } from '../../../redux/slices/reservationSlice';
import { useDispatch } from 'react-redux';

const ChefMenuItem = ({menu, isDetailReservation}) => {

    const dispatch = useDispatch();

    const setMenuSelected = ()=> {
        dispatch(setMenu(menu));
    }

    return (
        <ChefMenuItemWrapper className='chef-menu-item-wrapper'>
            <ChefMenuItemHeader isDetailReservation={isDetailReservation} className='chef-menu-item-header d-flex align-items-center justify-content-between'>
                <div>
                    <StyledSubtitle text={menu.nombre_menu} marginBottom='8px' />
                    <StyledSubtitle text={menu.tipos.join(', ')} color={Colors.gray} />
                </div>
                <div>
                    <StyledText style={{textAlign: 'center'}} text={FormatPrice(menu.precio)} marginBottom='16px' />
                    <MainButton onClick={isDetailReservation ? null : setMenuSelected} text='Seleccionar' />
                </div>
            </ChefMenuItemHeader>
            {
                !isDetailReservation &&
                    <ListMenuTimes tiempos={menu.tiempos} />
            }
        </ChefMenuItemWrapper>
    )
}

const ChefMenuItemWrapper = styled.div`
    border: solid 1px ${Colors.grayBorder};
    padding: 16px;
    border-radius: 35px;
    margin-bottom: 36px;
`;

const ChefMenuItemHeader = styled.div`
    margin-bottom: ${props => props.isDetailReservation ? '0px' : '30px'};
`;

export default ChefMenuItem
