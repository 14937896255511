import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import StyledSubtitle from '../../design/atoms/StyledSubtitle';
import ListUserOptions from '../../design/molecules/ListUserOptions/ListUserOptions';
import MainButton from '../../design/molecules/MainButton';
import TextInputItem from '../../design/molecules/TextInputItem/TextInputItem';
import UserAvatarInfo from '../../design/molecules/UserAvatarInfo/UserAvatarInfo';
import Colors from '../../theme/Colors';
import ImgCreditCard from '../../images/img_credit_card.png';
import usePaymentsMethods from './hooks/usePaymentsMethods';
import Loading from '../../components/Loading';
import CardItem from '../../design/molecules/CardItem/CardItem';
import StyledText from '../../design/atoms/typography/StyledText';

const PaymentsMethods = () => {

    const {
        loading,
        nombre,
        tarjeta,
        mesExp,
        yearExp,
        cvc,
        handleNombre,
        handleTarjeta,
        handleMesExpiracion,
        handleYearExpiracion,
        handleCvc,
        ValidateForm,
        cards,
        handleCardDelete
    } = usePaymentsMethods();

    return (
        <>
            <PaymentsMethodsWrapper className='my-account-wraper'>
                <Container>
                
                    <Row>
                        <Col md='3'>
                            <OptionsUserWrapper>
                                <div className='mb-4'>
                                    <UserAvatarInfo />
                                </div>
                                <div>
                                    <ListUserOptions />
                                </div>
                            </OptionsUserWrapper>
                        </Col>
                        
                        <Col md='9'>
                            <OptionsUserWrapper>
                                <div style={{marginBottom: '40px'}}>
                                    <StyledSubtitle text='Pagos' />
                                </div>
                                <Row style={{marginBottom: '30px'}}>
                                    <Col md='6'>
                                        <form id="processCard" name="processCard">

                                            <TextInputItem 
                                                text='Nombre del titular'
                                                value={nombre}
                                                onChange={handleNombre}
                                                dataOpenpayCard="holder_name"
                                            />
                                            <TextInputItem 
                                                text='Número de tarjeta' 
                                                value={tarjeta}
                                                onChange={handleTarjeta}
                                                mask
                                                placeholder='1234 1234 1234 1234'
                                            />
                                            <input type='hidden' value={tarjeta} data-openpay-card="card_number" />
                                            <div className='d-flex align-items-center'>
                                                <TextInputItem
                                                    widthWrapper='48%'
                                                    dataOpenpayCard="expiration_month"
                                                    marginRightWrapper='10px' 
                                                    text='Mes Exp.'
                                                    value={mesExp}
                                                    onChange={handleMesExpiracion}
                                                    placeholder='MM'
                                                    maxLength={2}
                                                />
                                                <TextInputItem
                                                    widthWrapper='48%'
                                                    dataOpenpayCard="expiration_year" 
                                                    marginRightWrapper='10px' 
                                                    text='Año Exp.'
                                                    value={yearExp}
                                                    onChange={handleYearExpiracion}
                                                    placeholder='AA'
                                                    maxLength={2}
                                                />
                                            </div>
                                            <TextInputItem 
                                                width='145px' 
                                                text='CVC' 
                                                value={cvc}
                                                onChange={handleCvc}
                                                maxLength={4}
                                                dataOpenpayCard="cvv2"
                                            />
                                        </form>
                                    </Col>
                                    <Col md='6'>
                                        <div className='d-flex justify-content-center'>
                                            <img style={{maxWidth: '220px'}} className='img-fluid' src={ImgCreditCard} alt='tarjeta ilustrativa crédito/débito' />
                                        </div>
                                    </Col>
                                </Row>
                                {
                                    cards.length === 3 ?
                                        <StyledText 
                                            text='Por seguridad solo puedes guardar 3 tarjetas, si necesitas agregar otra tarjeta, debes eliminar una.'
                                            marginBottom='16px'
                                        />
                                    :
                                        <div className='d-flex justify-content-center mb-5'>
                                            <MainButton 
                                                text='Agregar tarjeta'
                                                border={`solid 1px ${Colors.gray}`}
                                                borderRadius='19px'
                                                backGroundColor='transparent'
                                                color={Colors.gray}
                                                onClick={ValidateForm}
                                            />
                                        </div>
                                }
                                {
                                    cards.length > 0 &&
                                    <div>
                                        <StyledSubtitle text='Tarjetas guardadas' marginBottom='32px' />
                                        <div>
                                            {
                                                cards.map((card)=>{
                                                    return(
                                                        <CardItem 
                                                            card_name={card.bank_name} 
                                                            card_number={card.card_number}
                                                            onClickTrash={()=>{handleCardDelete(card)}}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </OptionsUserWrapper>
                        </Col>
                    </Row>

                </Container>
            </PaymentsMethodsWrapper>
            <Loading visible={loading} />
        </>
    )
}

const PaymentsMethodsWrapper = styled.div`
    background-color: ${Colors.bgAccountScreen};
    padding-top: 50px;
    padding-bottom: 100px;
`;

const OptionsUserWrapper = styled.div`
    background-color: ${Colors.white};
    padding: 20px;
    border-radius: 8px;
`;

export default PaymentsMethods
