import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import StyledSubtitle from '../../atoms/StyledSubtitle';
import ChefBadgeItem from '../../molecules/ChefBadgeItem';
import useGetChefBadges from './hooks/useGetChefBadges';

const ListChefBadges = () => {

    useGetChefBadges();

    const {badges} = useSelector(state => state.chefBadges);
    const {chef} = useSelector(state => state.chefSelected);

    return (
        <ListChefBadgesWrapper className='list-chef-badges-wrapper'>
            <Container>
                <TitleInsigniasWrapper className='title-insignias-wrapper'>
                    <StyledSubtitle text='Insignias recibidas' />
                </TitleInsigniasWrapper>
                <Row>
                    {
                        chef.insignias.map((insignia)=>(
                            badges.map((badge)=>{
                                if(insignia.nombre === badge.nombre){
                                    return(
                                        <Col xs='3' md='2' className='mb-1'>
                                            <ChefBadgeItem 
                                                badgeImage={badge.image_url} 
                                                badgeName={insignia.nombre}
                                                count={insignia.cantidad}
                                            />
                                        </Col>
                                    )
                                }else{
                                    return null;
                                }
                            })
                        ))
                    }
                </Row>
            </Container>
        </ListChefBadgesWrapper>
    )

}

const ListChefBadgesWrapper = styled.div`
    margin-bottom: 53px;
    margin-top: 29px
`;

const TitleInsigniasWrapper = styled.div`
    margin-bottom: 32px;
`;

export default ListChefBadges
