import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import Tab from '../../atoms/Tab';
import useTabsSelectedChefs from './hooks/useTabsSelectedChefs';

const TabsChefs = () => {

    const chefs_founded = useSelector(state => state.chefsFounded.chefs);
    const {activeAllChefs, activeRecomendedChefs} = useSelector(state => state.app);

    const {
        handleClickRecomendedChefs, 
        handleClickAllChefs
    } = useTabsSelectedChefs();

    return (
        <TabsChefsWrapper className='tabs-chefs-wrapper d-flex'>
            {
                chefs_founded.length > 0 &&
                <Tab text='Recomendados para ti' onClick={handleClickRecomendedChefs} active={activeRecomendedChefs} />
            }
            <Tab text='Ver todos los Chefs' onClick={handleClickAllChefs} active={activeAllChefs} />
        </TabsChefsWrapper>
    )
}

const TabsChefsWrapper = styled.div`

`;

export default TabsChefs
