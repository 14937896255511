import React from 'react'
import styled from 'styled-components'
import Colors from '../../../theme/Colors';
import StyledText from '../../atoms/typography/StyledText';
import InputMask from "react-input-mask";

const InputAddress = ({
    label, 
    placeholder, 
    onChange, 
    value, 
    dataOpenpayCard,
    maxLength,
    mask
}) => {
    return (
        <InputAddressWrapper className='input-address-wrapper'>
            <InputAddressHolder className='d-flex align-items-center'>
                <StyledText text={label} color={Colors.black} marginRight='12px' />
                {
                    mask ?
                        <InputTextMask
                            data-openpay-card={dataOpenpayCard} 
                            value={value} 
                            placeholder={placeholder}
                            onChange={onChange} 
                            mask="9999 9999 9999 9999"
                        />
                    :
                        <InputText 
                            data-openpay-card={dataOpenpayCard} 
                            value={value} 
                            placeholder={placeholder}
                            onChange={onChange} 
                            maxLength={maxLength}
                        />
                }
            </InputAddressHolder>
        </InputAddressWrapper>
    )
}

const InputAddressWrapper = styled.div`
    margin-bottom: 16px;
`;

const InputAddressHolder = styled.div`
    border: solid 1px ${Colors.gray};
    padding: 15px;
`;

const InputText = styled.input`
    flex: 1;
    border: 0;

    :focus {
        outline: none;
    }
`;

const InputTextMask = styled(InputMask)`
    flex: 1;
    border: 0;

    :focus {
        outline: none;
    }
`; 

export default InputAddress
