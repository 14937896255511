import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import StyledText from '../../design/atoms/typography/StyledText';
import ListUserOptions from '../../design/molecules/ListUserOptions/ListUserOptions';
import MainButton from '../../design/molecules/MainButton';
import NotificationSettingItem from '../../design/molecules/NotificationSettingsItem/NotificationSettingItem';
import UserAvatarInfo from '../../design/molecules/UserAvatarInfo/UserAvatarInfo';
import { setUserConfiguration } from '../../redux/slices/userLoggedSlice';
import Colors from '../../theme/Colors';

const NotificationsSettings = () => {

    const {userData} = useSelector(state => state.userLogged);
    const [configuracion, setConfiguracion] = useState(userData.configuracion);
    const dispatch = useDispatch();

    const handleSms = (sms)=> {
        setConfiguracion(prevState => ({
            ...prevState,
            sms
        }));
    }
    
    const handleEmails = (emails)=> {
        setConfiguracion(prevState => ({
            ...prevState,
            emails
        }));
    }
    
    const handlePushNotifications = (push_notifications)=> {
        setConfiguracion(prevState => ({
            ...prevState,
            push_notifications
        }));
    }

    const saveConfiguration = ()=> {
        dispatch(setUserConfiguration(configuracion));
    }

    return (
        <NotificationsSettingsWrapper className='my-account-wraper'>
            <Container>
                <Row>
                    <Col md='3'>
                        <OptionsUserWrapper>
                            <div className='mb-4'>
                                <UserAvatarInfo />
                            </div>
                            <div>
                                <ListUserOptions />
                            </div>
                        </OptionsUserWrapper>
                    </Col>
                    
                    <Col md='9'>
                        {
                            userData.configuracion &&
                            <OptionsUserWrapper>
                                <StyledText text='Configura las notificaciones que te gustaría recibir' color={Colors.gray}  marginBottom='40px' />
                                <NotificationSettingItem 
                                    checked={configuracion.sms} 
                                    label='Mensajes de texto' 
                                    onChange={handleSms} 
                                />
                                <NotificationSettingItem 
                                    checked={configuracion.emails} 
                                    label='Correos electrónicos' 
                                    onChange={handleEmails} 
                                />
                                <NotificationSettingItem 
                                    checked={configuracion.push_notifications} 
                                    label='Notificaciones push' 
                                    onChange={handlePushNotifications} 
                                />
                                <div className='mt-5 ml-5'>
                                    <MainButton
                                        text='Guardar'
                                        backGroundColor='transparent'
                                        border={`solid 1px ${Colors.grayBorder}`}
                                        color={Colors.gray}
                                        onClick={saveConfiguration}
                                    />
                                </div>
                            </OptionsUserWrapper>
                        }
                    </Col>
                </Row>
            </Container>
        </NotificationsSettingsWrapper>
    )
}

const NotificationsSettingsWrapper = styled.div`
    background-color: ${Colors.bgAccountScreen};
    padding-top: 50px;
    padding-bottom: 100px;
`;

const OptionsUserWrapper = styled.div`
    background-color: ${Colors.white};
    padding: 20px;
    border-radius: 8px;
`;

export default NotificationsSettings
