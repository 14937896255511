import React from 'react';
import styled from 'styled-components';
import Colors from '../../../theme/Colors';
import StyledSubtitle from '../../atoms/StyledSubtitle';
import StyledText from '../../atoms/typography/StyledText';
import ListChefMenus from '../ListChefMenus';

const ChefMenuReservation = () => {
    return (
        <ChefMenuReservationWrapper className='chef-menu-reservation-wrapper'>
            <StyledSubtitle text='Reservación por menú degustación' marginBottom='16px' />
            <StyledText text='¿Qué incluye?' />
            <StyledText 
                text='Puedes elegir uno de los cientos de menús dentro de los estilos gastronómicos de KaiChef. El Chef aliado preparará el menú en tu hogar, servirá los platillos y dejará impecable tu cocina.' 
                color={Colors.gray}
                marginBottom='18px'
            />
            <ListChefMenus />
        </ChefMenuReservationWrapper>
    )
}

const ChefMenuReservationWrapper = styled.div`
`;

export default ChefMenuReservation
