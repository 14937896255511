import { createSlice } from "@reduxjs/toolkit";

export const allChefsSlice = createSlice({
    name: 'allChefs',
    initialState: {
        chefs: []
    },
    reducers: {
        setAllChefs: (state, action)=> {
            state.chefs = action.payload;
        }
    }
});

export const {setAllChefs} = allChefsSlice.actions;
export default allChefsSlice.reducer;