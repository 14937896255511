import React from 'react';
import styled from 'styled-components';
import Colors from '../../../theme/Colors';
import StarIcon from '../../atoms/StarIcon';
import StyledText from '../../atoms/typography/StyledText';

const ChefRating = ({promedioRating}) => {
    return (
        <ChefRatingWrapper className='chef-rating-wrapper'>
            <ChefRatingHolder className='chef-rating-holder d-flex align-items-center'>
                <StarIcon className='mr-2' color={Colors.mainColor} />
                <StyledText text={promedioRating} />
            </ChefRatingHolder>
        </ChefRatingWrapper>
    )
}

const ChefRatingWrapper = styled.div`
`;

const ChefRatingHolder = styled.div`
`;


export default ChefRating
