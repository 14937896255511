import React from 'react';
import styled from 'styled-components';
import StyledText from '../../atoms/typography/StyledText';
import {IoMdMenu} from 'react-icons/io';
import Colors from '../../../theme/Colors';

const FilterOrderChefs = ({onClick}) => {
    return (
        <FilterOrderChefsWrapper onClick={onClick} className='filter-order-chefs-warpper d-flex align-items-center'>
            <StyledText text='Ordenar por' />
            <IoMdMenu color={Colors.blue} size={28} />
        </FilterOrderChefsWrapper>
    )
}

const FilterOrderChefsWrapper = styled.div`
    cursor: pointer;
`;

export default FilterOrderChefs
