import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Colors from '../../theme/Colors';
import Carousel, { consts }  from 'react-elastic-carousel';
import Loading from '../../components/Loading';
import StyledTitle from '../../design/atoms/StyledTitle';
import SearchField from '../../design/molecules/SearchField/SearchField';
import ArticleItem from '../../design/molecules/ArticleItem/ArticleItem';
import useBlog from './hooks/useBlog';
import { useHistory } from 'react-router';
import './blog.css';
import StyledText from '../../design/atoms/typography/StyledText';
import moment from 'moment';
import ArticleItemRelated from '../../design/molecules/ArticleItem/ArticleItemRelated';

export const Blog = () => {

    const {
        loading, 
        posts, 
        textSearch, 
        handleSearchPosts, 
        filteredPosts,
        trendPosts,
        featuredPosts
    } = useBlog();
    const [enableSwipe, setEnableSwipe] = useState(false);
    const {push} = useHistory();
    
    useEffect(() => {
        const handleResize = ()=> {
            if(window.innerWidth < 992){
                setEnableSwipe(true);
            }
            if(window.innerWidth > 992){
                setEnableSwipe(false);
            };
        };

        if(window.innerWidth < 992){
            setEnableSwipe(true);
        };
        if(window.innerWidth > 992){
            setEnableSwipe(false);
        };

        window.addEventListener('resize', handleResize);

        return ()=>{
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const GoDetail = (post)=> {
        push(`articulo/${post.titulo}`, {post, posts});
    }

    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV ? '<' : '>'
        return (
          <div className='d-flex flex-column justify-content-end' onClick={onClick} disabled={isEdge}>
            {pointer}
          </div>
        )
      }

    return (
        <>
            <BlogWrapper className='blog-wrapper'>
                <Container>
                    <Row>
                        <Col lg='8'>
                            <TrendWrapper className='trend-wrapper'>
                                <Carousel  
                                    //renderArrow={myArrow}
                                    enableSwipe={enableSwipe}
                                    itemsToShow={1}
                                    showArrows={true}
                                    outerSpacing={0}
                                    itemPosition={consts.START}
                                    itemPadding={[0, 5]}
                                    focusOnSelect={false}
                                    pagination={false}
                                >
                                    {
                                        trendPosts.map((post) => {
                                                return(
                                                    <SlideItem onClick={()=>{GoDetail(post)}} className='slide-item'>
                                                        <ImgSlide className='img-fluid' src={post.imagen_principal} />
                                                        <StyledTitle 
                                                            text={post.titulo}
                                                            marginBottom='16px'
                                                        />
                                                        <StyledText 
                                                            text={moment.unix(post.created_at.seconds).format('DD MMMM YYYY')}
                                                            fontSize='12px'
                                                        />
                                                    </SlideItem>
                                                )
                                            }
                                        )
                                    }
                                </Carousel>
                            </TrendWrapper>
                        </Col>
                        <Col lg='4'>
                            <FeaturedWrapper className='featured-wrapper'>
                                <FeaturedPostsList className='featured-posts-list'>
                                    {
                                        featuredPosts.map((post)=>{
                                            return(
                                                <ArticleItemRelated onClick={()=>{GoDetail(post)}} post={post} />
                                            )
                                        })
                                    }
                                </FeaturedPostsList>
                            </FeaturedWrapper>
                        </Col>
                    </Row>
                </Container>

                <ListPostsWrapper className='list-posts-wrapper'>
                    <TitleBar className='title-wrapper'>
                        <Container>
                            <Row className='align-items-center'>
                                <Col lg='9'>
                                    <StyledTitle 
                                        text='Todo acerca del mundo culinario aquí'
                                    />
                                </Col>
                                <Col lg='3'>
                                    <SearchField
                                        textSearch={textSearch}
                                        handleTextSearch={handleSearchPosts}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </TitleBar>
                    <ListPostHolder>
                        <Container>
                            <Row>    
                                {
                                    filteredPosts.length > 0 ?
                                        filteredPosts.map((post)=>{
                                            return(
                                                <Col lg='3'>
                                                    <ArticleItem onClick={()=>{GoDetail(post)}} post={post} />
                                                </Col>
                                            )
                                        })
                                    :
                                        posts.map((post)=>{
                                            return(
                                                <Col lg='3'>
                                                    <ArticleItem onClick={()=>{GoDetail(post)}} post={post} />
                                                </Col>
                                            )
                                        })
                                }
                            </Row>
                        </Container>
                    </ListPostHolder>
                </ListPostsWrapper>
            </BlogWrapper>
            <Loading visible={loading} />
        </>
    )
}

const BlogWrapper = styled.div`

`;

const SlideItem = styled.div`
    cursor: pointer;
`;

const ImgSlide = styled.img`
    border-radius: 8px;
    margin-bottom: 36px;
    pointer-events: none;
`;

const TrendWrapper = styled.div`
    box-shadow: 0px 0px 6px  ${Colors.grayBorder};
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 115px;
    position: relative;
`;

const FeaturedWrapper = styled.div`
    border-radius: 8px;
    box-shadow: 0px 0px 6px ${Colors.grayBorder};
    padding: 24px;
    margin-bottom: 45px;
`;

const FeaturedPostsList = styled.div`
    height: 480px;
    overflow: scroll;
`;

const ListPostsWrapper = styled.div`
    
`;

const TitleBar = styled.div`
    margin-bottom: 32px;
`;

const ListPostHolder = styled.div`
    
`;
