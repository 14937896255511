import React from 'react';
import styled from 'styled-components';
import {IoStar} from 'react-icons/io5';
import Colors from '../../theme/Colors';

const StarIcon = ({className, color, size}) => {
    return (
        <StarIconWrapper className='star-icon-wrapper'>
            <IoStar className={className} color={color || Colors.blue} size={size || 12} />
        </StarIconWrapper>
    )
}

const StarIconWrapper = styled.div`
`;

export default StarIcon
