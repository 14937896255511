import { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import swal from "sweetalert";
import { callApi } from "../../../fixtures/api";
import { config } from "../../../fixtures/config";
import { setOpenPayId, setOpenPayIdProd } from "../../../redux/slices/userLoggedSlice";
import {store} from 'redux';

const usePaymentsMethods = () => {
    
    const store = useStore();
    const dispatch = useDispatch();
    
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState([]);
    const [nombre, setNombre] = useState('');
    const [tarjeta, setTarjeta] = useState('');
    const [mesExp, setMesExp] = useState('');
    const [yearExp, setYearExp] = useState('');
    const [cvc, setCvc] = useState('');

    useEffect(()=>{
        getCardsUser();
    },[]);

    const clearCardForm = ()=> {
        setNombre('');
        setTarjeta('');
        setMesExp('');
        setYearExp('');
        setCvc('');
    }

    const getCardsUser = async()=> {
        const {userData} = store.getState().userLogged;
        if(!loading){
            setLoading(true);
        }
        const data = {
            customer_id: config.openpayIsProduction ? userData.openpay_id_prod : userData.openpay_id || '',
            openpay_is_production: config.openpayIsProduction
        };
        const result = await callApi('get_cards_customer', 'post', data);
        if(result.status == 200){
            setCards(result.tarjetas);
            setLoading(false);
        }else{
            setLoading(false);
            swal({
                text: result.message,
                icon: 'warning'
            });
        }
    };

    const handleNombre = (e)=> {
        const nombre = e.target.value;
        setNombre(nombre);
    }

    const handleTarjeta = (e)=> {
        const tarjeta = e.target.value.replace(/ /g, '');
        setTarjeta(tarjeta);
    }

    const handleMesExpiracion = (e)=> {
        const mesExp = e.target.value;
        setMesExp(mesExp);
    }

    const handleYearExpiracion = (e)=> {
        const yearExp = e.target.value;
        setYearExp(yearExp);
    }

    const handleCvc = (e)=> {
        const cvc = e.target.value;
        setCvc(cvc);
    }

    const saveCard = async(card_token)=> {
        const {userData} = store.getState().userLogged;
        const device_session_id = window.OpenPay.deviceData.setup("processCard");
        const data = {
            "usuario_id": userData.id,
            "nombre_usuario": userData.nombre,
            "apellido_paterno": userData.apellido_paterno || userData.apellidos,
            "email": userData.email,
            "celular": userData.celular,
            "customer_id": config.openpayIsProduction ? userData.openpay_id_prod : userData.openpay_id,
            "token_card": card_token,
            "device_session_id": device_session_id,
            "openpay_is_production": config.openpayIsProduction
        };
        const result = await callApi('add_card', 'post', data);
        if(result.status == 200){
            if (result.customer_created) {
                if(config.openpayIsProduction){
                    dispatch(setOpenPayIdProd(result.customer_id));
                }else{
                    dispatch(setOpenPayId(result.customer_id));
                }
                swal({
                    text: 'Tarjeta guardada con éxito.',
                    icon: 'success',
                }).then(()=>{
                    const {userData} = store.getState().userLogged;
                    localStorage.setItem('userLogged',JSON.stringify(userData));
                    getCardsUser();
                });
            }else {
                swal({
                    text: 'Tarjeta guardada con éxito.',
                    icon: 'success',
                }).then(()=>{
                    const {userData} = store.getState().userLogged;
                    localStorage.setItem('userLogged',JSON.stringify(userData));
                    getCardsUser();
                });
            }
            clearCardForm();
            setLoading(false);
        }else{
            setLoading(false);
            swal({
                text: result.message,
                icon: 'warning'
            });
        }
    }

    const ValidateForm = ()=> {
        if(!nombre){
            swal({
                text: 'Escribe tu nombre',
                icon: 'warning'
            });
            return;
        }
        if(!tarjeta){
            swal({
                text: 'Escribe el número de tarjeta',
                icon: 'warning'
            });
            return;
        }
        if(!mesExp){
            swal({
                text: 'Escribe el mes de expiración de la tarjeta.',
                icon: 'warning'
            });
            return;
        };
        if(!yearExp){
            swal({
                text: 'Escribe el año de expiración de la tarjeta.',
                icon: 'warning'
            });
            return;
        };
        if(!cvc){
            swal({
                text: 'Escribe el código de seguridad de la tarjeta.',
                icon: 'warning'
            });
            return;
        };
        setLoading(true);
        window.OpenPay.token.extractFormAndCreate('processCard',(success)=>{
            const {data} = success;
            saveCard(data.id);
        }, (error)=>{
            setLoading(false);
            const {data} = error;
            swal({
                text: `Tarjeta no válida, verifica los datos o intenta con otra. 
                        Código: ${data?.error_code}, ${data?.http_code}`,
                icon: 'warning'
            });
        });
    }

    const handleCardDelete = async(card)=> {
        const {userData} = store.getState().userLogged;
        setLoading(true);
        const data = {
            customer_id: config.openpayIsProduction ? userData.openpay_id_prod : userData.openpay_id,
            card_id: card.id,
            openpay_is_production: config.openpayIsProduction
        };
        const result = await callApi('delete_card_customer', 'post', data);
        if(result.status == 200){
            getCardsUser();
        }else{
            setLoading(false);
            swal({
                text: result.message,
                icon: 'warning'
            });
        }
    }
    
    return {
        loading,
        nombre,
        tarjeta,
        mesExp,
        yearExp,
        cvc,
        handleNombre,
        handleTarjeta,
        handleMesExpiracion,
        handleYearExpiracion,
        handleCvc,
        ValidateForm,
        cards,
        handleCardDelete
    }
}

export default usePaymentsMethods
