import React from 'react';
import styled from 'styled-components';
import Colors from '../../theme/Colors';

const BtnPromoCode = ({onClick, text}) => {
    return (
        <BtnPromo
            onClick={onClick}
        >
            {text}
        </BtnPromo>
    )
}

const BtnPromo = styled.button`
    height: 50px;
    flex:1;
    background: transparent;
    border: solid 1px ${Colors.grayBorder};

    :focus {
        outline: none;
    }
`;

export default BtnPromoCode
