import React from 'react'
import ChefBadge from '../../atoms/ChefBadge'
import StyledText from '../../atoms/typography/StyledText'

const ChefBadgeItem = ({badgeImage, badgeName, count}) => {
    return (
        <div className='chef-badge-item' style={{display: 'block', textAlign: 'center'}}>
            <ChefBadge badgeImage={badgeImage} count={count} />
            <StyledText text={badgeName} fontSize='11px' textAlign='center' />
        </div>
    )
}

export default ChefBadgeItem
