import moment from "moment";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { callApi } from "../fixtures/api";
import { config } from "../fixtures/config";
import { removeKeysReservationByMenu, setChef, setFechaSolicitud, setNumeroReserva, setProductionReservation, setUsuario } from "../redux/slices/reservationSlice";
import { setOpenPayId, setOpenPayIdProd } from "../redux/slices/userLoggedSlice";
import { store } from "../redux/store";

const useGetUserCards = () => {

    const {chef} = useSelector(state => state.chefSelected);
    const [cardsLoading, setCardsLoading] = useState(true);
    const [cards, setCards] = useState([]);
    const [cardSelected, setCardSelected] = useState('');
    const [nombre, setNombre] = useState('');
    const [tarjeta, setTarjeta] = useState('');
    const [mesExp, setMesExp] = useState('');
    const [yearExp, setYearExp] = useState('');
    const [cvc, setCvc] = useState('');
    const dispatch = useDispatch();
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    

    useEffect(()=>{
        getUserCards();
    },[]);

    const clearCardForm = ()=> {
        setNombre('');
        setTarjeta('');
        setMesExp('');
        setYearExp('');
        setCvc('');
    }

    const getUserCards = async()=> {
        const {userData} = store.getState().userLogged;
        if(userData.id){
            if(!cardsLoading){
                setCardsLoading(true);
            }
            const data = {
                customer_id: config.openpayIsProduction ? userData.openpay_id_prod : userData.openpay_id || '',
                openpay_is_production: config.openpayIsProduction
            };
            const result = await callApi('get_cards_customer', 'post', data);
            if(result.status == 200){
                setCards(result.tarjetas);
                setCardsLoading(false);
            }else{
                setCardsLoading(false);
                swal({
                    text: result.message,
                    icon: 'warning'
                });
            }
        }else{
            setCardsLoading(false);
        }
    }

    const saveCard = async(card_token)=> {
        const {userData} = store.getState().userLogged;
        const device_session_id = window.OpenPay.deviceData.setup("processCard");
        const data = {
            "usuario_id": userData.id,
            "nombre_usuario": userData.nombre,
            "apellido_paterno": userData.apellido_paterno || userData.apellidos,
            "email": userData.email,
            "celular": userData.celular,
            "customer_id": config.openpayIsProduction ? userData.openpay_id_prod : userData.openpay_id,
            "token_card": card_token,
            "device_session_id": device_session_id,
            "openpay_is_production": config.openpayIsProduction
        };
        const result = await callApi('add_card', 'post', data);
        if(result.status == 200){
            if (result.customer_created) {
                if(config.openpayIsProduction){
                    dispatch(setOpenPayIdProd(result.customer_id));
                }else{
                    dispatch(setOpenPayId(result.customer_id));
                }
                swal({
                    text: 'Tarjeta guardada con éxito.',
                    icon: 'success',
                }).then(()=>{
                    const {userData} = store.getState().userLogged;
                    localStorage.setItem('userLogged',JSON.stringify(userData));
                    getUserCards();
                });
            }else {
                swal({
                    text: 'Tarjeta guardada con éxito.',
                    icon: 'success',
                }).then(()=>{
                    const {userData} = store.getState().userLogged;
                    localStorage.setItem('userLogged',JSON.stringify(userData));
                    getUserCards();
                });
            }
            clearCardForm();
            setCardsLoading(false);
        }else{
            setCardsLoading(false);
            swal({
                text: result.message,
                icon: 'warning'
            });
        }
    }

    const handleNombre = (e)=> {
        const nombre = e.target.value;
        setNombre(nombre);
    }

    const handleTarjeta = (e)=> {
        const tarjeta = e.target.value.replace(/ /g, '');
        setTarjeta(tarjeta);
    }

    const handleMesExpiracion = (e)=> {
        const mesExp = e.target.value;
        setMesExp(mesExp);
    }

    const handleYearExpiracion = (e)=> {
        const yearExp = e.target.value;
        setYearExp(yearExp);
    }

    const handleCvc = (e)=> {
        const cvc = e.target.value;
        setCvc(cvc);
    }

    const validateForm = ()=> {
        const {userData} = store.getState().userLogged;
        if(Object.keys(userData).length === 0){
            swal({
                text: 'Debes registrarte para poder continuar con tu reservación.',
                icon: 'warning'
            }).then(()=>{
                setShowRegisterModal(true);
            });
            return;
        }
        if(!nombre){
            swal({
                text: 'Escribe tu nombre',
                icon: 'warning'
            });
            return;
        }
        if(!tarjeta){
            swal({
                text: 'Escribe el número de tarjeta',
                icon: 'warning'
            });
            return;
        }
        if(!mesExp){
            swal({
                text: 'Escribe el mes de expiración de la tarjeta.',
                icon: 'warning'
            });
            return;
        };
        if(!yearExp){
            swal({
                text: 'Escribe el año de expiración de la tarjeta.',
                icon: 'warning'
            });
            return;
        };
        if(!cvc){
            swal({
                text: 'Escribe el código de seguridad de la tarjeta.',
                icon: 'warning'
            });
            return;
        };
        setCardsLoading(true);
        window.OpenPay.token.extractFormAndCreate('processCard',(success)=>{
            const {data} = success;
            saveCard(data.id);
        }, (error)=>{
            setCardsLoading(false);
            const {data} = error;
            swal({
                text: `Tarjeta no válida, verifica los datos o intenta con otra. 
                        Código: ${data?.error_code}, ${data?.http_code}`,
                icon: 'warning'
            });
        });
    }

    const handleCardDelete = async(card)=> {
        const {userData} = store.getState().userLogged;
        setCardsLoading(true);
        const data = {
            customer_id: config.openpayIsProduction ? userData.openpay_id_prod : userData.openpay_id,
            card_id: card.id,
            openpay_is_production: config.openpayIsProduction
        };
        const result = await callApi('delete_card_customer', 'post', data);
        if(result.status == 200){
            if(cardSelected === card.id){
                setCardSelected('');
            }
            getUserCards();
        }else{
            setCardsLoading(false);
            swal({
                text: result.message,
                icon: 'warning'
            });
        }
    }

    const handleCardSelected = (card)=> {
        setCardSelected(card.id);
    }

    const Process3DSecure = async ()=>{
        setCardsLoading(true);
        const {userData} = store.getState().userLogged;
        const numero_reserva = `KC-${nanoid(8)}`;
        dispatch(setFechaSolicitud(moment().format('DD-MM-YYYY HH:mm')));
        dispatch(setProductionReservation(config.openpayIsProduction));
        dispatch(setChef(chef));
        dispatch(setUsuario(userData));
        dispatch(setNumeroReserva(numero_reserva));
        const reservation = store.getState().reservation;
        const device_session_id = window.OpenPay.deviceData.setup("processCard");
        let openpayId = config.openpayIsProduction ? userData.openpay_id_prod : userData.openpay_id;
        const chargeRequest = {
            "charge_data": {
                "source_id": cardSelected,
                "method": "card",
                "amount": reservation.costo_total - reservation.costo_total_descuento,
                "currency": "MXN",
                "description": "Cargo kaichef",
                "order_id": numero_reserva,
                "device_session_id": device_session_id,
                "use_3d_secure": true,
                "redirect_url": config.openpayIsProduction ? 'https://kaichef.com/openpay_payment_detail' : 'http://localhost:3000/openpay_payment_detail',
            },
            "customer_id": openpayId,
            "openpay_is_production": config.openpayIsProduction
        };
        localStorage.setItem('chefSelected', JSON.stringify(chef));
        localStorage.setItem('reservation', JSON.stringify(reservation));
        const result = await callApi(`process_3dsecure`, 'post', chargeRequest);
        if(result.status === 500){
            setCardsLoading(false);
            swal({
                text: result.message,
                icon: 'warning'
            });
        }else{
            if(result.http_code === 409){
                Process3DSecure();
            }else{
                if(!result.error_message){
                    setCardsLoading(false);
                    swal({
                        text: 'Por tu seguridad es probable que tu banco te solicite confirmar tu identidad para comprobar tu pago. Continuará tu proceso 3D Secure para finalizar el cargo a tu tarjeta bancaria.',
                        icon: 'success',
                        buttons: {
                            acept:{
                                text: 'Aceptar',
                                value: 'Aceptar'
                            }
                        }
                    }).then(()=>{
                        window.open(result.payment_method.url, '_self');
                    });
                }else{
                    setCardsLoading(false);
                    swal({
                        text: result.error_message,
                        icon: 'warning'
                    });
                }
            }
        }
    }

    const closeRegisterModal = ()=> {
        setShowRegisterModal(false);
    }

    return {
        cardsLoading,
        cards,
        handleCardDelete,
        handleCardSelected,
        cardSelected,
        validateForm,
        handleNombre,
        handleTarjeta,
        handleYearExpiracion,
        handleMesExpiracion,
        handleCvc,
        nombre,
        tarjeta,
        mesExp,
        yearExp,
        cvc,
        Process3DSecure,
        showRegisterModal,
        closeRegisterModal
    }
}

export default useGetUserCards;
