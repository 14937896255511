import React from 'react';
import styled from 'styled-components';
import GastronomicStyleImage from '../../atoms/GastronomicStyleImage';
import StyledText from '../../atoms/typography/StyledText';

const GastronomicStyleItem = ({name, imageUrl, onClick, numMenusByChef}) => {
    return (
        <GastronomicStyleItemWrapper onClick={onClick} className='gastronomic-style-item-wrapper d-flex align-items-center'>
            <GastronomicStyleImage className='mr-2' imageUrl={imageUrl} />
            <InfoWrapper className='info-wrapper'>
                <StyledText className='name-gastronomic-style' text={name} fontWeight='bold' />
                <StyledText text={`${numMenusByChef} menús`} />
            </InfoWrapper>
        </GastronomicStyleItemWrapper>
    )
}

const GastronomicStyleItemWrapper = styled.div`
    //cursor: pointer;
`;

const InfoWrapper = styled.div`
    pointer-events: none;
`;

export default GastronomicStyleItem
