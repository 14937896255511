import styled from "styled-components";
import { screenSizes } from "../fixtures/ScreenSizes";

export const IconPlusMinusCircle = styled.img`
    width: 23px;
    height: 23px;
    pointer-events: none;
`;

export const CircleWaveDecor = styled.img`
    max-width: 221px;
    max-height: 221px;
    position: ${props => props.absolute ? 'absolute' : 'relative'};
    top: ${props => props.absolute ? '25%' : 'unset'};
    left: ${props => props.absolute ? '25px' : 'unset'};

    @media (max-width: ${screenSizes.xLarge}) {
        left: 0;
    }

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        left: 0;
        max-width: 134px;
        max-height: 134px;
    }

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        top: ${props => props.absolute ? '134px' : 'unset'};
        left: ${props => props.absolute ? '-50px' : 'unset'};
        max-width: 134px;
        max-height: 134px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        top: ${props => props.absolute ? '30%' : 'unset'};
        left: ${props => props.absolute ? '-50px' : '40%'};
        bottom: ${props => props.absolute ? 'unset' : '50px'};
        max-width: 100px;
        max-height: 100px;
        position: absolute;
    }
`;