import React from 'react';
import styled from 'styled-components';

const ImageAvatar = ({srcImage}) => {
    return (
        <ImageAvatarWrapper className='image-avatar-wrapper'>
            <ImageAvatarHolder>
                <Avatar src={srcImage} />
            </ImageAvatarHolder>
        </ImageAvatarWrapper>
    )
}

const ImageAvatarWrapper = styled.div`
    width: 65px;
    height: 65px;
    margin-right: 16px;
`;

const ImageAvatarHolder = styled.div`
    width: 65px;
    height: 65px;
    border-radius: 35px;
    overflow: hidden;
`;

const Avatar = styled.img`
    width: 65px;
    height: 65px;
    object-fit: cover;
`;

export default ImageAvatar
