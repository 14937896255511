import React from 'react'
import styled from 'styled-components';
import { screenSizes } from '../../fixtures/ScreenSizes';
import Colors from '../../theme/Colors';

const ItemCustomProfile = ({img, title, text, imageWidth, imageHeight  }) => {
    return (
        <ItemCustomProfileHolder className='d-flex flex-column align-items-md-center mb-4 mb-lg-0'>
            <Img className='mb-3' imageWidth={imageWidth} imageHeight={imageHeight} src={img} />
            <Title className='mb-4'>{title}</Title>
            <Text>{text}</Text>
        </ItemCustomProfileHolder>
    )
}

const ItemCustomProfileHolder = styled.div`
    
`;

const Img = styled.img`
    width: ${props => props.imageWidth};
    height: ${props => props.imageHeight};

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        width: 50px;
        height: 50px;
        object-fit: contain;
    }
`;

const Title = styled.h4`
    font-size: 20px;
    color: ${Colors.blue};
    font-weight: bold;
    text-align: center;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        text-align: left;
        font-size: 14px;
    }
`;

const Text = styled.p`
    font-size: 16px;
    color: ${Colors.blue};
    text-align: center;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        text-align: left;
        font-size: 12px;
    }
`;

export default ItemCustomProfile;