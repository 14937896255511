import Colors from '../theme/Colors';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import * as Icon from 'react-icons/fa';
import Images from '../images/Images';
import { screenSizes } from '../fixtures/ScreenSizes';
import { ValidateEmail } from '../fixtures/helpers';
import { firestore } from '../fixtures/firebase';
import swal from 'sweetalert';
import Loading from './Loading';

const Footer = () => {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState(' ');
    const [sizeIconPayments, setsizeIconPayments] = useState(40);

    useEffect(() => {
        const handleResize = ()=> {
            if(window.innerWidth < 480){
                setsizeIconPayments(30);
                
            }
            if(window.innerWidth > 480){
                setsizeIconPayments(40);
            }
        };
        if(window.innerWidth < 480){
            setsizeIconPayments(30);
            
        }
        if(window.innerWidth > 480){
            setsizeIconPayments(40);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const handleEmail = (e)=> {
        const email = e.target.value;
        if(!ValidateEmail(email)){
            setErrorEmail('Escribe un email válido.');
        }else{
            setErrorEmail('');
        }
        setEmail(email);
    }

    const addToNewsletter = async()=> {
        setLoading(true);
        if(errorEmail){
            setErrorEmail('Escribe un email válido.');
            return;
        };
        const result = await firestore.collection('Newsletter').add({
            email,
            created_at: new Date(),
        });
        setLoading(false);
        if(result.id){
            setEmail('');
            setErrorEmail(' ');
            swal("¡Gracias!", "Tu email se ha guardado correctamente.", "success", {buttons : 'Aceptar'});
        }
    }

    return (
        <>
            <FooterHolder>
                <div className='container'>
                    <FooterContent className='row'>
                        <div className='col-6 col-sm-6 col-md-4 col-lg-2'>
                            <FooterColumn>
                                <LogoKaichefWhite src={Images.Logo} className='img-fluid' />
                                <SocialIconsHolder>
                                    <IconHolder>
                                        <a href='https://www.instagram.com/kaichefmx' target='_blank' rel='noreferrer'>
                                            <Icon.FaInstagram size={24} color={Colors.white} />
                                        </a>
                                    </IconHolder>
                                    <IconHolder>
                                        <a href='https://www.facebook.com/KaiChefMx' target='_blank' rel='noreferrer'>
                                            <Icon.FaFacebookSquare size={24} color={Colors.white} />
                                        </a>
                                    </IconHolder>
                                    <IconHolder>
                                        <a href='https://twitter.com/kaichefmx' target='_blank' rel='noreferrer'>
                                            <Icon.FaTwitter size={24} color={Colors.white} />
                                        </a>
                                    </IconHolder>
                                    <IconHolder>
                                        <a href='https://www.youtube.com/channel/UC9Q0adaBUmFJMCp7TU2Hpgw' target='_blank' rel='noreferrer'>
                                            <Icon.FaYoutube size={24} color={Colors.white} />
                                        </a>
                                    </IconHolder>
                                </SocialIconsHolder>
                            </FooterColumn>
                        </div>
                        <div className='col-lg-2'>
                            <FooterColumn>
                                <TitleFooter>CONTÁCTANOS</TitleFooter>
                                <TextFooter>Soporte Técnico soporte@kaichef.com</TextFooter>
                                <TextFooter>Atención a Clientes ayuda@kaichef.com</TextFooter>
                            </FooterColumn>
                        </div>
                        <div className='col-lg-2'>
                            <FooterColumn>
                                <TitleFooter>POLÍTICAS</TitleFooter>
                                <LinkFooter href='https://firebasestorage.googleapis.com/v0/b/kaichefapp.appspot.com/o/legal%2Fterminos_condiciones_kaichef.pdf?alt=media&token=ff49e69f-20a4-46ae-8799-563845958cdf' target='_blank' rel='noreferrer'>Términos y Condiciones</LinkFooter>
                                <LinkFooter href='https://firebasestorage.googleapis.com/v0/b/kaichefapp.appspot.com/o/legal%2Faviso_privacidad_kaichef_mixto.pdf?alt=media&token=d3b62d47-e28e-4dd0-aa52-4bae8f6ecdaa' target='_blank' rel='noreferrer'>Aviso de privacidad</LinkFooter>
                                {/* <LinkFooter>Política de Cancelación</LinkFooter> */}
                            </FooterColumn>
                        </div>
                        <div className='col-lg-2'>
                            <FooterColumn>
                                <TitleFooter>DESCARGA LA APP</TitleFooter>
                                <BtnsStoreHolder>
                                    <BtnStoreLink href='https://apps.apple.com/mx/app/kaichef/id1553924790' target='_blank' rel='noreferrer'>
                                        <BtnStoreAppStore alt='Kaichef AppStore' src={Images.Appstore} />
                                    </BtnStoreLink>
                                    <BtnStoreLink href='https://play.google.com/store/apps/details?id=com.app.kaichefuser' target='_blank' rel='noreferrer'>
                                        <BtnStorePlayStore alt='Kaichef PlayStore' src={Images.Playstore} />
                                    </BtnStoreLink>
                                </BtnsStoreHolder>
                            </FooterColumn>
                        </div>
                        <div className='col-lg-4'>
                            <FooterColumn>
                                <TitleFooter>SUSCRÍBETE</TitleFooter>
                                <TextFooter>Suscríbete a nuestra newsletter, para que seas el primero en enterarte de nuevas ofertas y promociones.</TextFooter>
                                <InputHolder>
                                    <Input autoCapitalize='none' type='text' placeholder='Correo electrónico' value={email} onChange={handleEmail} />
                                    <BtnSuscribe onClick={addToNewsletter}>Suscribirse</BtnSuscribe>
                                </InputHolder>
                                <ErrroInput>{errorEmail}</ErrroInput>
                            </FooterColumn>
                        </div>
                    </FooterContent>
                    <FooterCopyRightHolder className='row flex-column-reverse flex-lg-row'>
                        <div className='col-lg-7'>
                            <TextFooter copyright>© 2020 KaiChef. Todos los derechos reservados</TextFooter>
                        </div>
                        <div className='col-lg-5 d-flex justify-content-lg-between mb-2 mb-lg-0'>
                            {/* <Icon.FaCcPaypal className='mr-3 mr-lg-0' color={Colors.white} size={sizeIconPayments} /> */}
                            <LogoOpenPay className='mr-3 mr-lg-0' src={Images.logoOpenpay} />
                            <Icon.FaCcMastercard className='mr-3 mr-lg-0' color={Colors.white} size={sizeIconPayments} />
                            <Icon.FaCcVisa className='mr-3 mr-lg-0' color={Colors.white} size={sizeIconPayments} />
                            <Icon.FaCcAmex className='mr-3 mr-lg-0' color={Colors.white} size={sizeIconPayments} />
                        </div>
                    </FooterCopyRightHolder>
                </div>
            </FooterHolder>
            <Loading visible={loading} />
        </>
    )
}

const FooterHolder = styled.div`
    background-color: ${Colors.grayFooter};
    padding: 30px 0px;
`;

const FooterContent = styled.div`
    margin-bottom: 160px;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        margin-bottom: 40px;
    }
`;

const FooterColumn = styled.div`
    margin-bottom: 40px;
`;

const LogoKaichefWhite = styled.img`
    margin-bottom: 16px;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}){

    }
`;

const SocialIconsHolder = styled.div`
    display: flex;
`;

const IconHolder = styled.div`
    margin-right: 12px;
`;

const TitleFooter = styled.h5`
    font-size: 1.25em;
    color: ${Colors.white};
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    margin-bottom: 21px;
`;

const TextFooter = styled.h5`
    font-size: 0.87em;
    color: ${Colors.white};
    font-family: 'Oswald', sans-serif;
    margin-bottom: 21px;
`;

const LinkFooter = styled.a`
    font-size: 0.87em;
    color: ${Colors.white};
    font-family: 'Oswald', sans-serif;
    margin-bottom: 21px;
    display: block;

    :hover {
        color: ${Colors.white};
    }

    :active{
        text-decoration: none;
        color: ${Colors.white};
    }
`;

const BtnsStoreHolder = styled.div`
    
`;

const BtnStoreLink = styled.a`
    display: block;
    margin-bottom: 16px;
`;

const BtnStoreAppStore = styled.img`
    width: 144px;
    height: 47px;
`;

const BtnStorePlayStore = styled.img`
    width: 144px;
    height: 42px;
`;

const InputHolder = styled.div`
    display: flex;
`;

const Input = styled.input`
    background-color: ${Colors.white};
    border: 0px;
    color: ${Colors.black};
    height: 50px;
    font-family: 'Oswald', sans-serif;
    font-size: 1em;
    padding: 10px;
    width: 262px;

    :focus {
        outline: none;
    }
`;

const BtnSuscribe = styled.button`
    background-color: ${Colors.mainColor};
    border: 0px;
    color: ${Colors.white};
    font-family: 'Oswald', sans-serif;
    font-size: 1em;
    height: 50px;
    width: 118px;
    font-weight: bold;

    :focus{
        outline: none;
    }
`;

const FooterCopyRightHolder = styled.div`
    align-items: center;
`;

const LogoOpenPay = styled.img`
    object-fit: contain;
    height: 45px;
    width: 100px;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMin}){
        height: 33px;
        width: 75px;
    }
`;

const ErrroInput = styled.span`
    font-size: 0.8em;
    color: ${Colors.white};
`;

export default Footer
