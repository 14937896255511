import React from 'react';
import styled from 'styled-components';
import LocationIcon from '../../atoms/LocationIcon';
import StyledText from '../../atoms/typography/StyledText';

const ChefLocation = ({chefLocation}) => {
    return (
        <ChefLocationWrapper className='chef-location-wrapper d-flex align-items-center'>
            <LocationIcon className='mr-2' size={14} />
            <StyledText text={chefLocation} />
        </ChefLocationWrapper>
    )
}

const ChefLocationWrapper = styled.div`
`;

export default ChefLocation
