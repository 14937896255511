import { createSlice } from "@reduxjs/toolkit";

export const chefsFoundedSlice = createSlice({
    name: 'chefsFounded',
    initialState: {
        chefs: []
    },
    reducers: {
        setChefsFounded: (state, action)=> {
            state.chefs = action.payload;
        }
    }
});

export const {setChefsFounded} = chefsFoundedSlice.actions;
export default chefsFoundedSlice.reducer;