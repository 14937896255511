import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components'
import StyledTitle from '../../design/atoms/StyledTitle';
import StyledText from '../../design/atoms/typography/StyledText';
import parse from 'html-react-parser';
import LabelCategory from '../../design/atoms/LabelCategory';
import Colors from '../../theme/Colors';
import ArticleItemRelated from '../../design/molecules/ArticleItem/ArticleItemRelated';
import { useHistory } from 'react-router';
import './article.css';

const ArticleDetail = ({location}) => {
    
    const {post} = location.state;
    const {posts} = location.state;
    const [relatedPosts, setRelatedPosts] = useState([]);
    const {push} = useHistory();

    useEffect(() => {
        let all_posts = [...posts];
        all_posts = all_posts.filter(p => p.id !== post.id);
        const related_posts = [];
        post.categorias.forEach((category)=>{
            all_posts.forEach((post_item)=>{
                post_item.categorias.forEach((category_b)=>{
                    if(category === category_b){
                        if(!related_posts.find(p => p.id === post_item.id)){
                            related_posts.push(post_item);
                        }
                    }
                });
            });
        });
        setRelatedPosts(related_posts);
    }, [post]);

    const GoDetail = (post)=> {
        push(`${post.titulo}`, {post, posts});
    }

    return (
        <ArticleDetailWrapper>
            <ArticleDetailHolder>
                <Container>
                    <Row>
                        <Col lg='7'>
                            <StyledTitle 
                                text={post.titulo}
                                fontSize='34px'
                                marginBottom='24px'
                            />
                            <StyledText
                                style={{fontStyle: 'italic'}}
                                text={`Por KaiChef el ${moment.unix(post.created_at.seconds).format('LL')}`}
                                fontSize='12px'
                                marginBottom='24px'
                            />
                            <PostImg className='img-fluid' src={post.imagen_principal} />
                            <ContentPostWrapper>
                                {parse(post.contenido)}
                            </ContentPostWrapper>
                            <CategoriesWrapper>
                                <StyledText 
                                    text='Categorías'
                                    fontSize='8px'
                                    marginBottom='8px'
                                    color={Colors.gray}
                                />
                                <CategoriesHolder>
                                    {
                                        post.categorias.map((category)=>{
                                            return(
                                                <LabelCategory category={category} />
                                            )
                                        })
                                    }
                                </CategoriesHolder>
                            </CategoriesWrapper>
                        </Col>
                        <Col lg='4' className='offset-lg-1'>
                            <RelatedPostsWrapper>
                                <RelatedPostsHolder>
                                    <StyledTitle 
                                        text='También podría interesarte'
                                        marginBottom='24px'
                                    />
                                    <RelatedPostsList>
                                        {
                                            relatedPosts.map((post)=>{
                                                return(
                                                    <ArticleItemRelated onClick={()=>{GoDetail(post)}} post={post} />
                                                )
                                            })
                                        }
                                    </RelatedPostsList>
                                </RelatedPostsHolder>
                            </RelatedPostsWrapper>
                        </Col>
                    </Row>
                </Container>
            </ArticleDetailHolder>
        </ArticleDetailWrapper>
    )
}

const ArticleDetailWrapper = styled.div``;

const ArticleDetailHolder = styled.div``;

const PostImg = styled.img`
    margin-bottom: 34px;
`;

const ContentPostWrapper = styled.div`
    margin-bottom: 32px;
`;

const CategoriesWrapper = styled.div`
    margin-bottom: 32px;
`;

const CategoriesHolder = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const RelatedPostsWrapper = styled.div`

`;

const RelatedPostsHolder = styled.div`

`;

const RelatedPostsList = styled.div`
    border-radius: 8px;
    box-shadow: 0px 0px 6px ${Colors.grayBorder};
    padding: 24px;
`;

export default ArticleDetail
