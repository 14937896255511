import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import StarIcon from '../../atoms/StarIcon';
import StyledSubtitle from '../../atoms/StyledSubtitle';
import MainButton from '../MainButton';

const ChefReviewsHeader = () => {

    const {chef} = useSelector(state => state.chefSelected);

    const showModalReviews = ()=> {
    }
    
    return (
        <ChefReviewsHeaderWrapper className='chef-reviews-header-wrapper'>
            <ChefReviewsHolder className='chef-reviews-holder d-flex align-items-center'>
                <IconHolder className='icon-holder'>
                    <StarIcon size={16} /> 
                </IconHolder>
                <SubtitleHolder className='title-holder'>
                    <StyledSubtitle text='Reseñas o calificaciones' />
                </SubtitleHolder>
                {
                    chef.evaluaciones.length > 3 &&
                        <MainButton onClick={showModalReviews} text='Ver todas' />
                }
            </ChefReviewsHolder>
        </ChefReviewsHeaderWrapper>
    )
}

const ChefReviewsHeaderWrapper = styled.div`
    margin-bottom: 60px;
`;

const ChefReviewsHolder = styled.div`
`;

const IconHolder = styled.div`
    margin-right: 16px;
`;

const SubtitleHolder = styled.div`
    margin-right: 32px;
`;

export default ChefReviewsHeader;
