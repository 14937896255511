import React, { useState, useEffect } from 'react';
import Carousel, { consts }  from 'react-elastic-carousel';
import styled from 'styled-components';
import Colors from '../../theme/Colors';
import { callApi } from '../../fixtures/api';
import { storage } from '../../fixtures/firebase';
import Loading from '../../components/Loading';
import {gsap} from 'gsap';
import { screenSizes } from '../../fixtures/ScreenSizes';
import BannerViewAllChefs from '../../design/molecules/BannerViewAllChefs/BannerViewAllChefs';
import StyledText from '../../design/atoms/typography/StyledText';

const AlliedChefs = () => {
    
    const [state, setState] = useState({
        loading: true,
        chefs: [],
        chefShowReview: null,
        windowWidth: window.innerWidth,
        itemToShow: 5,
        enableSwipe: false
    });

    useEffect(() => {
        GetChefs();
        const handleResize = ()=> {
            setState((prevState)=>({
                ...prevState,
                windowWidth: window.innerWidth
            }));
            if(window.innerWidth < 992){
                setState((prevState)=>({
                    ...prevState,
                    itemToShow: 5,
                    enableSwipe: true
                }));
            }
            if(window.innerWidth >= 992){
                setState((prevState)=>({
                    ...prevState,
                    itemToShow: 5,
                    enableSwipe: false
                }));
            }
            if(window.innerWidth >= 768 && window.innerWidth < 992){
                setState((prevState)=>({
                    ...prevState,
                    itemToShow: 3,
                }));
            }
            if(window.innerWidth >= 320 && window.innerWidth < 768){
                setState((prevState)=>({
                    ...prevState,
                    itemToShow: 2,
                }));
            }
        };

        if(window.innerWidth < 992){
            setState((prevState)=>({
                ...prevState,
                enableSwipe: true
            }));
        }
        if(window.innerWidth >= 992){
            setState((prevState)=>({
                ...prevState,
                itemToShow: 5
            }));
        }
        if(window.innerWidth >= 768 && window.innerWidth < 991){
            setState((prevState)=>({
                ...prevState,
                itemToShow: 3
            }));
        }
        if(window.innerWidth >= 320 && window.innerWidth < 768){
            setState((prevState)=>({
                ...prevState,
                itemToShow: 2
            }));
        }


        window.addEventListener('resize', handleResize);

        return ()=>{
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const GetChefs = async()=> {
        const result = await callApi('get_chefs', 'get');
        if(result.status === 200){
            let result_chefs = await Promise.all(
                result.chefs.map(async(chef)=>{
                    let chef_data = {};
                    chef_data.id = chef.id;
                    chef_data.img_carousel = await storage.ref().child(`chefs/${chef.id}/carousel_web_home.png`).getDownloadURL();
                    chef_data.nombre = chef.nombre.split(' ')[0];
                    chef_data.apellido_paterno = chef.apellido_paterno;
                    chef_data.ubicacion = chef.areas_servicio[0].estado;
                    chef_data.review_web_home = chef.review_web_home;
                    return chef_data;
                })
            );
            setState((prevState)=>({
                ...prevState,
                chefs: result_chefs,
                loading: false
            }));
        }else{
            setState((prevState)=>({
                ...prevState,
                loading: false
            }));
        }
    }

    const showReview = (e)=>{
        const chef_id = e.target.getAttribute('data-chef-id');
        gsap.to(`.${chef_id}`, {opacity: 1, top: 0});
    }

    const hideReview = (e)=>{
        const chef_id = e.target.getAttribute('data-chef-id');
        gsap.to(`.${chef_id}`, {opacity: 0, top: '100%'});
    }

    return (
        <>
            <BannerViewAllChefs />
            <AlliedChefsWrapper className='allied-chefs-wrapper'>
                <div className='container'>
                    <Title>Los Chefs aliados mejor calificados</Title>
                    <StyledText 
                        text='Hacemos que tu evento privado salga perfecto. Tú solo disfruta.'
                        fontSize='22px'
                        marginBottom='24px'
                    />
                    <div className='slide-allied-chefs'>
                    {
                        state.chefs.length > 0 &&
                            <Carousel
                                enableSwipe={state.enableSwipe}
                                itemsToShow={state.itemToShow}
                                showArrows={false}
                                outerSpacing={0}
                                itemPosition={consts.START}
                                itemPadding={[0, 5]}
                                focusOnSelect={false}
                            >
                                {
                                    state.chefs.map((chef)=>{
                                        return(    
                                            <ChefAlliedHolder onMouseEnter={showReview} onMouseLeave={hideReview} key={chef.id} data-chef-id={chef.id}>
                                                <ChefAlliedImg src={chef.img_carousel} />
                                                <NameHolder>
                                                    <Name>{`${chef.nombre} ${chef.apellido_paterno}`}</Name>
                                                    <Location>{chef.ubicacion}</Location>
                                                </NameHolder>
                                                <ReviewHolder className={chef.id}>
                                                    <PictureNameHolder>
                                                        <PictureCircleHolder>
                                                            <PictureCircle src={chef.img_carousel} />
                                                        </PictureCircleHolder>
                                                        <Name>{`${chef.nombre} ${chef.apellido_paterno}`}</Name>
                                                    </PictureNameHolder>
                                                    <Review>{chef.review_web_home}</Review>
                                                </ReviewHolder>
                                            </ChefAlliedHolder>  
                                        )
                                    })
                                }
                            </Carousel>
                    }
                    </div>
                </div>
                <Loading visible={state.loading} />
            </AlliedChefsWrapper>
        </>
    )
}

const AlliedChefsWrapper = styled.section`
    margin: 100px 0;
`;

const Title = styled.h2`
    color: ${Colors.blue};
    font-family: 'Montserrat', sans-serif;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 0px;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 18px;
        font-weight: 600;
    }
`;

const ChefAlliedHolder = styled.div`
    border-radius: 18px;
    position: relative;
    overflow: hidden;

    :focus {
        outline: none;
    }
`;

const ChefAlliedImg = styled.img`
    height: 100%;
    object-fit: cover;
    outline: none;
    user-select: none;
    width: 100%;
    pointer-events: none;
`;

const NameHolder = styled.div`
    background-color: rgba(0,0,0,0.6);
    height: 25%;
    left: 0;
    position: absolute;
    pointer-events: none;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Name = styled.p`
    color: ${Colors.white};
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 14px;
    }
`;

const Location = styled.p`
    color: ${Colors.white};
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    text-align: center;

    @media (min_width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 8px;
    }
`;

const ReviewHolder = styled.div`    
    background-color: ${Colors.blueRgba};
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    left: 0;
    opacity: 0;
    padding: 5px;
    position: absolute;
    pointer-events: none;
    top: 100%;
    width: 100%;
    justify-content: center;
`;

const PictureNameHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PictureCircleHolder = styled.div`
    height: 60px;
    border-radius: 50%;
    border: solid 2px ${Colors.mainColor};
    overflow: hidden;
    position: relative;
    width: 60px;
`;

const PictureCircle = styled.img`
    height: 150%;
    object-fit: cover;
    width: 100%;
`;

const Review = styled.p`
    color: ${Colors.white};
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    text-align: center;
    overflow: hidden;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMin}){
        font-size: 10px;
    }
`;

export default AlliedChefs
