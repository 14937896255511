import React from 'react';
import styled from 'styled-components';

const FullOverlay = ({children}) => {

    return (
        <FullOverlayWrapper className='full-overlay-wrapper d-flex align-items-center justify-content-center'>
            {children}
        </FullOverlayWrapper>
    )
}

const FullOverlayWrapper = styled.div`
    position: fixed;
    background-color: rgba(0,0,0,0.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
`;

export default FullOverlay
