import React from 'react';
import styled from 'styled-components';
import ListChefs from '../../organisms/ListChefs';
import TabsSelectChefs from '../../organisms/TabsSelectChefs';

const GroupChefs = () => {

    return (
        <GroupChefsWrapper className='group-chefs-wrapper'>
            <TabsSelectChefs />
            <ListChefs />
        </GroupChefsWrapper>
    )
}

const GroupChefsWrapper = styled.section`
`;

export default GroupChefs
