import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import ChefReviewsHeader from '../../molecules/ChefReviewsHeader'
import ListChefReviews from '../../organisms/ListChefReviews'

const ChefReviews = () => {

    const  {chef} = useSelector(state => state.chefSelected);

    return (
        <>
            {
                chef.evaluaciones &&
                    <ChefReviewsWrapper className='chef-reviews-wrapper'>
                        <ChefReviewsHeader />
                        <ListChefReviews />
                    </ChefReviewsWrapper>
            }
        </>
    )
}

const ChefReviewsWrapper = styled.div`
`;

export default ChefReviews
