import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ChefListItem from '../../molecules/ChefListItem/ChefListItem';
import {setChefSelected} from '../../../redux/slices/chefSelectedSlice';
import { setChef, setUsuario, unsetReservation } from '../../../redux/slices/reservationSlice';
import { store } from '../../../redux/store';

const ListChefs = () => {

    const  {chefs}  = useSelector(state => state.visibleChefs);
    const dispatch = useDispatch();
    const history = useHistory();

    const selectChef = (chef)=> {
        dispatch(unsetReservation());
        dispatch(setChefSelected(chef));
        history.push('/chef_profile');
    }

    return (
        <ListChefsWrapper className='list-chefs-wrapper'>
            <Container>
                <ListChefsHolder className='list-chefs-holder'>
                    <Row>
                        {
                            chefs.map((chef)=>(
                                <Col lg='3' className='mb-5 d-flex justify-content-center'>
                                    <ChefListItem
                                        onCLick={()=>{selectChef(chef)}}
                                        imageUrl={chef.avatar}
                                        promedioRating={chef.promedio_rating}
                                        chefName={`${chef.nombre} ${chef.apellido_paterno}`}
                                        chefLocation={chef.areas_servicio[0].estado}
                                    />
                                </Col>
                            ))
                        }
                    </Row>
                </ListChefsHolder>
            </Container>
        </ListChefsWrapper>
    )
}

const ListChefsWrapper = styled.div`
`;

const ListChefsHolder = styled.div`
`;

export default ListChefs
