import {createSlice} from '@reduxjs/toolkit';

export const chefSelectedSlice = createSlice({
    name: 'chefSelected',
    initialState: {
        chef: undefined
    },
    reducers: {
        setChefSelected: (state, action)=> {
            state.chef = action.payload;
        },
        unsetChefSelected: (state)=> {
            state.chef = undefined;
        }
    },
});

export const {setChefSelected, unsetChefSelected} = chefSelectedSlice.actions;
export default chefSelectedSlice.reducer;