import { createSlice } from "@reduxjs/toolkit";

export const gastronomicStylesSlice = createSlice({
    name: 'gastronomicStyles',
    initialState: {
        allGastronomicStyles: []
    },
    reducers: {
        setGastronomicStyles: (state, action)=> {
            state.allGastronomicStyles = action.payload
        },
        resetGastronomicStyles: (state)=>{
            state.allGastronomicStyles.forEach((gs)=>{
                gs.numero_menus_by_chef = 0;
            });
        },
        changeGastronomicStyles: (state, action)=> {
            action.payload.chefs.forEach((chef)=>{
                chef.menus.forEach((menu)=>{
                    state.allGastronomicStyles.forEach((estiloGastronomico)=>{
                        if(menu.estilos_gastronomicos.includes(estiloGastronomico.nombre)){
                            estiloGastronomico.numero_menus_by_chef++;
                        }
                    });
                });
            });
        }
    }
});

export const {setGastronomicStyles, resetGastronomicStyles, changeGastronomicStyles} = gastronomicStylesSlice.actions;
export default gastronomicStylesSlice.reducer;