import React from 'react'
import styled from 'styled-components'
import Colors from '../theme/Colors';

const MainButtonAnchor = ({text, width, height, fontSize, href, target, onClick}) => {
    return (
        <Button href={href} onClick={onClick} target={target} width={width} height={height} fontSize={fontSize}>{text}</Button>
    )
}

const Button = styled.a`
    background-color: ${Colors.mainColor};
    color: ${Colors.white};
    border: 0;
    border-radius: 32px;
    font-family: 'Monyserrat', sans-serif;
    font-size: ${props => props.fontSize ? props.fontSize : '0.8em'};
    font-weight: bold;
    padding: 10px;
    width: ${props => props.width && props.width};
    height: ${props => props.height && props.height};
    
    :focus {
        outline: none;
    }
    :hover{
        text-decoration: none;
    }
    :active{
        color: ${Colors.white};
    }
    :visited{
        color: ${Colors.white};
    }
`;

export default MainButtonAnchor
