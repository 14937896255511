import React from 'react';
import {Redirect, Route} from 'react-router';

const ProtectedRoute = ({component: Component, ...restOfProps}) => {

    const userLogged = localStorage.getItem('userLogged');

    return (
        <Route 
            {...restOfProps}
            render={(props)=>
                userLogged ? <Component {...props} /> : <Redirect to='/' />
            }
        />
    )
}

export default ProtectedRoute;
