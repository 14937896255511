import React from 'react';
import styled from 'styled-components';
import Colors from '../../../theme/Colors';
import {IoCard, IoTrash, IoCheckmarkCircle} from 'react-icons/io5';
import StyledText from '../../atoms/typography/StyledText';

const CardItem = ({
    onClick,
    card_name, 
    card_number,
    onClickTrash,
    selected
}) => {
    return (
        <CardItemWrapper onClick={onClick} className='card-item-wrapper'>
            <CardItemHolder className='card-item-holder d-flex align-items-center'>
                <div className='mr-4'>
                    <IoCard color={Colors.mainColor} size={32} />
                </div>
                <CardInfoWrapper>
                    <StyledText text={card_name} />
                    <StyledText text={card_number} color={Colors.gray} />
                </CardInfoWrapper>
                {
                    selected ?
                        <IoCheckmarkCircle color={Colors.succes} size={24} />
                    :
                        <IconTrash onClick={onClickTrash} color={Colors.mainColor} size={24} />
                }
            </CardItemHolder>
        </CardItemWrapper>
    )
}

const CardItemWrapper = styled.div`
    margin-bottom: 16px;
    cursor: pointer;
`;

const CardItemHolder = styled.div`
    background: ${Colors.grayInputForm};
    border-radius: 16px;
    padding: 8px;
`;

const CardInfoWrapper = styled.div`
    pointer-events: none;
    flex: 1;
`;

const IconTrash = styled(IoTrash)`
    cursor: pointer;
`;

export default CardItem
