import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import UserAvatar from '../../atoms/UserAvatar';
import {IoPersonCircle} from 'react-icons/io5';
import Colors from '../../../theme/Colors';
import StyledText from '../../atoms/typography/StyledText';

const UserAvatarInfo = () => {

    const {userData} = useSelector(state => state.userLogged);

    return (
        <UserAvatarInfoWrapper className='user-avatar-info-wrapper d-flex align-items-center'>
            <UserAvatarImageWrapper className='user-avatar-image-wrapper'>
                {
                    userData.avatar ?
                        <UserAvatar userImage={userData.avatar} />
                    :
                        <IoPersonCircle color={Colors.gray}  size={50} />
                }
            </UserAvatarImageWrapper>
            <div>
                <StyledText 
                    text={`${userData.nombre} ${userData.apellidos || userData.apellido_paterno}`} 
                    fontWeight='bold'
                />
                <StyledText 
                    text='Anfitrión' 
                    color={Colors.gray}
                />
            </div>
        </UserAvatarInfoWrapper>
    )
}

const UserAvatarInfoWrapper = styled.div``;

const UserAvatarImageWrapper = styled.div`
    margin-right: 8px;
`;

export default UserAvatarInfo
