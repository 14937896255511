import React from 'react'
import styled from 'styled-components'
import Colors from '../../../theme/Colors';
import CloseIcon from '../../atoms/CloseIcon';
import Logo from '../../atoms/Logo';
import TextInputItem from '../../molecules/TextInputItem/TextInputItem';
import useRegisterModal from './hooks/useRegisterModal';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import StyledText from '../../atoms/typography/StyledText';
import { countryCodes } from '../../../fixtures/countriesPhone';
import MainButton from '../../molecules/MainButton';
import FullOverlay from '../../atoms/FullOverlay';
import Loading from '../../../components/Loading';

const RegisterModal = ({closeRegisterModal}) => {

    const {
        loading,
        handleNombre, 
        handleApellidos,
        handleEmail, 
        handleCelular,
        handlePassword,
        handleTerminos,
        handleRegister,
        handleCodeUser,
        handleVerifyCode,
        nombre, 
        apellidos,
        email,
        celular,
        password,
        aceptaTerminos,
        codeSended,
        codeUser,
    } = useRegisterModal(closeRegisterModal);    

    return (
        <>
            <FullOverlay>
                <RegisterModalWrapper className='register-modal-wrapper'>
                    <RegisterModalHolder className='register-modal-holder'>
                        <CloseIconWrapper className='d-flex justify-content-end'>
                            <CloseIcon onClick={closeRegisterModal}/>
                        </CloseIconWrapper>
                        <div className='d-flex flex-column align-items-center'>
                            <LogoWrapper>
                                <Logo
                                    width={160}
                                    height={40}
                                />
                            </LogoWrapper>
                            {
                                codeSended ?
                                    <>
                                        <TextInputItem
                                            text='Ingresa el código que has recibido.'
                                            value={codeUser}
                                            onChange={handleCodeUser}
                                        />
                                        <MainButton
                                            backGroundColor='transparent' 
                                            text='Verificar código' 
                                            color={Colors.black} onClick={handleVerifyCode} 
                                            border={`solid 1px ${Colors.grayBorder}`}
                                        />
                                    </>
                                :
                                    <>
                                        <TextInputItem
                                            width='240px'
                                            text='Nombre'
                                            value={nombre}
                                            onChange={handleNombre}
                                        />
                                        <TextInputItem
                                            width='240px'
                                            text='Apellidos'
                                            value={apellidos}
                                            onChange={handleApellidos}
                                        />
                                        <TextInputItem
                                            width='240px'
                                            text='Email'
                                            value={email}
                                            onChange={handleEmail}
                                        />
                                        <TextInputItem
                                            width='240px'
                                            text='Celular'
                                        >
                                            <PhoneInput
                                                onlyCountries={countryCodes}
                                                preferredCountries={['mx']}
                                                country='mx'
                                                value={celular}
                                                onChange={celular => {handleCelular(celular)}}
                                                inputStyle={{border: '0', background: Colors.grayInputForm, width: '100%'}}
                                                buttonStyle={{border: '0', background: Colors.grayInputForm}}
                                            />
                                        </TextInputItem>
                                        <TextInputItem
                                            text='Contraseña'
                                            value={password}
                                            onChange={handlePassword}
                                            icon
                                        />
                                        <AgreeTermsWrapper className='d-flex align-items-center'>
                                            <Checkbox checked={aceptaTerminos} type='checkbox' onChange={handleTerminos} />
                                            <a href='https://firebasestorage.googleapis.com/v0/b/kaichefapp.appspot.com/o/legal%2Fterminos_condiciones_kaichef.pdf?alt=media&token=ff49e69f-20a4-46ae-8799-563845958cdf' target='_blank' rel='noreferrer'>
                                                <StyledText text='Términos y Condiciones y Aviso de privacidad' fontSize='12px' color={Colors.gray} />
                                            </a>
                                        </AgreeTermsWrapper>
                                        <MainButton
                                            backGroundColor='transparent' 
                                            text='Registrarme' 
                                            color={Colors.black} onClick={handleRegister} 
                                            border={`solid 1px ${Colors.grayBorder}`}
                                        />
                                    </>
                            }
                        </div>
                    </RegisterModalHolder>
                </RegisterModalWrapper>
            </FullOverlay>
            <Loading visible={loading} />
        </>
    )
}

const RegisterModalWrapper = styled.div`
    width: 350px;
`;

const RegisterModalHolder = styled.div`
    background: ${Colors.white};
    border-radius: 45px;
    padding: 20px;
    padding-bottom: 40px;
`;

const LogoWrapper = styled.div`
    margin-bottom: 12px;
`;

const CloseIconWrapper = styled.div`
`;

const AgreeTermsWrapper = styled.div`
    margin-bottom: 35px;
`;

const Checkbox = styled.input`
    margin-right: 12px;
`;

export default RegisterModal
