import React from 'react';
import styled from 'styled-components';
import MenuTimeItem from '../../molecules/MenuTimeItem';

const ListMenuTimes = ({tiempos}) => {

    return (
        <ListMenuTimesWrapper className='list-menu-times-wrapper'>
            {
                tiempos.map((tiempo, index)=>(
                    <MenuTimeItem
                        nombrePlatillo={tiempo.nombre_platillo}
                        descripcion={tiempo.descripcion}
                        opciones={tiempo.opciones}
                        index={index}
                    />
                ))
            }
        </ListMenuTimesWrapper>
    )
}

const ListMenuTimesWrapper = styled.div``;

export default ListMenuTimes
