import React, { useState } from 'react'
import styled from 'styled-components'
import Colors from '../../theme/Colors';
import Images from '../../images/Images';
import ItemEnjoy from './ItemEnjoy';
import {Tween} from 'react-gsap';
import { screenSizes } from '../../fixtures/ScreenSizes';

const EnjoyChef = () => {

    const [imageEnjoy, setImageEnjoy] = useState('anfitrion')

    const changeImagesEnjoy = (e)=> {
        const item = e.target.parentElement.getAttribute('data-item');
        setImageEnjoy(item);
    }

    return (
        <EnjoyChefWarpper className='container enjoy-chef'>
            <div className='row'>
                <div className='col-12 col-md-12 col-lg-6'>
                    <EnjoyContentHolder>
                        
                        <Title>Disfruta de tu chef favorito en casa</Title>

                        <ImgEnjoyChefMobile className='img-fluid mb-4 d-lg-none' src={Images.imgEnjoyChefMobile} />
                        
                        <ItemEnjoy
                            title='Sé el anfitrión perfecto'
                            image={Images.anfitrionPerfecto} 
                            description='¿Buscas algo nuevo? ¡Vive nuevas experiencias y deléitate con sabores que nunca imaginaste! Convierte cualquier momento en inolvidable.'
                            item='anfitrion'
                            onMouseOver={changeImagesEnjoy}
                        />

                        <ItemEnjoy 
                            title='Platillos de autor'
                            image={Images.iconPlatillosAutor} 
                            description='Nuestros Chefs son expertos y apasionados en lo que hacen. Cada uno libera sabores únicos, llevándote de viaje en cada tiempo.' 
                            item='platillos'
                            onMouseOver={changeImagesEnjoy}
                        />

                        <ItemEnjoy 
                            title='Sabores para todos'
                            image={Images.iconSaboresParaTodos} 
                            description='Tu estilo de vida es lo más importante para nosotros. Queremos que disfrutes de una experiencia gastronómica única, por eso personalizamos tu menú basándonos en las restricciones alimenticias que nos indiques.' 
                            item='sabores'
                            onMouseOver={changeImagesEnjoy}
                        />
                    </EnjoyContentHolder>
                </div>
                <div className='col-12 col-md-12 col-lg-6'>
                    {
                        imageEnjoy === 'anfitrion' &&
                            <EnjoyImageHolder className='enjoy-image-holder'>
                                <Tween to={{top: 0, opacity: 1}} ease={"elastic.out(0.1, 0.1)"}>
                                    <EnjoyImage src={Images.anfitrionPerfectoImg}/>
                                </Tween>
                                <Tween to={{top: '8%', opacity: 1}} ease={"elastic.out(0.1, 0.1)"} duration={1}>
                                    <EnjoyImageCel src={Images.anfitrionPerfectoCel} />
                                </Tween>
                            </EnjoyImageHolder>
                    }
                    {
                        imageEnjoy === 'platillos' &&
                            <EnjoyImageHolder className='enjoy-image-holder'>
                                <Tween to={{top: 0, opacity: 1}} ease={"elastic.out(0.1, 0.1)"}>
                                    <EnjoyImage src={Images.platillosImg} />
                                </Tween>
                                <Tween to={{top: '8%', opacity: 1}} ease={"elastic.out(0.1, 0.1)"} duration={1}>
                                    <EnjoyImageCel src={Images.platillosCel} />
                                </Tween>
                            </EnjoyImageHolder>
                    }
                    {
                        imageEnjoy === 'sabores' &&
                            <EnjoyImageHolder className='enjoy-image-holder'>
                                <Tween to={{top: 0, opacity: 1}} ease={"elastic.out(0.1, 0.1)"}>
                                    <EnjoyImage src={Images.saboresImg} />
                                </Tween>
                                <Tween to={{top: '8%', opacity: 1}} ease={"elastic.out(0.1, 0.1)"} duration={1}>
                                    <EnjoyImageCel src={Images.saboresCel} />
                                </Tween>
                            </EnjoyImageHolder>
                    }
                </div>
            </div>
        </EnjoyChefWarpper>
    )
}

const EnjoyChefWarpper = styled.section`
    
`;

const EnjoyContentHolder = styled.div`
    flex: 1;
`;

const Title = styled.h4`
    color: ${Colors.blue};
    font-family: 'Montserrat', sans-serif;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 70px;
    
    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 30px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        margin-bottom: 30px;
        width: 70%;
    }
`; 

const ImgEnjoyChefMobile = styled.img`
    
`;

const EnjoyImageHolder = styled.div`
    height: 100%;
    min-height: 640px; 
    position: relative;
    display: none;

    @media (min-width: ${screenSizes.large}) {
        display: block;
    }
`;

const EnjoyImageCel = styled.img`
    height: 507px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 100%;
    width: 261px;  

    @media (min-width: ${screenSizes.large}) and (max-width: ${screenSizes.xLarge}) {
        height: 450px;
        width: 250px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {    
        height: 350px;
        width: 195px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        height: 175px;
        width: 97px;
    }
`;

const EnjoyImage = styled.img`
    height: 608px;
    right: 0;
    opacity: 0;
    position: absolute;
    top: -100%;
    width: 392px;

    @media (min-width: ${screenSizes.large}) and (max-width: ${screenSizes.xLarge}) {
        height: 525px;
        width: 325px;
    }

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.large}) {
        height: 525px;
        width: 325px;
    }

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        height: 460px;
        width: 300px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        height: 230px;
        width: 150px;
    }
`;

export default EnjoyChef