import React from 'react';
import styled from 'styled-components';
import { screenSizes } from '../../fixtures/ScreenSizes';
import Colors from '../../theme/Colors';

const ItemWork = ({image, title, description, className, onMouseEnter, onMouseOut, item}) => {

    return (
        <ItemWorkWrapper className={className} onMouseEnter={onMouseEnter} onMouseOut={onMouseOut}>
            <div className='row'>
                <div className='col-3 col-sm-2 col-md-12 mb-3 mb-sm-0 d-md-flex justify-content-md-center' data-item={item}>
                    <Image className={className} src={image}/>
                </div>
                <div className='col-9 col-sm-10 col-md-12 mb-3 mb-sm-0 d-md-flex align-items-md-center flex-md-column'>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </div>
            </div>
        </ItemWorkWrapper>
    )
}   

const Title = styled.h4`
    color: ${Colors.blue};
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 12px;
    pointer-events: none;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 13px;
        margin-bottom: 0px;
    }
`;

const ItemWorkWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const Image = styled.img`
    height: 100px;
    width: 100px;
    margin-bottom: 18px;
    pointer-events: none;
    object-fit: contain;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        width: 60px;
        height: 60px;
    }
`;

const Description = styled.p`
    color: ${Colors.black};
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    text-align: center;
    pointer-events: none;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 13px;
        text-align: left;
    }
`;

export default ItemWork
