import React, { useEffect } from 'react';
import styled from 'styled-components';
import { screenSizes } from '../../fixtures/ScreenSizes';
import Images from '../../images/Images';
import Colors from '../../theme/Colors';
import Header from './Header';
import ItemCustomProfile from './ItemCustomProfile';
import { Timeline } from 'rsuite';
import TimeLineNumberItem from './TimeLineNumberItem';
import './time_line.css';

const AlliedChef = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <>
            <Header />
            <section className='container mb-5 work-private-chef-holder'>
                <div className='row align-items-center justify-content-center'>
                    <div className='col-7 col-md-4 offset-lg-0 mb-4 mb-md-0'>
                        <img src={Images.imgWorkLikeChef} className='img-fluid' alt='trabaja_como_chef_privado' />
                    </div>
                    <div className='col-md-7 col-lg-6'>
                        <Title className='mb-3'>Trabaja como chef privado en tu ciudad</Title>
                        <Text>Innova y brinda una experiencia gastronómica única a tu comensal. Para dar un servicio de excelencia, recuerda seleccionar la vajilla y cristalería ideal para cada servicio; eso hará que tus platillos brillen aún más.</Text>
                    </div>
                </div>
            </section>
            
            <CulinaryExperienceWrapper className='container-fluid'>
                <CulinaryExperience className='container py-4 culinary-experience d-flex flex-column justify-content-center'>
                    <div className='row mb-3 mb-3 mb-md-0'>
                        <div className='col-8 col-md-6 col-lg-5'>
                            <TitleCulinary className='mb-md-5'>Crea experiencias culinarias únicas</TitleCulinary>
                        </div>
                    </div>
                    <div className='row d-md-none mb-3'>
                        <div className='col-12'>
                            <img className='img-fluid d-md-none' src={Images.imgCulinaryExperienceMobile} alt='nueva_experiencia_culinaria' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 col-lg-5'>
                            <TextWhite>¡Muestra tu lado más creativo! Dentro de un mismo estilo gastronómico, desarrolla una o más propuestas de menú tipo degustación en tiempos.</TextWhite>
                        </div>
                    </div>
                </CulinaryExperience>
            </CulinaryExperienceWrapper>
            
            <BenefitsWrapper className='benefits-wrapper mb-5 py-5'>
                <div className='container'>
                    <BenefitsHolder className='row benefits-holder'>
                        <div className='d-lg-none mb-5 col-12 justify-content-center'>
                            <TitleBenefits mobile >Beneficios como KaiChef Aliado</TitleBenefits>
                        </div>
                        <div className='col-lg-3 mb-5 mb-lg-0 d-flex justify-content-center'>
                            <ImgAppLogin src={Images.imgAppLogin} alt='aplicacion_movil_kaichef' />
                        </div>
                        <div className='col-md-6 d-lg-none'>
                            <div className='timeline-benefits-wrapper'>
                                <Timeline className='custom-timeline'>
                                    <Timeline.Item dot={<TimeLineNumberItem number={1} />}>
                                        <TitleTimeLine>Registro Gratis</TitleTimeLine>
                                        <TextTimeLine>No tiene costo registrarse como Chef Aliado</TextTimeLine>
                                    </Timeline.Item>
                                    <Timeline.Item dot={<TimeLineNumberItem number={2} />}>
                                        <TitleTimeLine>Configuración de servicio</TitleTimeLine>
                                        <TextTimeLine>Define el número de servicios y comensales al día que desees atender.</TextTimeLine>
                                    </Timeline.Item>
                                    <Timeline.Item dot={<TimeLineNumberItem number={3} />}>
                                        <TitleTimeLine>Libertad</TitleTimeLine>
                                        <TextTimeLine>Podrás diseñar tu menú y poner el precio que tu consideres.</TextTimeLine>
                                    </Timeline.Item>
                                    <Timeline.Item dot={<TimeLineNumberItem number={4} />}>
                                        <TitleTimeLine>Creatividad</TitleTimeLine>
                                        <TextTimeLine>Tú decides el estilo gastronómico de tu propuesta de menú.</TextTimeLine>
                                    </Timeline.Item>
                                    <Timeline.Item dot={<TimeLineNumberItem number={5} />}>
                                        <TitleTimeLine>Independencia</TitleTimeLine>
                                        <TextTimeLine>Puedes elegir la ciudad parar brindar servicio y configurar tus horarios de atención.</TextTimeLine>
                                    </Timeline.Item>
                                    <Timeline.Item dot={<TimeLineNumberItem number={6} />}>
                                        <TitleTimeLine>Ingresos</TitleTimeLine>
                                        <TextTimeLine>Aumenta tus ganancias dependiendo del número de servicios que brindes en la plataforma.</TextTimeLine>
                                    </Timeline.Item>
                                </Timeline>
                            </div>
                        </div>
                        <div className='d-none d-lg-block col-lg-9'>
                            <TitleBenefits className='mb-5'>Beneficios como KaiChef Aliado</TitleBenefits>
                            <div className='row mb-sm-5'>
                                <div className='col-sm-4 mb-3 mb-sm-0'>
                                    <SubTitleBenefit className='mb-1'>GRATIS</SubTitleBenefit>
                                    <TextBenefit>No tiene costo registrarte como Chef Aliado.</TextBenefit>
                                </div>
                                <div className='col-sm-4 mb-3 mb-sm-0'>
                                    <SubTitleBenefit className='mb-1'>LIBERTAD</SubTitleBenefit>
                                    <TextBenefit>Podrás diseñar tu menú y poner el precio que tú consideres.</TextBenefit>
                                </div>
                                <div className='col-sm-4 mb-3 mb-sm-0'>
                                    <SubTitleBenefit className='mb-1'>Creatividad</SubTitleBenefit>
                                    <TextBenefit>Tú decides el estilo gastronómico de tu propuesta de menú.</TextBenefit>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4 mb-3 mb-sm-0'>
                                    <SubTitleBenefit className='mb-1'>Servicio</SubTitleBenefit>
                                    <TextBenefit>Define el número de servicios y comensales al día que desees atender.</TextBenefit>
                                </div>
                                <div className='col-sm-4 mb-3 mb-sm-0'>
                                    <SubTitleBenefit className='mb-1'>Alcance</SubTitleBenefit>
                                    <TextBenefit>Puedes elegir la ciudad para brindar servicio y configurar tus horarios.</TextBenefit>
                                </div>
                                <div className='col-sm-4 mb-3 mb-sm-0'>
                                    <SubTitleBenefit className='mb-1'>Ingresos</SubTitleBenefit>
                                    <TextBenefit>Aumenta tus ganancias dependiendo del número de servicios que brindes.</TextBenefit>
                                </div>
                            </div>
                        </div>
                    </BenefitsHolder>
                </div>
            </BenefitsWrapper>
            
            <CustomProfileWrapper className='container'>
                <div className='row'>
                    <div className='col-6 col-md-12'>
                        <TitleBenefits className='mb-5'>Personaliza tu perfil como Chef Privado</TitleBenefits>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <ItemCustomProfile
                            img={Images.iconGanancias} 
                            imageWidth={'71px'}
                            imageHeight={'88px'}
                            title='Ganancias' 
                            text='Como Chef Aliado, tienes capacidad de definir el precio de tu menú. En KaiChef no ponemos un máximo de ganancias que puedas obtener: estas varían dependiendo del número de servicios que realices durante el mes.' 
                        />
                    </div>
                    <div className='col-lg-4'>
                        <ItemCustomProfile
                            img={Images.iconDisponibilidad} 
                            imageWidth={'89px'}
                            imageHeight={'89px'}
                            title='Disponibilidad' 
                            text='Sabemos la importancia de tu tiempo y que el transportarse en la ciudad puede convertirse en un caos. Por eso, tú decides las ciudades o localidades en las que deseas brindar tu servicio como Chef privado.' 
                        />
                    </div>
                    <div className='col-lg-4'>
                        <ItemCustomProfile 
                            img={Images.iconAlcance} 
                            imageWidth={'68px'}
                            imageHeight={'89px'}
                            title='Alcance' 
                            text='Queremos que tengas libertad absoluta acerca de tus horarios, número de comensales por servicio y número de servicios diarios. En KaiChef no hay un mínimo de comensales o de servicios que debas dar.' 
                        />
                    </div>
                </div>
            </CustomProfileWrapper>

            <HowAlliedChef className='container how-allied-chef'>
                <div className='mb-5'>
                    <TitleBenefits className='mt-5 mb-4'>¿Cómo ser Chef Aliado?</TitleBenefits>
                </div>
                <div className='row mb-1 mb-md-5'>
                    <div className='col-lg-1 offset-lg-2'>
                        <ImgStepHowAlliedChef alt='como_ser_chef_aliado' className='mb-4 mb-lg-0' src={Images.imgStep1HowAlliedChef} imageWidth={'54px'} imageHeight={'70px'} />
                    </div>
                    <div className='col-lg-7'>
                        <TitleStepHowAlliedChef>Descarga tu formato</TitleStepHowAlliedChef>
                        <TextStepHowAlliedChef>Ingresando tus datos correspondientes (correo, nombre completo y teléfono) tendrás acceso al formato que deberás completar para ser Chef privado a domicilio.</TextStepHowAlliedChef>
                    </div>
                </div>
                <div className='row mb-1 mb-md-5'>
                    <div className='col-lg-1 offset-lg-2'>
                        <ImgStepHowAlliedChef alt='como_ser_chef_aliado' className='mb-4 mb-lg-0' src={Images.imgStep2HowAlliedChef} imageWidth={'54px'} imageHeight={'70px'} />
                    </div>
                    <div className='col-lg-7'>
                        <TitleStepHowAlliedChef>Llena tu formato de registro</TitleStepHowAlliedChef>
                        <TextStepHowAlliedChef>Es importante que sigas las instrucciones para completar tu registro correctamente. No olvides incluir tu trayectoria, el diseño de tu menú y el precio por comensal que desees asignarle.</TextStepHowAlliedChef>
                        <TextStepHowAlliedChef>Si tienes alguna duda referente al llenado del registro, escríbenos a aliado@kaichef.com o envíanos un WhatsApp al (55) 7853 3785, donde nuestro Departamento de Integración te brindará la ayuda necesaria.</TextStepHowAlliedChef>
                    </div>
                </div>
                <div className='row mb-1 mb-md-5'>
                    <div className='col-lg-1 offset-lg-2'>
                        <ImgStepHowAlliedChef alt='como_ser_chef_aliado' className='mb-4 mb-lg-0' src={Images.imgStep3HowAlliedChef} imageWidth={'83px'} imageHeight={'41px'} />
                    </div>
                    <div className='col-lg-7'>
                        <TitleStepHowAlliedChef>Envía tus documentos</TitleStepHowAlliedChef>
                        <TextStepHowAlliedChef>Finalmente, debes enviar los siguientes documentos digitalizados y adjuntar el formato de registro debidamente llenado al correo aliado@kaichef.com</TextStepHowAlliedChef>
                        <TextStepHowAlliedChef>El nombre del asunto del correo que envíes debe ser: SOLICITUD ALTA_NOMBRE COMPLETO</TextStepHowAlliedChef>
                        <TextStepHowAlliedChef>Por ejemplo: <HightlightBlack>SOLICITUD ALTA_GABRIELA GUTIÉRREZ LÓPEZ</HightlightBlack></TextStepHowAlliedChef>
                        <TextStepHowAlliedChef>Documentos digitalizados que deberán estar adjuntos:</TextStepHowAlliedChef>
                        <ListDocuments>
                            <li>Identificación Oficial IFE/INE (por ambos lados)</li>
                            <li>Comprobante de domicilio</li>
                            <li>Curriculum</li>
                            <li>Fotografía</li>
                        </ListDocuments>
                        <TextStepHowAlliedChef>Una vez que tu proceso haya concluido nos comunicaremos contigo en un tiempo no mayor a 48 horas.</TextStepHowAlliedChef>
                    </div>
                </div>
            </HowAlliedChef>

            <NeedHelpWrapper className='need-help-wrapper py-5'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='mb-4 col-lg-5 mb-lg-0'>
                            <TitleBenefits mobile>¿Necesitas ayuda?</TitleBenefits>
                        </div>
                        <div className='col-lg-7'>
                            <Text white>Si tienes algún problema o duda durante tu proceso escríbenos a <strong>aliado@kaichef.com</strong> o comunícate a soporte al <strong>(55) 7853 3785</strong>, y con mucho gusto te ayudaremos.</Text>
                        </div>
                    </div>
                </div>
            </NeedHelpWrapper>
        </>
    )
}

const Title = styled.h3`
    font-size: 35px;
    font-family: 'Montserrat', sans-serif;
    color: ${Colors.blue};
    font-weight: bold;

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 20px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
    }
`;

const Text = styled.p`
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    color: ${props => props.white ? Colors.white : Colors.blue};

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 15px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 12px;
    }
`;

const CulinaryExperienceWrapper = styled.section`
    background: ${Colors.black};
`; 

const CulinaryExperience = styled.div`
    background: url(${Images.bgCulinaryExperience}) top center no-repeat;
    background-size: cover;
    min-height: 427px;

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        background: url(${Images.bgCulinaryExperience}) top right no-repeat;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        background: ${Colors.black};
        min-height: unset;
    }
`;

const TitleCulinary = styled.h3`
    font-size: 35px;
    font-family: 'Montserrat', sans-serif;
    color: ${Colors.white};
    font-weight: bold;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        font-size: 18px;
        font-weight: 600;
    }
`;

const TextWhite = styled.p`
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    color: ${Colors.white};

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        font-size: 14px;
    }
`;

const BenefitsWrapper = styled.section`
    background: ${Colors.grayBenefitsHolder};

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        background: ${Colors.blue};
    }
`;

const TitleBenefits = styled.h3`
    font-size: 35px;
    font-family: 'Montserrat', sans-serif;
    color: ${props => props.mobile ? Colors.white : Colors.blue};
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
    }
`;

const BenefitsHolder = styled.div`
    min-height: 440px;

    @media (min-width: ${screenSizes.large}) and (max-width: ${screenSizes.largeMax}){
        min-height: 352px;
    }
`;

const ImgAppLogin = styled.img`
    height: 406px;
    width: 208px;

    @media (min-width: ${screenSizes.large}) and (max-width: ${screenSizes.largeMax}){
        height: 421px;
        width: 222px;
    }

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}){
        height: 463px;
        width: 244px;
    }
`;

const SubTitleBenefit = styled.div`
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    color: ${Colors.blue};
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
`;

const TextBenefit = styled.p`
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    color: ${Colors.black};
    text-align: center;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        font-size: 14px;
    }
`;

const TitleTimeLine = styled.p`
    font-weight: bold;
    font-size: 14px;
    color: ${Colors.white};
    font-family: 'Montserrat', sans-serif;
`;

const TextTimeLine = styled.p`
    font-size: 12px;
    color: ${Colors.white};
    font-family: 'Montserrat', sans-serif;
`;

const CustomProfileWrapper = styled.section`

`;

const HowAlliedChef = styled.section`

`;

const TitleStepHowAlliedChef = styled.h5`
    color: ${Colors.blue};
    font-size: 24px;
    font-weight: bold;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        font-size: 14px;
    }
`;

const ImgStepHowAlliedChef = styled.img`
    width: ${props => props.imageWidth};
    height: ${props => props.imageHeight};
    object-fit: contain;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        width: 64px;
        height: 42px;
    }
`;

const TextStepHowAlliedChef = styled.p`
    font-size: 20px;
    color: ${Colors.blue};
    margin-bottom: 24px;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        font-size: 12px;
    }
`;

const ListDocuments = styled.ul`
    font-size: 20px;
    list-style: none;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}){
        font-size: 12px;
    }

    li{
        display: flex;
        margin-bottom: 8px;
        align-items: center;
    }

    li:before{
        content: "";
        display: inline-block;
        height: 11px;
        width: 15px;
        margin-right: 7px;
        background-color: ${Colors.mainColor};
        mask-image: url(${Images.iconCheckListMain});
        mask-size: contain;
    }
`;

const HightlightBlack = styled.span`
    font-weight: bold;
    color: ${Colors.black};
`;

const NeedHelpWrapper = styled.section`
    background: ${Colors.blue};
`;

export default AlliedChef
