import React from 'react'
import styled from 'styled-components'
import Colors from '../../../theme/Colors'
import ImageAvatar from '../../atoms/ImageAvatar'
import StyledText from '../../atoms/typography/StyledText'

const ChefReviewItem = ({imgAvatar, commensalName, review}) => {
    return (
        <ChefReviewItemWrapper className='chef-review-item-wrapper d-flex align-items-center'>
            <ImageAvatar srcImage={imgAvatar}  />
            <InforReviewWrapper className='info-review-wrapper'>
                <StyledText text={commensalName} />
                <StyledText text={review} color={Colors.gray} />
            </InforReviewWrapper>
        </ChefReviewItemWrapper>
    )
}

const ChefReviewItemWrapper = styled.div``;

const InforReviewWrapper = styled.div`
`;

export default ChefReviewItem
