const Colors = {
    mainColor: '#FC1052',
    blue: '#011E50',
    blueRgba: 'rgba(1, 30, 80, 0.9)',
    yelow: '#fec11f',
    orange: '#fa7e06',
    grayBgMenu: '#F0F0F0',
    gray: '#7f7f7f',
    lightGray: '#f8f8f8',
    white: '#ffffff',
    grayParagraph: '#626262',
    grayBorder: '#707070',
    black: '#000',
    grayFooter: '#494D53',
    grayInputForm: '#EDEDED',
    grayTextInput: '#AAAAAA',
    grayBenefitsHolder: '#DDDDDD',
    blueInputContact: '#73A1C2',
    bgAccountScreen: '#F8F8F8',
    bluFb: '#1852EF',
    succes: '#25CE92',
    grayPaymentMethod: '#ECEDF1',
    grayBorderB: '#D2D2D3',
    boxShadow: '#8798ad;'
}

export default Colors;