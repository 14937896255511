import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import swal from 'sweetalert';
import { ValidateEmail } from "../../../../fixtures/helpers";
import { callApi } from "../../../../fixtures/api";
import md5 from 'md5';
import { setUserLogged } from "../../../../redux/slices/userLoggedSlice";
import { auth } from '../../../../fixtures/firebase';
import { config } from "../../../../fixtures/config";
import jwt_decode from "jwt-decode";

const useLoginModal = (closeLoginModal) => {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showCelphoneForm, setShowCelphoneForm] = useState(false);
    const [celular, setCelualr] = useState('');
    const [codeSended, setCodeSended] = useState('');
    const [codeUser, setCodeUser] = useState('');
    const [emailProvider, setEmailProvider] = useState('');
    const [nameProvider, setNameProvider] = useState('');
    const [lastNameProvider, setLastNameProvider] = useState('');
    const [userData, setUserData] = useState('');
    const dispacth = useDispatch();

    useEffect(() => {
        window.AppleID.auth.init({
            clientId : 'com.kaichef.auth.web',
            scope : 'name email',
            redirectURI : config.openpayIsProduction ? 'https://kaichef.com' : 'https://geekcode.com:3000',
            state : 'state',
            nonce : 'nonce',
            usePopup : true
        });
        const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
        //Listen for authorization success
        document.addEventListener('AppleIDSignInOnSuccess', AppleIDSignInOnSuccess);
        //Listen for authorization failures
        document.addEventListener('AppleIDSignInOnFailure', AppleIDSignInOnFailure);
        return ()=>{
            subscriber();
            document.removeEventListener('AppleIDSignInOnSuccess', AppleIDSignInOnSuccess);
            document.removeEventListener('AppleIDSignInOnFailure', AppleIDSignInOnFailure);
        };
    }, []);

    //registrar usuario nuevo 
    const RegisterSocialUserExecute = async(data)=> {
        const result = await callApi('registro_usuario_social', 'post', data || userData);
        if(result.status == 200){
            Auth(result.usuario, true);
        }else{
            setLoading(false);
            swal({
                text: result.message,
                icon: 'warning'
            });
        }
    }

    const RegisterSocialUser = async(user, facebook, appleLoginAgain)=> {
        let passMd5;
        let data;
        if(appleLoginAgain){
            data = {
                "email": user.email,
                "provider": 'apple'
            };
            RegisterSocialUserExecute(data);
            return;
        }
        if(facebook){
            passMd5 = md5('loginWhithFacebook');
            data = {
                "nombre": user.name.split(' ')[0],
                "apellidos": user.name.split(' ')[1] || '',
                "email": user.email,
                "avatar": user.picture.data.url ? user.picture.data.url : '',
                "id_facebook": user.id,
                "provider": 'facebook',
                "password": passMd5,
                "interno": !config.productionEnviroment
            };
        }
        if(!facebook && !appleLoginAgain){
            passMd5 = md5('loginWhithApple');
            data = {
                "nombre": user.firstName,
                "apellidos": user.lastName,
                "email": user.email,
                "avatar": '',
                "provider": 'apple',
                "password": passMd5,
                "interno": !config.productionEnviroment
            };
        }
        
        setUserData(data);

        const result_validate_user_exist = await callApi('validate_user_exist_social', 'post', data);
        if(result_validate_user_exist.status === 200){
            if(result_validate_user_exist.empty){
                setShowCelphoneForm(true);
                setLoading(false);
            }else{
                RegisterSocialUserExecute(data);
            }
        }else{
            setLoading(false);
            swal({
                text: result_validate_user_exist.message,
                icon: 'warning'
            });
        }
    }

    const AppleIDSignInOnSuccess = (data)=> {
        setLoading(true);
        const authorization = data.detail.authorization;
        const id_token = authorization.id_token;
        
        let user;
        let email;
        let name;
        let firstName;
        let lastName;
        
        if(data.detail.user){
            user = data.detail.user;
            email = user.email;
            name = user.name;
            firstName = name.firstName;
            lastName = name.lastName;
            setEmailProvider(email);
            setNameProvider(firstName);
            setLastNameProvider(lastName);
        }
        if(!email){
            const userData = jwt_decode(id_token);
            RegisterSocialUser(userData, false, true);
        }else{
            const userData = {
                email,
                firstName,
                lastName
            };
            RegisterSocialUser(userData, false, false);
        }
        
    }

    const AppleIDSignInOnFailure = (error)=> {
        if(error.detail.error !== 'popup_closed_by_user'){
            swal({
                text: 'Error al iniciar sesión, verifica tus credenciales e intenta nuevamente.',
                icon: 'warning'
            });
        }
    }

    const onAuthStateChanged = (user)=> {
        if(user){
            
        }
    };

    const handleEmail = (e)=> {
        const email = e.target.value;
        setEmail(email);
    }

    const handlePassword = (e)=>{
        const password = e.target.value;
        setPassword(password);
    }

    const validateForm = ()=> {
        if(!email){
            swal({
                text: 'Escribe tu email.',
                icon: 'warning'
            });
            return;
        }
        if(!ValidateEmail(email)){
            swal({
                text: 'Escribe un email válido.',
                icon: 'warning'
            });
            return;
        }
        if(!password){
            swal({
                text: 'Escribe tu contraseña.',
                icon: 'warning'
            });
            return;
        }
        loginExecute();
    }

    const cleanFormLogin = ()=> {
        setEmail('');
        setPassword('');
    }

    const Auth = async(usuario, loginSocial)=> {
        try {
            const passMd5 = md5(password);
            const resultAuth = await auth.signInWithEmailAndPassword(loginSocial ? usuario.email : email, loginSocial ? usuario.password : passMd5);
            if(resultAuth.user){
                dispacth(setUserLogged(usuario));
                localStorage.setItem('userLogged', JSON.stringify(usuario));
                closeLoginModal();
                setLoading(false);
                cleanFormLogin();
            }else{
                setLoading(false);
                swal({
                    text: 'No fue posible iniciar sesión.',
                    icon: 'warning'
                }); 
            }
        }catch (error) {
            setLoading(false);
            swal({
                text: 'No fue posible iniciar sesión intente mas tarde.',
                icon: 'warning'
            });
        }
    };

    const loginExecute = async()=> {
        setLoading(true);
        const passMd5 = md5(password);
        const data = {
            email: email,
            password: passMd5
        };
        const result = await callApi('login', 'post', data);
        if(result.status === 200){
            Auth(result.usuario);
        }else{
            setLoading(false);
            swal({
                text: result.message,
                icon: 'warning'    
            });    
        }
    }

    const handleFaceBookLogin = (user)=> {
        setLoading(true);
        if(!user.email){
            setLoading(false);
            swal({
                text: 'No fue posible obtener tu cuenta de facebook, verifica que tu email este confirmado.',
                icon: 'warning'
            });
            return;
        }
        RegisterSocialUser(user, true, false);
    }

    const handleCelular = (celular)=>{
        setCelualr(celular);
    }

    const validateCelphone = async()=> {
        setLoading(true);
        const dataCelular = {
            nombre: nameProvider,
            celular: `+${celular}`
        };
        const resultValidateCelular = await callApi('send_code_social_user','post',dataCelular);
        if(resultValidateCelular.status === 200){
            setLoading(false);
            setCodeSended(resultValidateCelular.codigo);
        }else{
            setLoading(false);
            swal({
                text: resultValidateCelular.message,
                icon: 'warning'
            });    
        }
    }

    const handleLoginApple = ()=> {
        window.AppleID.auth.signIn();
    }

    const handleCodeUser = (e)=> {
        const codeUser = e.target.value;
        setCodeUser(codeUser);
    }

    const handleVerifyCode = ()=> {
        userData.celular = `+${celular}`;
        userData.platform_register = 'web';
        setUserData(userData);
        if(!codeUser){
            swal({
                text: 'Escribe el código.',
                icon: 'warning'
            });
            return;
        }
        if(codeUser !== codeSended){
            swal({
                text: 'El código no coincide con el código enviado.',
                icon: 'warning'
            });
        }else{
            swal({
                text: 'Código confirmado, disfruta de una nueva experiencia KaiChef.',
                icon: 'success'
            });
            RegisterSocialUserExecute();
        }
    }

    return {
        loading,
        handleEmail,
        handlePassword,
        validateForm,
        email,
        password,
        handleLoginApple,
        handleFaceBookLogin,
        showCelphoneForm,
        handleCelular,
        celular,
        validateCelphone,
        handleCodeUser,
        handleVerifyCode,
        codeSended,
        codeUser
    }
}

export default useLoginModal
