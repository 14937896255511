import {configureStore} from '@reduxjs/toolkit';
import counterReducer from './slices/counterSlice';
import searchChefsReducer from './slices/searchChefsSlice';
import chefsFoundedReducer from './slices/chefsFoundedSlice';
import allChefsReducer from './slices/allChefsSlice';
import visibleChefsReducer from './slices/visibleChefsSlice';
import gastronomicStylesReducer from './slices/gastronomicStylesSlice';
import chefSelectedReducer from './slices/chefSelectedSlice';
import chefBadgesReducer from './slices/chefBadgesSlice';
import reservationReducer from './slices/reservationSlice';
import userLoggedReducer from './slices/userLoggedSlice';
import appReducer from './slices/appSlice';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        searchChefs: searchChefsReducer,
        chefsFounded: chefsFoundedReducer,
        allChefs: allChefsReducer,
        visibleChefs: visibleChefsReducer,
        gastronomicStyles: gastronomicStylesReducer,
        chefSelected: chefSelectedReducer,
        chefBadges: chefBadgesReducer,
        reservation: reservationReducer,
        userLogged: userLoggedReducer,
        app: appReducer
    },
});