import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import TextInputBorderless from '../../atoms/TextInputBorderless';
import StyledText from '../../atoms/typography/StyledText';
import Counter from '../Counter.js';
import ListCities from '../ListCities';
import SearchButton from '../../molecules/SearchButton.js';
import useGetAvailableLocations from './hooks/useGetAvailableLocations';
import swal from 'sweetalert';
import Calendar from '../../molecules/Calendar/Calendar';
import { callApi } from '../../../fixtures/api';
import { useDispatch, useSelector } from 'react-redux';
import {setCity, handleDate, addPeople, removePeople} from '../../../redux/slices/searchChefsSlice';
import {setChefsFounded} from '../../../redux/slices/chefsFoundedSlice';
import { setVisibleChefs } from '../../../redux/slices/visibleChefsSlice';
import { useHistory } from 'react-router';
import Colors from '../../../theme/Colors';
import Loading from '../../../components/Loading';
import { screenSizes } from '../../../fixtures/ScreenSizes';
import { config } from '../../../fixtures/config';
import {IoCaretDown} from 'react-icons/io5';

const SearchChefs = ({fontSize, backGroundColor, isResultPage}) => {

    const [loading, setLoading] = useState(false);
    const {city, date, people } = useSelector(state => state.searchChefs);
    const {locations} = useGetAvailableLocations();
    const [locationsAutomplete, setLocationsAutocomplete] = useState([]);
    const [showCounter, setShowCounter] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const searchCity = (e)=> {
        let text = e.target.value;
        text = text.slice(0,1).toUpperCase() + text.slice(1, text.length);
        if(text === ''){
            dispatch(setCity(''));
            setLocationsAutocomplete([]);
        }else{
            dispatch(setCity(text));
            let locationsFiltered = locations;
            locationsFiltered = locationsFiltered.filter(location => location.includes(text));
            setLocationsAutocomplete(locationsFiltered);
        }
    }

    const setCitySelected = (e)=> {
        const city = e.target.innerText;
        setLocationsAutocomplete([]);
        dispatch(setCity(city));
    }

    const searchChef = async()=> {
        if(!city){
            swal({
                text: 'Selecciona una ciudad',
                icon: 'warning'
            });
            return;
        };
        if(!date){
            swal({
                text: 'Selecciona una fecha',
                icon: 'warning'
            });
            return;
        };
        setLoading(true);
        const data = {
            "location": city,
            "date_selected": date,
            "people": people,
            "test_chefs": !config.openpayIsProduction
        };
        const result = await callApi('search_chef_by_location', 'post', data);
        const {chefs} = result;
        if(chefs.length === 0){
            swal({
                text: 'No se encontrarón chefs con los criteriores seleccionados.',
                icon: 'warning'
            });
            setLoading(false);
            return;
        }

        //result chefs into global store
        dispatch(setChefsFounded(chefs));
        if(!isResultPage){
            history.push('/resultado_busqueda_chefs');
        }
        setLoading(false);
    } 

    const onChageDate = (value)=> {
        dispatch(handleDate(value.format()));
    }

    const handleShowCounter = ()=> {
        console.log('click show counter');
        setShowCounter(!showCounter);
    }

    return (
        <>
            <SearchChefWrapper className='search-chef-wrapper mb-5' backGroundColor={backGroundColor}>
                <SearchChefHolder className='search-chef-holder'>
                    <TitleSearchWrapper className='d-lg-none'>
                        <StyledText 
                            text='Buscar un chef a domicilio en mi ciudad'
                            fontSize='20px'
                            color={Colors.blue}
                            fontWeight={600}
                        />
                    </TitleSearchWrapper>
                    <RowB className='align-items-center'>
                        <ColB lg='5' borderRight={'solid 1px'} background={Colors.white}>
                            <InputWrapper className='input-wrapper d-flex align-items-center'>
                                <StyledText className='mr-2' text='Ciudad' color={Colors.black} />
                                <TextInputBorderless style={{flex: 1}} fontSize={fontSize} placeholder='¿Dónde quieres tu reserva?' value={city} onChange={searchCity} />
                            </InputWrapper>
                            <ListCities locationsAutomplete={locationsAutomplete} onClick={setCitySelected} />
                        </ColB>
                        <ColB borderRight={'solid 1px'} background={Colors.white}>
                            <InputWrapper className='input-wrapper d-flex align-items-center'>
                                <StyledText className='mr-2' text='Fecha' color={Colors.black} fontSize={fontSize} />
                                <Calendar value={date} onChange={onChageDate} />
                            </InputWrapper>
                        </ColB>
                        <ColB lg='2' background={Colors.white}>
                            <ComensalesSearchWrapper 
                                className='comensales-search-wrapper'
                                onClick={handleShowCounter}
                            >
                                <ComensalesSearchHolder className='comensales-search-holder'>
                                    <InputWrapper className='input-wrapper d-flex align-items-center'>
                                        <StyledText text='Comensales' marginRight='8px' />
                                        <IoCaretDown style={{pointerEvents: 'none'}} color={Colors.black} size={12} />
                                    </InputWrapper>
                                </ComensalesSearchHolder>
                            </ComensalesSearchWrapper>
                            {
                                showCounter &&
                                    <CounterSearchWrapper className='counter-search-wrapper'>
                                        <CounterSearchHolder className='counter-search-holder'>
                                            <Counter text='Adultos' addPeople={()=>{dispatch(addPeople())}} removePeople={()=>{dispatch(removePeople())}} people={people} />
                                        </CounterSearchHolder>
                                    </CounterSearchWrapper>  
                            }
                        </ColB>
                        <ColB lg='2' className='d-flex justify-content-center justify-content-lg-end'>
                            <SearchButton onClick={searchChef} />
                        </ColB>
                    </RowB>
                </SearchChefHolder>
            </SearchChefWrapper>
            <Loading visible={loading} />
        </>
    )
}

const SearchChefWrapper = styled.div`
    background: ${props => props.backGroundColor || Colors.white};
    border-radius: 39px;
    height: 65px;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;

    @media (max-width:  ${screenSizes.mediumMax}){
        background: ${Colors.grayInputForm};
        border-radius: 0px;
        height: auto;
        top:0px;
        padding: 16px;
    }

    @media (min-width: ${screenSizes.large}){
        border: solid 1px ${Colors.grayBorder};
    }
`;

const SearchChefHolder = styled.div`


    @media (max-width: ${screenSizes.mediumMax}){
        background: ${Colors.white};
        border-radius: 19px;
        box-shadow: 0px 0px 6px ${Colors.grayBorder};
    }
`;

const TitleSearchWrapper = styled.div`
    padding: 12px 16px;
`;

const InputWrapper = styled.div`
    padding-left: 8px;

    @media (max-width: ${screenSizes.mediumMax}){
        border: solid 1px ${Colors.grayBorderB};
        border-radius: 19px;
        padding: 8px 16px;
    }
`;

const RowB = styled(Row)`

    @media (max-width: ${screenSizes.mediumMax}){
        padding: 16px;
    }
`;

const ColB = styled(Col)`
    border-right: ${props => props.borderRight};

    @media (max-width: ${screenSizes.mediumMax}){
        border-right-width: 0px;
        border-right-style: none;
        background: ${props => props.background};
        margin-bottom: 16px;
        border-radius: 26px;
    }
`;

const ComensalesSearchWrapper = styled.div`
    cursor: pointer;
`;

const ComensalesSearchHolder = styled.div`
    pointer-events: none;
`;

const CounterSearchWrapper = styled.div`
    
`;

const CounterSearchHolder = styled.div`
    border-radius: 5px;
    padding: 6px 12px;
    background: ${Colors.white};
    position: absolute;
    top: 150%;
    left: 15px;
    width: 190px;
    box-shadow: 0px 0px 5px ${Colors.boxShadow};

    @media (max-width: ${screenSizes.mediumMax}) {
        top: 100%;
    }
`;

export default SearchChefs
