import Footer from './components/Footer';
import WhatsAppBtn from './components/WhatsAppBtn';
import {Switch, Route} from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import Invite from './screens/Invite/Invite';
import Home from './screens/Home/Home';
import MainMenu from './components/Menu/MainMenu';
import AlliedChef from './screens/AlliedChef/AlliedChef';
import Faqs from './screens/Faqs/Faqs';
import Contact from './screens/Contact/Contact';
import {useDispatch} from 'react-redux';
import ResultChefs from './screens/ResultChefs';
import ChefProfile from './screens/ChefProfile';
import { useEffect } from 'react';
import { setUserLogged } from './redux/slices/userLoggedSlice';
import MyAccount from './screens/MyAccount/MyAccount';
import NotificationsSettings from './screens/NotificationsSettings/NotificationsSettings';
import MyReservations from './screens/MyReservations/MyReservations';
import PaymentMethods from './screens/PaymentMethods/PaymentMethods';
import ProtectedRoute from './components/ProtectedRoute';
import PaymentDetail from './screens/PaymentDetail/PaymentDetail';
import { setChefSelected } from './redux/slices/chefSelectedSlice';
import { setReservation } from './redux/slices/reservationSlice';
import { Blog } from './screens/Blog/Blog';
import ArticleDetail from './screens/ArticleDetail.js/ArticleDetail';

// localStorage.removeItem('userLogged');
// localStorage.removeItem('reservation');
// localStorage.removeItem('chefSelected');

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init('167362838479665', advancedMatching, options);

const App = ()=> {
  
  const dispatch = useDispatch();
  
  useEffect(()=>{
    getUserLogged();
  },[]);

  const getUserLogged = ()=>{
    const userLogged = JSON.parse(localStorage.getItem('userLogged'));
    const chefSelected = JSON.parse(localStorage.getItem('chefSelected'));
    const reservation = JSON.parse(localStorage.getItem('reservation'));
    if(userLogged){
      dispatch(setUserLogged(userLogged));
    }
    if(chefSelected){
      dispatch(setChefSelected(chefSelected));
    }
    if(reservation){
      dispatch(setReservation(reservation));
    }
  }

  const withFacebookAnalytics = (Component) => (props) => {
    ReactPixel.pageView();
    return <Component {...props} />;
  };

  return (
    <>
      <div className='general-container'>
        <MainMenu />
        <div className='mb-5'>
          <Switch>
            <Route exact path="/"  render={withFacebookAnalytics(Home)} />
            <Route exact path="/chef_aliado"  render={withFacebookAnalytics(AlliedChef)} />
            <Route exact path="/preguntas_frecuentes"  render={withFacebookAnalytics(Faqs)} />
            <Route exact path="/blog"  render={withFacebookAnalytics(Blog)} />
            <Route exact path="/contacto"  render={withFacebookAnalytics(Contact)} />
            <Route exact path="/resultado_busqueda_chefs" component={withFacebookAnalytics(ResultChefs)} />
            <Route exact path="/chef_profile" component={withFacebookAnalytics(ChefProfile)} />
            <Route path="/invite/:reservation_id" component={withFacebookAnalytics(Invite)} />
            <Route exact path="/articulo/:article_title" component={withFacebookAnalytics(ArticleDetail)} />

            {/* user screens */}
            <ProtectedRoute exact path='/mi_cuenta' component={withFacebookAnalytics(MyAccount)} />
            <ProtectedRoute exact path='/configuracion_notificaciones' component={withFacebookAnalytics(NotificationsSettings)} />
            <ProtectedRoute exact path='/mis_reservaciones' component={withFacebookAnalytics(MyReservations)} />
            <ProtectedRoute exact path='/metodos_pago' component={withFacebookAnalytics(PaymentMethods)} />
            <ProtectedRoute exact path='/openpay_payment_detail' component={withFacebookAnalytics(PaymentDetail)} />
          </Switch>
        </div>
        <Footer />
      </div>
      <WhatsAppBtn />
    </>
  );
}

export default App;
