import React from 'react'
import styled from 'styled-components'
import Colors from '../../../theme/Colors';
import CloseIcon from '../../atoms/CloseIcon';
import Logo from '../../atoms/Logo';
import TextInputItem from '../../molecules/TextInputItem/TextInputItem';
import 'react-phone-input-2/lib/style.css';
import StyledText from '../../atoms/typography/StyledText';
import MainButton from '../../molecules/MainButton';
import FullOverlay from '../../atoms/FullOverlay';
import useLoginModal from './hooks/useLoginModal';
import Loading from '../../../components/Loading';
import FacebookLogin from '@greatsumini/react-facebook-login';
import PhoneInput from 'react-phone-input-2';
import { countryCodes } from '../../../fixtures/countriesPhone';
import {IoLogoFacebook} from 'react-icons/io5';
import swal from 'sweetalert';

const LoginModal = ({closeLoginModal, showRecoveryPassModal, showRegisterModal}) => {

    const {
        loading,
        handleEmail, 
        handlePassword,
        validateForm,
        email,
        password,
        handleLoginApple,
        handleFaceBookLogin,
        showCelphoneForm,
        handleCelular,
        celular,
        validateCelphone,
        handleCodeUser,
        handleVerifyCode,
        codeSended,
        codeUser
    } = useLoginModal(closeLoginModal);    

    const handleRegisterModal = ()=> {
        closeLoginModal();
        showRegisterModal();
    }

    const handleRecoveryPass = ()=> {
        closeLoginModal();
        showRecoveryPassModal();
    }

    return (
        <>
            <FullOverlay>
                <LoginModalWrapper className='login-modal-wrapper'>
                    <LoginModalHolder className='login-modal-holder'>
                        <CloseIconWrapper className='d-flex justify-content-end'>
                            <CloseIcon onClick={closeLoginModal}/>
                        </CloseIconWrapper>
                        <div className='d-flex flex-column align-items-center'>
                            <LogoWrapper>
                                <Logo
                                    width={160}
                                    height={40}
                                />
                            </LogoWrapper>
                            {
                                !showCelphoneForm ?
                                    <>
                                        <TextInputItem 
                                            text='Usuario*' 
                                            value={email} 
                                            onChange={handleEmail} 
                                            placeholder='Email'
                                            width={'240px'}
                                        />
                                        <TextInputItem
                                            text='Contraseña*'
                                            value={password}
                                            onChange={handlePassword}
                                            icon
                                        />
                                        
                                        <MainButton 
                                            text='Iniciar sesión' 
                                            onClick={validateForm} 
                                            border={`solid 1px ${Colors.grayBorder}`}
                                            backGroundColor='transparent'
                                            color={Colors.black}
                                            marginTop='26px'
                                            marginBottom='20px'
                                        />

                                        <StyledText 
                                            color={Colors.mainColor}
                                            marginBottom='16px'    
                                            text='¿Olvidaste tu contraseña?'
                                            isClickeable
                                            onClick={handleRecoveryPass}
                                        />

                                        <OptionWrapper className='d-flex align-items-center'>
                                            <Divider />
                                            <StyledText 
                                                text='Ó'
                                                marginLeft='8px'
                                                marginRight='8px'
                                            />
                                            <Divider />
                                        </OptionWrapper>

                                        <FacebookLogin
                                            appId="1493435764189553"
                                            onSuccess={(response) => {
                                            }}
                                            onFail={(error) => {
                                                if(error.status !== "loginCancelled"){
                                                    swal({
                                                        text: `No fue posible iniciar sesión, intenta más tarde.
                                                                ${error.status}
                                                                `,
                                                        icon: 'warning'
                                                    });
                                                }
                                            }}
                                            onProfileSuccess={(response) => {
                                                console.log('Get Profile Success!', response);
                                                handleFaceBookLogin(response);
                                            }}
                                            children={
                                                <>
                                                    <IoLogoFacebook 
                                                        style={{marginRight:8}}
                                                    />
                                                    <StyledText 
                                                        text='Continúa con Facebook'
                                                        color={Colors.white}
                                                    />
                                                </>
                                            }
                                            style={{
                                                background: Colors.bluFb,
                                                borderRadius: 28,
                                                padding: 6,
                                                paddingLeft: 12,
                                                paddingRight: 12,
                                                marginBottom: 16,
                                                color: Colors.white,
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        />

                                        <MainButton
                                            text='Inicia sesión con Apple' 
                                            border={`solid 1px ${Colors.grayBorder}`}
                                            backGroundColor='transparent'
                                            color={Colors.black}
                                            marginBottom='40px'
                                            appleButton
                                            onClick={handleLoginApple}
                                        />

                                        <StyledText
                                            text='¿No tienes cuenta? Regístrate aquí'
                                            isClickeable
                                            onClick={handleRegisterModal}
                                            color={Colors.gray}
                                        />
                                    </>
                                :
                                <>  
                                    {
                                        codeSended ?
                                            <>
                                                <TextInputItem
                                                    text='Ingresa el código que has recibido.'
                                                    value={codeUser}
                                                    onChange={handleCodeUser}
                                                />
                                                <MainButton
                                                    backGroundColor='transparent' 
                                                    text='Verificar código' 
                                                    color={Colors.black} onClick={handleVerifyCode} 
                                                    border={`solid 1px ${Colors.grayBorder}`}
                                                />
                                            </> 
                                        :
                                            <>
                                                <TextInputItem
                                                        width='240px'
                                                        text='Celular'
                                                >
                                                    <PhoneInput
                                                        onlyCountries={countryCodes}
                                                        preferredCountries={['mx']}
                                                        country='mx'
                                                        value={celular}
                                                        onChange={celular => {handleCelular(celular)}}
                                                        inputStyle={{border: '0', background: Colors.grayInputForm, width: '100%'}}
                                                        buttonStyle={{border: '0', background: Colors.grayInputForm}}
                                                    />
                                                </TextInputItem>
                                                <MainButton 
                                                    text='Validar celular' 
                                                    onClick={validateCelphone} 
                                                    border={`solid 1px ${Colors.grayBorder}`}
                                                    backGroundColor='transparent'
                                                    color={Colors.black}
                                                    marginTop='26px'
                                                    marginBottom='20px'
                                                />
                                            </>
                                    }
                                </>
                            }
                            

                        </div>
                    </LoginModalHolder>
                </LoginModalWrapper>
            </FullOverlay>
            <Loading visible={loading} />
        </>
    )
}

const LoginModalWrapper = styled.div`
    width: 350px;
`;

const LoginModalHolder = styled.div`
    background: ${Colors.white};
    border-radius: 45px;
    padding: 20px;
    padding-bottom: 40px;
`;

const LogoWrapper = styled.div`
    margin-bottom: 12px;
`;

const CloseIconWrapper = styled.div`
`;

const OptionWrapper = styled.div`
    width: 100%;
    margin-bottom: 16px;
`;

const Divider = styled.div`
    height: 1px;
    background-color: ${Colors.grayTextInput};
    width: 100%;
`;

export default LoginModal
