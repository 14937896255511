import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { firestore } from "../../../fixtures/firebase";
import { setUserLastName, setUserName } from "../../../redux/slices/userLoggedSlice";

const useMyAccount = () => {

    const {userData} = useSelector(state => state.userLogged);
    const dispatch = useDispatch();

    const userId = userData.id;
    
    const [loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState(userData.nombre);
    const [apellidos, setApellidos] = useState(userData.apellidos || `${userData.apellido_paterno} ${userData.apellido_materno}`);
    const [email] = useState(userData.email);
    const [celular] = useState(userData.celular);

    const handleNombre = (e)=> {
        const nombre = e.target.value;
        setNombre(nombre);
    }

    const handleApellidos = (e)=> {
        const apellidos = e.target.value;
        setApellidos(apellidos);
    }
    
    const validateForm = ()=> {
        if(!nombre){
            swal({
                text: 'Escribe tu nombre',
                icon: 'warning'
            });
            return
        }
        if(!apellidos){
            swal({
                text: 'Escribe tus apellidos',
                icon: 'warning'
            });
            return
        }
        updateProfile();
    }

    const updateProfile = async()=> {
        setLoading(true);
        await firestore.collection('Usuarios').doc(userId).update({
            nombre,
            apellidos
        });
        dispatch(setUserName(nombre));
        dispatch(setUserLastName(apellidos));
        setLoading(false);
    }
    
    return {
        loading,
        userId,
        handleNombre,
        handleApellidos,
        validateForm,
        nombre,
        apellidos,
        email,
        celular
    }
}

export default useMyAccount
