import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { FormatPrice } from '../../../fixtures/helpers';
import { setReservationActive } from '../../../redux/slices/reservationSlice';
import Colors from '../../../theme/Colors';
import StyledSubtitle from '../../atoms/StyledSubtitle';
import StyledText from '../../atoms/typography/StyledText';
import MainButton from '../MainButton';

const ChefFullDayItem = () => {

    const {chef} = useSelector(state => state.chefSelected);
    const dispatch = useDispatch();

    const selectFullDay = ()=> {
        dispatch(setReservationActive(chef.costo_dia));
    }

    return (
        <ChefFullDayItemWrapper className='chef-full-day-item-wrapper'>
            <ChefFullDayItemHolder className='chef-full-day-item-holder d-flex align-items-center justify-content-between'>
                <StyledSubtitle text='Día completo / Full Day' />
                <FullDayPriceWrapper>
                    <StyledText text={FormatPrice(chef.costo_dia)} marginBottom='8px' />
                    <MainButton 
                        backGroundColor={Colors.white}
                        text='Seleccionar' 
                        color={Colors.gray} 
                        border={`solid 1px ${Colors.grayBorder}`} 
                        onClick={selectFullDay}
                    />
                </FullDayPriceWrapper>
            </ChefFullDayItemHolder>
        </ChefFullDayItemWrapper>
    )
}

const ChefFullDayItemWrapper = styled.div``;

const ChefFullDayItemHolder = styled.div`
    border-radius: 35px;
    border: solid 1px ${Colors.grayBorder};
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 8px;
    padding-bottom: 8px;
`;

const FullDayPriceWrapper = styled.div`
`;

export default ChefFullDayItem
