import React from 'react'
import styled from 'styled-components'
import { screenSizes } from '../../fixtures/ScreenSizes';
import Colors from '../../theme/Colors';

const StyledTitle = ({
    text, 
    color, 
    fontSize, 
    fontWeight,
    marginBottom,
    style
}) => {
    return (
        <Title
            style={style}
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
            marginBottom={marginBottom}
        >
            {text}
        </Title>
    )
}

const Title = styled.h1`
    color: ${props => props.color || Colors.blue};
    font-size: ${props => props.fontSize || '20px'};
    font-weight: ${props => props.fontWeight || 'bold'};
    margin-bottom: ${props => props.marginBottom || '0px'} ;

    @media (max-width: ${screenSizes.mediumMax}){
        font-size: 18px;
    }
`;

export default StyledTitle
