import React from 'react';
import {IoMdRemoveCircleOutline}from 'react-icons/io';
import {IoMdAddCircleOutline}from 'react-icons/io';
import styled from 'styled-components';
import Colors from '../../../theme/Colors';
import StyledText from '../../atoms/typography/StyledText';

const Counter = ({text, people, removePeople, addPeople}) => {

    return (
        <CounterWrapper className='counter-wrapper'>
            <CounterHolder className='conter-holder d-flex justify-content-between align-items-center'>
                <StyledText text={text} />
                <IoMdRemoveCircleOutline onClick={removePeople}  color={Colors.grayBorder} size={24} />
                <StyledText text={people} />
                <IoMdAddCircleOutline onClick={addPeople}  color={Colors.grayBorder} size={24} />
            </CounterHolder>
        </CounterWrapper>
    )
}

const CounterWrapper = styled.div`
    flex: 1
`;

const CounterHolder = styled.div`
    flex: 1
`;

export default Counter
