import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { firestore } from "../../../../fixtures/firebase";
import { setChefBadges } from "../../../../redux/slices/chefBadgesSlice";


const useGetChefBadges = () => {

    const dispatch = useDispatch();

    useEffect(()=>{
        getBadges();
    },[]);

    const getBadges = async()=>{
        const chefBadgesQuery = await firestore.collection('Insignias').orderBy('orden').get();
        const chefBadges = chefBadgesQuery.docs.map((badge)=>{
            const badgeInfo = badge.data();
            badgeInfo.id = badge.id;
            return badgeInfo;
        });
        dispatch(setChefBadges(chefBadges));
    } 
}

export default useGetChefBadges;
