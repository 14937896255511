import React from 'react';
import {IoCheckmarkCircle} from 'react-icons/io5'
import Colors from '../../theme/Colors';

const CheckIcon = ({color, size}) => {
    return (
        <IoCheckmarkCircle color={color || Colors.mainColor} size={size || 32} />
    )
}

export default CheckIcon
