import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import ChefPersonalInfo from '../../design/Templates/ChefPersonalInfo';

const ChefProfile = () => {

    const {chef} = useSelector(state => state.chefSelected);

    return (
        <>
            {
                !chef ?
                    <Redirect path='/' />
                :
                    <ChefProfileWrapper className='chef-profile-wrapper'>
                        <ChefPersonalInfo 
                            chefProfilePicture={chef.avatar}
                            chefName={`${chef.nombre} 
                            ${chef.apellido_paterno}`} 
                            promedioRating={chef.promedio_rating}
                            totalEvaluaciones={chef.total_evaluaciones}
                            chefLocation={chef.areas_servicio[0].estado}
                        />
                    </ChefProfileWrapper>
            }
        </>
    )
}

const ChefProfileWrapper = styled.section`
`;

export default ChefProfile
