import React from 'react'
import styled from 'styled-components'
import Images from '../../images/Images';
import Colors from '../../theme/Colors';
import ItemWork from './ItemWork';
import {gsap} from 'gsap';
import { screenSizes } from '../../fixtures/ScreenSizes';

const HowItWorks = () => {

    const changeStyleItem = (e)=> {
        const item = e.target.getAttribute('data-item');
        if(item === 'download'){
            gsap.set(`img.${item}`, {attr: {src: Images.iconDescargaOver}});
            gsap.to(`.${item} h4`, {color: Colors.mainColor});
        }
        if(item === 'reserva'){
            gsap.set(`.${item} img`, {attr: {src: Images.iconAcudimosOver}});
            gsap.to(`.${item} h4`, {color: Colors.mainColor});
        }
        if(item === 'enjoy'){
            gsap.set(`.${item} img`, {attr: {src: Images.iconCocinamosOver}});
            gsap.to(`.${item} h4`, {color: Colors.mainColor});
        }
    }

    const resetStyleItem = (e)=> {
        const item = e.target.getAttribute('data-item');
        if(item === 'download'){
            gsap.set(`.${item} img`, {attr: {src: Images.iconDescarga}});
            gsap.to(`.${item} h4`, {color: Colors.blue});
        }
        if(item === 'reserva'){
            gsap.set(`.${item} img`, {attr: {src: Images.iconAcudimos}});
            gsap.to(`.${item} h4`, {color: Colors.blue});
        }
        if(item === 'enjoy'){
            gsap.set(`.${item} img`, {attr: {src: Images.iconCocinamos}});
            gsap.to(`.${item} h4`, {color: Colors.blue});
        }
    }

    return (
        <HowItWorksHolder className='container how-it-works py-5'>
            <Title className='mb-4'>¿Cómo funciona KaiChef?</Title>
            <div className='row mb-4 d-lg-none'>
                <div className='col-12'>
                    <ImgHowItWorksMobile className='img-fluid' src={Images.imgHowItWorksMobile} />
                </div>
            </div>
            <Description>Creamos una plataforma que te hará vivir momentos únicos y llenos de sabor. Disfruta de platillos de autor con exquisito toque Gourmet.</Description>
            <ItemWorkHolder>
                <div className='row'>
                    <div className='col-sm-12 col-md-4 mb-4 mb-md-0'>
                        <ItemWork
                            className='download'
                            item='download'
                            onMouseEnter={changeStyleItem}
                            onMouseOut={resetStyleItem}
                            title='Descarga' 
                            description='Descarga la aplicación en tu dispositivo móvil Android o iOS Regístrate y reserva.' 
                            image={Images.iconDescarga}
                        />
                    </div>
                    <div className='col-sm-12 col-md-4 mb-4 mb-md-0'>
                        <ItemWork
                            className='reserva'
                            item='reserva' 
                            title='Reserva' 
                            onMouseEnter={changeStyleItem}
                            onMouseOut={resetStyleItem}
                            description='Ingresa a la app, elige tu chef favorito y selecciona el menú que más te guste.' 
                            image={Images.iconAcudimos}
                        />
                    </div>
                    <div className='col-sm-12 col-md-4 mb-4 mb-md-0'>
                        <ItemWork
                            className='enjoy'
                            item='enjoy' 
                            onMouseEnter={changeStyleItem}
                            onMouseOut={resetStyleItem}
                            title='Disfruta' 
                            description='Acudimos a tu casa, cocinamos, servimos y dejamos impecable tu cocina.' 
                            image={Images.iconCocinamos}
                        />
                    </div>
                </div>
            </ItemWorkHolder>
        </HowItWorksHolder>
    )
}

const HowItWorksHolder = styled.section`
    
`;

const Title = styled.h3`
    color: ${Colors.blue};
    font-family: 'Montserrat', sans-serif;
    font-size: 35px;
    font-weight: bold;
    text-align: center;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
    }

    @media (min-width: ${screenSizes.medium}) and (max-width: ${screenSizes.mediumMax}) {
        text-align: left;
    }
`;

const ImgHowItWorksMobile = styled.img`
    
`;

const Description = styled.p`
    color: ${Colors.black};
    font-size: 17px;
    margin: 0px auto;
    margin-bottom: 4.3rem;
    text-align: center;
    width: 70%;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        text-align: left;
        font-size: 14px;
        margin-left: 0px;
        width: unset;
    }
`;

const ItemWorkHolder = styled.div`
    /* display: flex;
    justify-content: space-between; */
`;

export default HowItWorks
