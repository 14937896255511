import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from '../../components/Loading';
import ResultChefsHeader from '../../design/organisms/ResultChefsHeader';
import GastronomicStyles from '../../design/Templates/GastronomicStyles';
import GroupChefs from '../../design/Templates/GroupChefs';
import { setActiveAllChefs, setActiveRecomendedChefs } from '../../redux/slices/appSlice';
import { setVisibleChefs } from '../../redux/slices/visibleChefsSlice';
import useGetAllChefs from './hooks/useGetAllChefs';

const ResultChefs = () => {

    const dispatch = useDispatch();
    const  {loading} = useGetAllChefs();
    const chefs_founded = useSelector(state => state.chefsFounded.chefs);
    const all_chefs = useSelector(state => state.allChefs.chefs);

    if(chefs_founded.length === 0){
        dispatch(setVisibleChefs(all_chefs));
        dispatch(setActiveAllChefs());
    }else{
        dispatch(setVisibleChefs(chefs_founded));
        dispatch(setActiveRecomendedChefs());
    }


    return (
        <>
            <ResultChefsWrapper className='result-chefs-wrapper mb-5'>
                <ResultChefsHeader />
                <GastronomicStyles />
                <GroupChefs />
            </ResultChefsWrapper>
            <Loading visible={loading} />
        </>
    )
}

const ResultChefsWrapper = styled.div`
`;

export default ResultChefs;
