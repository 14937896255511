import React, {useEffect} from 'react'
import styled from 'styled-components'
import MainButton from '../../components/MainButton';
import Images from '../../images/Images';
import Colors from '../../theme/Colors';
import gsap from 'gsap';
import { screenSizes } from '../../fixtures/ScreenSizes';
import {useHistory} from 'react-router-dom';

const ShowProposal = () => {

    const {push} = useHistory();

    useEffect(() => {
        gsap.to('.img-proposal', {scrollTrigger: {trigger: '.show-proposal-container'}, opacity: 1, left: 0, duration: 2.5});
        gsap.to('.text-proposal', {scrollTrigger: {trigger: '.show-proposal-container'}, opacity: 1, duration: 2.5});
    }, [])

    const navToAlliedChef = ()=> {
        push('/chef_aliado');
    }

    return (
        <ShowProposalContainer className='container show-proposal-container'>
            <div className='row'>
                <div className='d-none d-lg-block col-lg-6 align-self-center'>
                    <ImgHolder className='img-proposal'>
                        <ImgProposal className='img-fluid img-proposal' src={Images.showProposal} />
                    </ImgHolder>
                </div>
                <div className='col-lg-6 align-self-center'>
                    <ContentHolder className='show-proposal-holder'>
                        <Content className='text-proposal'>
                            <Title>Muéstrale a México tu propuesta gastronómica</Title>
                            <img className='img-fluid mb-3 d-lg-none' src={Images.imgShowProposalMobile} alt='muestra_tu_propuesta_grastronomica' />
                            <ContentText>Conviértete en Aliado Kaichef y usa tu talento para crear historias inolvidables. Nos encantaría trabajar contigo. ¿Estás listo para tu nueva aventura?</ContentText>
                            <div className='d-flex justify-content-center'>
                                <MainButton text='Más Información' onClick={navToAlliedChef} />
                            </div>
                        </Content>
                    </ContentHolder>
                </div>
            </div>
        </ShowProposalContainer>
    )
}

const ShowProposalContainer = styled.section`
    margin-bottom: 100px;
`;

const ImgHolder = styled.div`
    flex: 1;
    opacity: 0;
    position: relative;
    left: -150%;
`;

const ImgProposal = styled.img`
`;

const ContentHolder = styled.div`
    flex: 1;
`;

const Content = styled.div`
    opacity: 0;
`;

const Title = styled.h4`
    font-family: 'Montserrat', sans-serif;
    font-size: 35px;
    font-weight: bold;
    color: ${Colors.blue};
    margin-bottom: 20px;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 18px;
        font-weight: 600;
    }
`;

const ContentText = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    color: ${Colors.blue};
    margin-bottom: 20px;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.smallMax}) {
        font-size: 12px;
    }
`;

export default ShowProposal
