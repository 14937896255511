import { createSlice } from "@reduxjs/toolkit";

export const searchChefsSlice = createSlice({
    name: 'searchChefs',
    initialState: {
        location: '',
        date: '',
        people: 2,
        minPeople: 2,
    },
    reducers: {
        setCity: (state, action)=> {
            state.city = action.payload;
        },
        handleDate: (state, action)=> {
            state.date = action.payload;
        },
        addPeople: (state)=> {
            state.people++;
        },
        removePeople: (state)=> {
            if(state.people !== state.minPeople){
                state.people--;
            }
        },
    }
});

export const {setCity, handleDate, addPeople, removePeople} = searchChefsSlice.actions;
export default searchChefsSlice.reducer;