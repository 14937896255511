import { config } from "./config";

const callApi = async(endPoint, method, data, contenType)=> {
    try {
        let response = await fetch(`${config.urlApi}${endPoint}`, {
            method: method,
            headers: {
                'Origin': 'https://kaichef.com',
                'Accept': 'application/json',
                'Content-Type': contenType ? contenType : 'application/json',
            },
            body: data ? JSON.stringify(data) : null
        });
        let json = await response.json();
        json.status = response.status;
        return json;
    } catch (error) {
        console.error(error);
        return error;
    }
}

export {callApi};