import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        activeAllChefs: false,
        activeRecomendedChefs: false
    },
    reducers: {
        setActiveAllChefs: (state, action)=> {
            state.activeAllChefs = true;
            state.activeRecomendedChefs = false;
        },
        setActiveRecomendedChefs: (state, action)=> {
            state.activeRecomendedChefs = true;
            state.activeAllChefs = false;
        }
    }
});

export const {setActiveAllChefs, setActiveRecomendedChefs} = appSlice.actions;
export default appSlice.reducer;