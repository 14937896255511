import { useDispatch, useSelector } from 'react-redux';
import { setActiveAllChefs, setActiveRecomendedChefs } from '../../../../redux/slices/appSlice';
import { setVisibleChefs } from '../../../../redux/slices/visibleChefsSlice';

const useTabsSelectedChefs = () => {
    const allChefs = useSelector(state => state.allChefs);
    const chefsFounded = useSelector(state => state.chefsFounded);
    const dispatch = useDispatch();

    const handleClickRecomendedChefs = ()=> {
        dispatch(setActiveRecomendedChefs());
        dispatch(setVisibleChefs(chefsFounded.chefs));
    }

    const handleClickAllChefs = ()=> {
        dispatch(setActiveAllChefs());
        dispatch(setVisibleChefs(allChefs.chefs));
    }

    return{
        handleClickRecomendedChefs,
        handleClickAllChefs
    }

}

export default useTabsSelectedChefs
