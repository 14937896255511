import React from 'react';
import styled from 'styled-components';
import Colors from '../../theme/Colors';
import {IoLocation} from 'react-icons/io5';

const LocationIcon = ({className, color, size}) => {
    return (
        <LocationIconWrapper className='location-icon-wrapper'>
            <IoLocation className={className} color={color || Colors.blue} size={size || 12} />
        </LocationIconWrapper>
    )
}

const LocationIconWrapper = styled.div`
`;

export default LocationIcon
