import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import MainButton from '../../../components/MainButton';
import BannerAllChefs from '../../../images/banner_all_chefs.png';
import Colors from '../../../theme/Colors';
import StyledTitle from '../../atoms/StyledTitle';

const BannerViewAllChefs = () => {

    const history = useHistory();

    const GotoListAllChefs = ()=> {
        history.push('/resultado_busqueda_chefs');
    }

    return (
        <BannerAllChefsWrapper className='banner-all-chefs-wrapper'>
            <BannerAllChefsHolder className='banner-all-chefs-holder'>
                <Container>
                    <Row>
                        <Col>
                            <ImageBannerWrapper className='image-banner-wrapper'>
                                <ImageBanner className='img-fluid' src={BannerAllChefs} />
                                <CTAWrapper className='cta-wrapper'>
                                    <CTAHolder>
                                        <StyledTitle
                                            text='Descubre nuestros chefs aliados y reserva'
                                            color={Colors.white}
                                            fontSize='3vw'
                                            marginBottom='8px'
                                        />
                                        <MainButton 
                                            text='Ver todos los Chefs' 
                                            fontSize='1vw' 
                                            onClick={GotoListAllChefs}
                                            width='178px'
                                        />
                                    </CTAHolder>
                                </CTAWrapper>
                            </ImageBannerWrapper>
                        </Col>
                    </Row>
                </Container>
            </BannerAllChefsHolder>
        </BannerAllChefsWrapper>
    )
}

const BannerAllChefsWrapper = styled.div``;

const BannerAllChefsHolder = styled.div``;

const ImageBannerWrapper = styled.div`
    position: relative;
`;

const ImageBanner = styled.img``;

const CTAWrapper = styled.div`
    position: absolute;
    left: 45px;
    top: 33%;
`;

const CTAHolder = styled.div`
    max-width: 525px;
`;

export default BannerViewAllChefs
