import React, { useState } from 'react';
import styled from 'styled-components';
import Colors from '../../../theme/Colors';
import TextInput from '../../atoms/TextInput';
import StyledText from '../../atoms/typography/StyledText';
import {IoEye} from 'react-icons/io5';
import {IoEyeOff} from 'react-icons/io5';
import InputMask from "react-input-mask";

const TextInputItem = ({
    text, 
    value, 
    onChange, 
    children, 
    icon, 
    width, 
    placeholder,
    disabled,
    marginRightWrapper,
    maxLength,
    mask,
    dataOpenpayCard,
    widthWrapper
}) => {

    const [passwordVisible, setPasswordVisible] = useState(true);

    return (
        <TextInputItemWrapper 
            className='text-input-item-wrapper'
            marginRightWrapper={marginRightWrapper}
            widthWrapper={widthWrapper}
        >

            <StyledText text={text} />
            <TextInputItemHolder width={width} className='text-input-item-holder d-flex align-items-center'>
            {
                children ?
                children
                :
                <>
                    {
                        mask ?
                            <InputTextMask
                                data-openpay-card={dataOpenpayCard} 
                                value={value} 
                                placeholder={placeholder}
                                onChange={onChange} 
                                mask="9999 9999 9999 9999"
                            />
                        :
                            <TextInput 
                                isPassword={!passwordVisible} 
                                value={value} 
                                onChange={onChange} 
                                placeholder={placeholder} 
                                disabled={disabled}
                                maxLength={maxLength}
                                dataOpenpayCard={dataOpenpayCard}
                            />
                    }
                    {
                        icon ?
                            passwordVisible ?
                                <IoEyeOff 
                                    cursor='pointer' 
                                    color={Colors.gray} 
                                    size={20} 
                                    onClick={()=>{setPasswordVisible(!passwordVisible)}}
                                />
                            :
                                <IoEye 
                                    cursor='pointer' 
                                    color={Colors.gray} 
                                    size={20} 
                                    onClick={()=>{setPasswordVisible(!passwordVisible)}}
                                />
                        :
                            null
                    }
                </>
            }
            </TextInputItemHolder>
        </TextInputItemWrapper>
    )
}

const TextInputItemWrapper = styled.div`
    margin-right: ${props => props.marginRightWrapper || '0px'};
    width: ${props => props.widthWrapper || 'auto'};
`;

const TextInputItemHolder = styled.div`
    background: ${Colors.grayInputForm};
    width: ${props => props.width || 'auto'};
    height: 35px;
    border: 0;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 16px;
`;

const InputTextMask = styled(InputMask)`
    flex: 1;
    border: 0;
    background: transparent;

    :focus {
        outline: none;
    }
`; 

export default TextInputItem
