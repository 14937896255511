import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import StyledSubtitle from '../../atoms/StyledSubtitle';
import GastronomicStyleItem from "../../molecules/GastronomicStyleItem";
import useGetGastronomicStyles from './hooks/useGetGastronomicStyles';

const GastronomicStyles = () => {
    
    useGetGastronomicStyles();
    const {allGastronomicStyles} = useSelector(state => state.gastronomicStyles);

    const selecGastronomicStyle = (e)=> {
        
    }

    return (
        <GastronomicStylesWrapper className='gastronomic-styles-wrapper mb-5'>
            <Container>
                <StyledSubtitle className='mb-4' text='Estilos Gastrónomicos' />
                <Row>
                    {
                        allGastronomicStyles.map((gastronomicStyle)=>(
                            <Col lg='3' className='mb-4' key={gastronomicStyle.id}>
                                <GastronomicStyleItem
                                    onClick={selecGastronomicStyle}
                                    name={gastronomicStyle.nombre}
                                    imageUrl={gastronomicStyle.image_url}
                                    numMenusByChef={gastronomicStyle.numero_menus_by_chef}
                                />
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </GastronomicStylesWrapper>
    )
}

const GastronomicStylesWrapper = styled.section`

`;

export default GastronomicStyles
