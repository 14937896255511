import React from 'react'
import styled from 'styled-components'
import { screenSizes } from '../../fixtures/ScreenSizes';
import Colors from '../../theme/Colors';

const ItemEnjoy = (props) => {

    return (
        <ItemEnjoyWrapper className='item-enjoy-wrapper' onMouseOver={props.onMouseOver}>
            <div className='row' data-item={props.item}>
                <div className='col-12 col-lg-2 mb-3 mb-lg-0'>
                    <Image src={props.image} />
                </div>
                <div className='col-12 col-lg-10'>
                    <ContentHolder>
                        <Title>{props.title}</Title>
                        <Description>{props.description}</Description>
                    </ContentHolder>
                </div>
            </div>
        </ItemEnjoyWrapper>
    )
}

const ItemEnjoyWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
`;

const Image = styled.img`
    height: 70px;
    width: 70px;
    margin-right: 20px;
    pointer-events: none;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        height: 52px;
        width: 52px;
    }
`;

const ContentHolder = styled.div`
    pointer-events: none;
`;

const Title = styled.h4`
    color: ${Colors.mainColor};
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    pointer-events: none;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 14px;
        color: ${Colors.blue};
    }
`;

const Description = styled.p`
    color: ${Colors.black};
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8em;
    width: 90%;
    pointer-events: none;

    @media (min-width: ${screenSizes.xSmall}) and (max-width: ${screenSizes.large}) {
        width: auto;
    }
`;

export default ItemEnjoy
