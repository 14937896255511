import React from 'react'
import styled from 'styled-components'
import Colors from '../../theme/Colors';
import StyledText from './typography/StyledText';

const ChefBadge = ({badgeImage, count}) => {
    return (
        <ChefBadgeWrapper className='chef-badge-wrapper' style={{margin: '0 auto'}}>
            <ChefBadgeImage className='img-fluid' src={badgeImage} />
            <CountChefBadgeWrapper className='count-chef-badge-wrapper d-flex align-items-center justify-content-center'>
                <StyledText text={count} color={Colors.gray} fontSize='8px' />
            </CountChefBadgeWrapper>
        </ChefBadgeWrapper>
    )
}

const ChefBadgeWrapper = styled.div`
    position: relative;
    width: 60px;
    height: 60px;
`;

const CountChefBadgeWrapper = styled.div`
    width: 17px;
    height: 13px;
    border: solid 1px ${Colors.mainColor};
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${Colors.white};
`;

const ChefBadgeImage = styled.img`
    
`;

export default ChefBadge
