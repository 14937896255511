import React from 'react';
import styled from 'styled-components';
import { screenSizes } from '../../../fixtures/ScreenSizes';
import Colors from '../../../theme/Colors';

const ListCities = ({locationsAutomplete, onClick}) => {
    return (
        <ListCitiesWrapper className={locationsAutomplete.length > 0 ? 'd-block' : 'd-none'}>
            <ListCitiesContainer>
                {
                    locationsAutomplete.map((location)=>(
                            <CityItem key={location} onClick={onClick}>{location}</CityItem>
                        )
                    )
                }
            </ListCitiesContainer>
        </ListCitiesWrapper>
    )
}

const ListCitiesWrapper = styled.div`
    padding: 20px;
    background-color: ${Colors.white};
    max-height: 300px;
    position: absolute;
    top: 50px;
    left: 0px;
    width: 100%;
    border-radius: 21px;
    overflow: scroll;
    z-index: 100000;

    @media (max-width: ${screenSizes.mediumMax}) {
        border-radius: 0px;
    }
`;

const ListCitiesContainer = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const CityItem = styled.li`
    cursor: pointer;
    margin-bottom: 8px;

    :hover {
        background-color: ${Colors.mainColor};
        color: ${Colors.white}
    }
`;

export default ListCities
