export const config = {
    urlApi: 'https://us-central1-kaichefapp.cloudfunctions.net/app/api/',
    urlAppStore: 'https://apps.apple.com/us/app/kaichef/id1553924790',
    urlPlayStore: 'https://play.google.com/store/apps/details?id=com.app.kaichefuser',
    firebaseConfig: {
        apiKey: "AIzaSyAinJQjpdVSIz-teMhRkzE8Ov1giCmmNQU",
        authDomain: "kaichefapp.firebaseapp.com",
        databaseURL: "https://kaichefapp.firebaseio.com",
        projectId: "kaichefapp",
        storageBucket: "kaichefapp.appspot.com",
        messagingSenderId: "611043142608",
        appId: "1:611043142608:web:bb726a28e7d610a9f967d5",
        measurementId: "G-7J82549KG3"
    },
    urlApiSepomex: 'https://api-sepomex.hckdrk.mx/query/',
    //dev
    // urlApiOpenpay: 'https://sandbox-api.openpay.mx/v1/',
    // openpayMerchantId: 'mqw7dduf4ixa8w5yz9j3',
    // openpayPublicKey: 'pk_6ea61506ae7345058aff0141396543e9',
    // openpayIsProduction: false,
    //prod
    urlApiOpenpay: 'https://api.openpay.mx/v1/',
    openpayMerchantId: 'mv4v45f0b1gxcvbuzmyj',
    openpayPublicKey: 'pk_1f99e7f2b76c4990bb78a2485d04abaf',
    openpayIsProduction: true,
};