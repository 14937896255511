import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import Colors from '../../../theme/Colors';
import ChefProfilePicture from '../../atoms/ChefProfilePicture';
import StyledTitle from '../../atoms/StyledTitle';
import StyledText from '../../atoms/typography/StyledText';
import ChefRating from '../../molecules/ChefRating';
import ChefLocation from '../../molecules/ChefLocation';
import CareerPath from '../../molecules/CareerPathTitle';
import ListChefBadges from '../../organisms/ListChefBadges';
import ChefReviews from '../ChefReviews';
import ChefFullDayReservation from '../../organisms/ChefFullDayReservation';
import ChefMenuReservation from '../../organisms/ChefMenuReservation';
import { useSelector } from 'react-redux';
import MyReservation from '../MyReservation';
import StepCompleted from '../../molecules/StepCompleted/StepCompleted';

const ChefPersonalInfo = ({chefProfilePicture, chefName, promedioRating, totalEvaluaciones, chefLocation}) => {

    const reservation = useSelector(state => state.reservation);

    return (
        <ChefPersonalInfoWrapper className='chef-personal-info-wrapper'>
            <Container>
                <Row>
                    <Col lg='6' className='mb-2'>
                        <ChefProfilePicture chefProfilePicture={chefProfilePicture} />
                    </Col>
                    <Col lg='6'>
                        <PersonalInfoWrapper>
                            <NameRatingWrapper className='d-flex align-items-center'>
                                <TitleInfoWrapper>
                                    <StyledTitle text={chefName} />
                                </TitleInfoWrapper>
                                {
                                    totalEvaluaciones > 0 &&
                                    <>
                                        <RatingHolder>
                                            <ChefRating promedioRating={promedioRating}  />
                                        </RatingHolder>
                                        <StyledText text={`(${totalEvaluaciones} ${totalEvaluaciones > 1 ? 'Evaluaciones':'Evaluación'})`} color={Colors.gray} />
                                    </>
                                }
                            </NameRatingWrapper>
                            <div className='mb-5'>
                                <ChefLocation chefLocation={chefLocation} />
                            </div>
                            <CareerPath />
                        </PersonalInfoWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg='6'>
                        <ListChefBadges />
                        <ChefReviews />
                    </Col>
                    <Col lg='6'>
                        {
                            Object.keys(reservation.menu).length === 0 && !reservation.active ?
                                <>
                                    <ChefFullDayReservation />
                                    <ChefMenuReservation />
                                </>
                            :
                                <>
                                    {
                                        reservation.active &&
                                        <StepCompleted text='Día completo / Full Day' />
                                    }
                                    <MyReservation />
                                </>
                        }
                    </Col>
                </Row>
            </Container>
        </ChefPersonalInfoWrapper>
    )
}

const ChefPersonalInfoWrapper = styled.div`
`;

const PersonalInfoWrapper = styled.div`
`;

const NameRatingWrapper = styled.div`
`;

const TitleInfoWrapper = styled.div`
    margin-right: 16px;
    max-width: 240px;
`;

const RatingHolder = styled.div`
    margin-right: 8px;
`;

export default ChefPersonalInfo
