import React from 'react';
import styled from 'styled-components';
import FullOverlay from '../../atoms/FullOverlay';
import CloseIcon from '../../atoms/CloseIcon';
import useRecoveryPass from './hooks/useRecoveryPass';
import Colors from '../../../theme/Colors';
import Logo from '../../atoms/Logo';
import TextInputItem from '../../molecules/TextInputItem/TextInputItem';
import MainButton from '../../molecules/MainButton';
import StyledText from '../../atoms/typography/StyledText';
import Loading from '../../../components/Loading';

const RecoveryPassModal = ({closeRecoveryPassModal}) => {

    const {
        loading,
        handleEmail,
        handleCode,
        validateForm,
        validateCode,
        handleNewPass,
        validateNewPass,
        email,
        codeSended,
        code,
        codeValidated,
        newPass,
    } = useRecoveryPass(closeRecoveryPassModal);

    return (
        <>
            <FullOverlay>
                <RecoveryPassModalWrapper>
                    <RecoveryPassModalHolder>
                        <CloseIconWrapper className='d-flex justify-content-end'>
                                <CloseIcon onClick={closeRecoveryPassModal}/>
                        </CloseIconWrapper>
                        <div className='d-flex flex-column align-items-center'>
                                <LogoWrapper>
                                    <Logo
                                        width={160}
                                        height={40}
                                    />
                                </LogoWrapper>
                                {
                                    codeValidated ?
                                        <>
                                            <StyledText
                                                text='Establece tu nueva contraseña.' 
                                                marginBottom='16px'
                                            />
                                            <TextInputItem 
                                                text='Nueva contraseña*' 
                                                value={newPass} 
                                                onChange={handleNewPass}
                                                icon
                                            />
                                        </>
                                    :
                                        !codeSended ?
                                        <>
                                            <StyledText
                                                style={{textAlign: 'center'}}
                                                text='Enviaremos un código de recuperación a tu email registrado.'
                                                marginBottom='16px'
                                            />
                                            <TextInputItem 
                                                text='Email*' 
                                                value={email} 
                                                onChange={handleEmail} 
                                                placeholder='Email'
                                            />
                                        </>
                                    :
                                        <>
                                            <StyledText
                                                style={{textAlign: 'center'}}
                                                text={`Ingresa el código aquí que enviamos al mail ${email}`}
                                                marginBottom='16px'
                                            />
                                            <TextInputItem 
                                                text='Código recibido*' 
                                                value={code} 
                                                onChange={handleCode} 
                                            />
                                        </>       
                                }
                                
                                <MainButton
                                    text='Continuar' 
                                    onClick={codeValidated ? validateNewPass : codeSended ? validateCode : validateForm} 
                                    border={`solid 1px ${Colors.grayBorder}`}
                                    backGroundColor='transparent'
                                    color={Colors.black}
                                    marginTop='26px'
                                    marginBottom='20px'
                                />

                            </div>
                    </RecoveryPassModalHolder>
                </RecoveryPassModalWrapper>        
            </FullOverlay>
            <Loading visible={loading} />
        </>
    )
}

const RecoveryPassModalWrapper = styled.div`
    width: 350px;
`;

const RecoveryPassModalHolder = styled.div`
    background: ${Colors.white};
    border-radius: 45px;
    padding: 20px;
    padding-bottom: 40px;
`;

const LogoWrapper = styled.div`
    margin-bottom: 12px;
`;

const CloseIconWrapper = styled.div`
`;

export default RecoveryPassModal
