import React from 'react';
import styled from 'styled-components';
import {IoCheckmarkCircle, IoPerson, IoCloseCircle, IoCalendarClearOutline, IoTimeOutline, IoPin} from 'react-icons/io5';
import Colors from '../../../theme/Colors';
import StyledText from '../../../design/atoms/typography/StyledText';
import moment from 'moment';
import MainButton from '../../../design/molecules/MainButton';
import { screenSizes } from '../../../fixtures/ScreenSizes';

const MyReservationList = ({listReservations, handleShowDetail, handleReservSelected}) => {

    return (
        <MyReservationListWrapper>
            {
                listReservations.map((reserv)=>
                    <ReservationWrapper className='reservation-wrapper' key={reserv.id}>

                        <IconReservStatusWrapper className='icon-reserv-status-wrapper'>
                            {
                                reserv.estatus === 'cancelada' ?
                                    <IoCloseCircle color={Colors.mainColor} size={20} />
                                :
                                    reserv.estatus === 'activa' &&
                                        <IoCheckmarkCircle color={Colors.mainColor} size={20} />

                            }
                        </IconReservStatusWrapper>
                        
                        <ChefPhotoWrapper className='chef-photo-wrapper'>
                            <ChefPhoto src={reserv.chef.avatar} />
                        </ChefPhotoWrapper>
                        
                        <ChefInfoWrapper className='chef-info-wrapper'>
                            <IconChefWrapper className='d-flex align-items-center'>
                                <IoPerson color={Colors.gray} size={12} />
                                <StyledText text='Chef' color={Colors.grayParagraph} />
                            </IconChefWrapper>
                            <StyledText text={reserv.chef_nombre} />
                            {
                                reserv.estatus === 'activa' &&
                                <MainButton 
                                    text='Ver detalles' 
                                    fontSize='10px' 
                                    height='20px' 
                                    onClick={()=>{handleReservSelected(reserv); handleShowDetail();}}
                                />
                            }
                        </ChefInfoWrapper>

                        <ReservInfoWrapper className='reserv-info-wrapper'>
                            <DateInfoWrapper className='date-info-wrapper d-flex align-items-center'>
                                <IoCalendarClearOutline color={Colors.gray} size={14} />
                                <StyledText 
                                    style={{textTransform: 'capitalize'}} 
                                    text={reserv.fecha || reserv.fechas.join(',')
                                    } 
                                    fontSize='12px' color={Colors.gray} marginLeft='14px' 
                                />
                            </DateInfoWrapper>
                            <TimeInfoWrapper className='time-info-wrapper d-flex align-items-center'>
                                <IoTimeOutline color={Colors.gray} size={14} />
                                <StyledText text={reserv.hora || `${reserv.hora_inicial} - ${reserv.hora_final}`} fontSize='12px' color={Colors.gray} marginLeft='14px' />
                            </TimeInfoWrapper>
                            <AddressInfoWrapper className='address-info-wrapper d-flex align-items-center'>
                                <IoPin color={Colors.gray} size={30} />
                                <StyledText text={reserv.direccion.formatted_address} fontSize='12px' color={Colors.gray} marginLeft='14px' />
                            </AddressInfoWrapper>
                        </ReservInfoWrapper>

                    </ReservationWrapper>
                )
            }
        </MyReservationListWrapper>
    )
}

const MyReservationListWrapper = styled.div``;

const ReservationWrapper = styled.div`
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: solid 1px ${Colors.grayInputForm};
    display: flex;
    align-items: center;

    @media(max-width: ${screenSizes.mediumMax}){
        display: block;
    }
`;

export const IconReservStatusWrapper = styled.div`
    margin-right: 30px;

    @media(max-width: ${screenSizes.mediumMax}){
        display: none;
    }
`;

export const ChefPhotoWrapper = styled.div`
    margin-right: 24px;

    @media(max-width: ${screenSizes.mediumMax}){
        margin-bottom: 16px;
    }
`;

export const ChefPhoto = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 21px;
    object-fit: cover;
`;

export const ChefInfoWrapper = styled.div`
    margin-right: 50px;

    @media(max-width: ${screenSizes.mediumMax}){
        margin-bottom: 16px;
    }
`;

const IconChefWrapper = styled.div``;

const ReservInfoWrapper = styled.div`
    flex: 1;
`;

const DateInfoWrapper = styled.div``;

const TimeInfoWrapper = styled.div``;

const AddressInfoWrapper = styled.div``;

export default MyReservationList
