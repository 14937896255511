import React from 'react'
import styled from 'styled-components'

const TextInputBorderless = ({placeholder, value, onChange, style, type}) => {
    return (
        <TextInput 
            type={type}  
            style={style} 
            placeholder={placeholder} 
            value={value} 
            onChange={onChange} />
    )
}

const TextInput = styled.input`
    border: 0px;
    background-color: transparent;

    :focus{
        outline: none;
    }

    :placeholder{
        font-size: ${props => props.fontSize || '16px'};
    }
`;

export default TextInputBorderless
