import React from 'react';
import VideoModal from '../../design/organisms/VideoModal/VideoModal';
import { HeaderReservation } from '../../design/Templates/HeaderReservation';
import AlliedChefs from './AlliedChefs';
import EnjoyChef from './EnjoyChef';
import Header from './Header'
import HowItWorks from './HowItWorks';
import ShowProposal from './ShowProposal';

const Home = () => {

    return (
        <>
            <HeaderReservation />
            <AlliedChefs />
            <Header />
            <HowItWorks />
            <EnjoyChef />
            <ShowProposal />
            <VideoModal />
        </>
    )
}

export default Home
