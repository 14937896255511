import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import ListUserOptions from '../../design/molecules/ListUserOptions/ListUserOptions';
import MainButton from '../../design/molecules/MainButton';
import TextInputItem from '../../design/molecules/TextInputItem/TextInputItem';
import UserAvatarInfo from '../../design/molecules/UserAvatarInfo/UserAvatarInfo';
import Colors from '../../theme/Colors';
import { Redirect } from 'react-router';
import useMyAccount from './hooks/useMyAccount';
import Loading from '../../components/Loading';

const MyAccount = () => {

    const {
        loading,
        userId,
        nombre, 
        apellidos, 
        email, 
        celular,
        handleNombre,
        handleApellidos,
        validateForm
    } = useMyAccount();

    return (
        userId ? 
            <>
                <MyAccountWrapper className='my-account-wraper'>
                    <Container>
                        <Row>
                            <Col md='3'>
                                <OptionsUserWrapper>
                                    <div className='mb-4'>
                                        <UserAvatarInfo />
                                    </div>
                                    <div>
                                        <ListUserOptions />
                                    </div>
                                </OptionsUserWrapper>
                            </Col>
                            
                            <Col md='9'>
                                <OptionsUserWrapper>
                                    <Row className='mb-3'>
                                        <Col>
                                            <TextInputItem
                                                width='100%' 
                                                text='Nombre' 
                                                value={nombre}
                                                onChange={handleNombre} 
                                            />
                                        </Col>
                                        <Col>
                                            <TextInputItem 
                                                width='100%' 
                                                text='Apellidos' 
                                                value={apellidos} 
                                                onChange={handleApellidos}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col>
                                            <TextInputItem disabled width='100%' text='Email' value={email} />
                                        </Col>
                                        <Col>
                                            <TextInputItem disabled width='100%' text='Celular' value={celular} />
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col>
                                            <TextInputItem disabled width='100%' text='Contraseña' value='**********' />
                                        </Col>
                                        <Col className='d-flex justify-content-end align-items-center'>
                                            <MainButton 
                                                text='Guardar' 
                                                backGroundColor='transparent'
                                                color={Colors.gray}
                                                border={`solid 1px ${Colors.grayBorder}`}
                                                onClick={validateForm}
                                            />
                                        </Col>
                                    </Row>
                                </OptionsUserWrapper>
                            </Col>
                        </Row>
                    </Container>
                </MyAccountWrapper>
                {
                    <Loading visible={loading} />
                }
            </>
        :
            <Redirect to='/' />
    )
}

const MyAccountWrapper = styled.div`
    background-color: ${Colors.bgAccountScreen};
    padding-top: 50px;
    padding-bottom: 100px;
`;

const OptionsUserWrapper = styled.div`
    background-color: ${Colors.white};
    padding: 20px;
    border-radius: 8px;
`;

export default MyAccount
