import React from 'react'
import styled from 'styled-components'
import { screenSizes } from '../../../fixtures/ScreenSizes';
import Colors from '../../../theme/Colors';
import {IoLogoApple,IoLogoFacebook} from 'react-icons/io5';

const MainButton = ({
    text, 
    backGroundColor, 
    border, 
    color, 
    borderRadius, 
    onClick, 
    fontSize, 
    marginTop, 
    marginBottom,
    height,
    style,
    appleButton,
    fbButton
}) => {
    return (
        <MainButtonWrapper
            style={style}
            className='main-button-wrapper'
            marginTop={marginTop}
            marginBottom={marginBottom}
        >
            <StyledButton
                onClick={onClick}
                backGroundColor={backGroundColor}
                border={border}
                color={color}
                borderRadius={borderRadius}
                fontSize={fontSize}
                height={height}
            >
                {
                    appleButton &&
                    <IoLogoApple color={Colors.black} style={{marginRight: 8}} size={14} />
                }
                {
                    fbButton &&
                    <IoLogoFacebook color={Colors.white} style={{marginRight: 8}} size={14} />
                }
                {text}
            </StyledButton>
        </MainButtonWrapper>
    )
}

const MainButtonWrapper = styled.div`
    margin-top: ${props => props.marginTop || '0'};
    margin-bottom: ${props => props.marginBottom || '0'};
`;

const StyledButton = styled.button`
    background-color: ${props => props.backGroundColor || Colors.mainColor};
    border: ${props => props.border || '0px'};
    color: ${props => props.color || Colors.white};
    height: ${props => props.height || '33px'};
    border-radius: ${props => props.borderRadius || '33px'};
    font-size: ${props => props.fontSize || '16px'};
    padding-right: 16px;
    padding-left: 16px;
    width: ${props => props.width || 'auto'};
    display: flex;
    align-items: center;

    :focus {
        outline: none;
    }

    @media (max-width: ${screenSizes.mediumMax}){
        font-size: 12px;
    }
`;

export default MainButton
