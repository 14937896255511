import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Switch from "react-switch";
import styled from 'styled-components';
import Colors from '../../../theme/Colors';
import StyledText from '../../atoms/typography/StyledText';

const NotificationSettingItem = ({label, checked, onChange}) => {
    return (
        <NotificationSettingItemWrapper className='notification-setting-item-wrapper'>
            <NotificationSettingItemHolder className='notification-setting-item-holder'>
                <Row className='align-items-center'>
                    <Col sm='4'>
                        <StyledText text={label} color={Colors.gray} marginRight='16px' />
                    </Col>
                    <Col>        
                        <Switch 
                            checked={checked}
                            onColor={Colors.mainColor} 
                            checkedIcon={false} 
                            uncheckedIcon={false} 
                            width={40}
                            height={20}
                            onChange={onChange}
                        />
                    </Col>
                </Row>
            </NotificationSettingItemHolder>
        </NotificationSettingItemWrapper>
    )
}

const NotificationSettingItemWrapper = styled.div``;

const NotificationSettingItemHolder = styled.div`
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: solid 1px ${Colors.lightGray};
`;

export default NotificationSettingItem
