import { createSlice } from "@reduxjs/toolkit";

export const visibleChefsSlice = createSlice({
    name: 'visibleChefs',
    initialState: {
        chefs: [],
    },
    reducers: {
        setVisibleChefs: (state, action)=> {
            const chefs = [...action.payload];
            // const chefs_filterd = chefs.filter(chef => chef.menus.length > 0);
            state.chefs = chefs;
        }
    }
});

export const {setVisibleChefs} = visibleChefsSlice.actions;
export default visibleChefsSlice.reducer;