import React, { useState } from 'react'
import styled from 'styled-components';
import Loading from '../../components/Loading';
import MainButton from '../../components/MainButton';
import { callApi } from '../../fixtures/api';
import { ValidateEmail } from '../../fixtures/helpers';
import { screenSizes } from '../../fixtures/ScreenSizes';
import Images from '../../images/Images';
import Colors from '../../theme/Colors';
import swal from 'sweetalert';

const Contact = () => {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState(' ');
    const [subject, setSubject] = useState('');
    const [errorSubject, setErrorSubject] = useState(' ');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(' ');

    const handleEmail = (e)=> {
        const email = e.target.value;
        if(!ValidateEmail(email)){
            setErrorEmail('Escribe un email válido.');
        }else{
            setErrorEmail('');
        }
        setEmail(email);
    }

    const handleSubject = (e)=> {
        const subject = e.target.value;
        if(!subject){
            setErrorSubject('Escribe un asunto.');
        }else{
            setErrorSubject('');
        }
        setSubject(subject);
    }

    const handleMessage = (e)=> {
        const message = e.target.value;
        if(!message || message.length < 15){
            setErrorMessage('Escribe un mensaje.');
        }else{
            setErrorMessage('');
        }
        setMessage(message);
    }

    const resetForm = ()=> {
        setEmail('');
        setErrorEmail(' ');
        setSubject('');
        setErrorSubject(' ');
        setMessage('');
        setErrorMessage(' ');
    }

    const SendEmail = async()=> {
        setLoading(true);
        const contactData = {
            email,
            subject,
            message
        };
        const resultSendEmail = await callApi('send_email_contact_form_web', 'post', contactData);
        setLoading(false);
        if(resultSendEmail.status === 200){
            swal("¡Gracias!", resultSendEmail.message, "success", {buttons : 'Aceptar'});
        }else{
            swal("", resultSendEmail.message, "error", {buttons : 'Aceptar'});
        }
        resetForm();
    }

    return (
        <>
            <ContactWrapper>
                <div className='container mb-4'>
                    <div className='row mb-3'>
                        <div className='col-6 d-lg-none'>
                            <Title blue>Mejoramos gracias a tus comentarios</Title>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-lg-none'>
                            <img className='img-fluid' src={Images.imgContactMobile} alt='mejoramos_por_tus_comentarios' />
                        </div>
                    </div>
                </div>

                <Header className='container mb-5 d-lg-flex flex-column justify-content-end d-none'>
                    <div className='row'>
                        <div className='col-sm-8 col-lg-5'>
                            <Title>Mejoramos gracias a tus comentarios</Title>
                        </div>
                    </div>
                </Header>

                <IntroContactWrapper className='container intro-contact-wrapper pb-5'>
                    <Text className='mb-5'>¿Tienes alguna sugerencia o comentario que no has podido resolver? No dudes en dejarlo aquí debajo y nosotros te contactaremos a la brevedad posible. Recuerda que también nos puedes encontrar nuestras redes sociales. Bon appétit!</Text>
                    <TitleSection>Contáctanos</TitleSection>
                </IntroContactWrapper>

                <FormWrapper className='container form-wrapper mb-5'>
                    <div className='row mb-2'>
                        <div className='col-lg-6 mb-2 mb-lg-0'>
                            <InputText autoCapitalize='none' placeholder='Email' value={email} onChange={handleEmail} />
                            <ErrroInput>{errorEmail}</ErrroInput>
                        </div>
                        <div className='col-lg-6'>
                            <InputText placeholder='Asunto' value={subject} onChange={handleSubject} />
                            {/* <Select onChange={handleSubject} value={subject}>
                                <option value=''>Asunto</option>
                                <option value='Valor 1'>Valor 1</option>
                                <option value='Valor 2'>Valor 2</option>
                            </Select> */}
                            <ErrroInput>{errorSubject}</ErrroInput>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <TextArea placeholder='Escribe tu mensaje...' value={message} onChange={handleMessage} />
                            <ErrroInput>{errorMessage}</ErrroInput>
                        </div>
                    </div>
                    {
                        !errorEmail && !errorMessage && !errorSubject &&
                            <div className='d-flex justify-content-center'>
                                <MainButton width={'225px'} height={'52px'} fontSize={'20px'} text='Enviar' onClick={SendEmail} />
                            </div>
                    }
                </FormWrapper>

            </ContactWrapper>
            <Loading visible={loading} />
        </>
    )
}

const ContactWrapper = styled.div`
`;

const Header = styled.section`
    min-height: 416px;
    background: url(${Images.imgHeaderContact}) top center no-repeat;
    background-size: cover;
`;

const Title = styled.h2`
    color: ${props => props.blue ? Colors.blue : Colors.white};
    font-size: 35px;
    font-weight: bold;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 20px;
    }
`;

const IntroContactWrapper = styled.section`

`;

const TitleSection = styled.section`
    font-size: 35px;
    color: ${Colors.blue};
    font-weight: bold;
    text-align: center;
`;

const Text = styled.p`
    font-size: 20px;
    text-align: center;

    @media (min-width: ${screenSizes.small}) and (max-width: ${screenSizes.mediumMax}) {
        font-size: 14px;
        text-align: left;
    }
`;

const FormWrapper = styled.div`

`;

const InputText = styled.input`
    color: ${Colors.grayFooter};
    width: 100%;
    height: 60px;
    border: solid 1px ${Colors.grayFooter};
    padding-left: 15px;

    :focus{
        outline: none;
    }

    ::placeholder {
        color: ${Colors.grayFooter};
    }
`;

const TextArea = styled.textarea`
    color: ${Colors.grayFooter};
    width: 100%;
    height: 180px;
    border: solid 1px ${Colors.grayFooter};
    padding-left: 15px;
    padding-top: 15px;
    resize: none;

    ::placeholder {
        color: ${Colors.grayFooter};
    }

    :focus{
        outline: none;
    }
`;

const ErrroInput = styled.span`
    font-size: 12px;
    color: ${Colors.mainColor};
`;

export default Contact;
