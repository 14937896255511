import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import StyledSubtitle from '../../design/atoms/StyledSubtitle';
import StyledText from '../../design/atoms/typography/StyledText';
import ListUserOptions from '../../design/molecules/ListUserOptions/ListUserOptions';
import UserAvatarInfo from '../../design/molecules/UserAvatarInfo/UserAvatarInfo';
import Colors from '../../theme/Colors';
import useMyReservations from './hooks/useMyReservations';
import MyReservationList from './components/MyReservationList';
import Loading from '../../components/Loading';
import ReservationDetail from './components/ReservationDetail';

const MyReservations = () => {

    const {
        loading,
        reservasActivas,
        reservasFinalizadas,
        reservasCanceladas,
        handleShowDetail,
        handleReservSelected,
        showDetail,
        reservaSelected
    } = useMyReservations();

    return (
        <>
            {
                loading ?
                    <Loading visible={loading} />
                :
                    <MyReservationsWrapper className='my-account-wraper'>
                        <Container>
                            <Row>
                                <Col md='3'>
                                    <OptionsUserWrapper>
                                        <div className='mb-4'>
                                            <UserAvatarInfo />
                                        </div>
                                        <div>
                                            <ListUserOptions />
                                        </div>
                                    </OptionsUserWrapper>
                                </Col>
                                
                                <Col md='9'>
                                    <OptionsUserWrapper>
                                        <StyledSubtitle 
                                            text={!showDetail ? 'Mis reservas' : 'Mis reservas > Ver detalles'} 
                                            fontWeight='600' 
                                            marginBottom='50px'
                                            isClickeable={showDetail ? true : false}
                                            onClick={showDetail ? handleShowDetail : null}
                                        />
                                        {
                                            showDetail ?
                                                <ReservationDetail reservaSelected={reservaSelected} />
                                            :
                                                reservasActivas.length === 0 && 
                                                reservasCanceladas.length === 0 && 
                                                reservasFinalizadas.length === 0 ?
                                                    <StyledText text='Aun no cuentas con reservaciones' color={Colors.mainColor} />
                                                :
                                                    <ListReservationsWrapper>
                                                        {
                                                            reservasActivas.length > 0 &&
                                                            <ListReservationsHolder>
                                                                <StyledText text='Activas' fontSize='18px' />
                                                                <MyReservationList 
                                                                    listReservations={reservasActivas} 
                                                                    handleShowDetail={handleShowDetail}
                                                                    handleReservSelected={handleReservSelected}
                                                                />
                                                            </ListReservationsHolder>
                                                        }
                                                        {
                                                            reservasCanceladas.length > 0 &&
                                                                <ListReservationsHolder>
                                                                    <StyledText text='Canceladas' fontSize='18px' />
                                                                    <MyReservationList listReservations={reservasCanceladas} />
                                                                </ListReservationsHolder>
                                                        }
                                                        {
                                                            reservasFinalizadas.length > 0 &&
                                                            <ListReservationsHolder>
                                                                <StyledText text='Historial de pedidos' fontSize='18px' />
                                                                <MyReservationList listReservations={reservasFinalizadas} />
                                                            </ListReservationsHolder>
                                                        }
                                                    </ListReservationsWrapper>
                                        }
                                    </OptionsUserWrapper>
                                </Col>
                            </Row>
                        </Container>
                    </MyReservationsWrapper>
            }  
        </>
    )
}

const MyReservationsWrapper = styled.div`
    background-color: ${Colors.bgAccountScreen};
    padding-top: 50px;
    padding-bottom: 100px;
`;

const OptionsUserWrapper = styled.div`
    background-color: ${Colors.white};
    padding: 20px;
    border-radius: 8px;
`;

const ListReservationsWrapper = styled.div`
    margin-left: 30px;
    margin-right: 30px;
`;

const ListReservationsHolder = styled.div`
    margin-top: 60px;
`;

export default MyReservations
