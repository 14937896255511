import React from 'react';
import lang_es from "./lang_es";
import DatePicker from "react-multi-date-picker";

const Calendar = ({onChange, value, multiple}) => {

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate()+1);

    return (
        <DatePicker
            weekStartDayIndex={1}
            locale={lang_es}
            value={value}
            onChange={onChange}
            className='red' 
            minDate={tomorrow}
            placeholder='¿Cuando?'
            format='DD/MM/YYYY'
            style={{
                width: '100%',
                border: '0px',
                outline: 'unset',
                background: 'transparent',
            }}
            containerStyle={{
                width: '100%'
            }}
            multiple={multiple}
        />
    )
}

export default Calendar
